export const imageCreativeFormats = ["ExportableImage", "FacebookPhotoAdPost", "FacebookCarouselAdPost", "SingleImageTweet", "TweetWithImageWebsiteCard", "TweetWithImageAppCard", "PinterestStaticPin"]

export const videoCreativeFormats = ["ExportableVideo", "FacebookVideoAdPost", "PromotedVideoTweet", "TweetWithVideoWebsiteCard", "TweetWithVideoAppCard", "YouTubeTrueView", "YouTubeBumperAds", "PinterestStandardWidthVideoPin"]

export const htmlCreativeFormats = ["ExportableFastLoadingCrossScreenBanner", "ExportableFastLoadingAnimatedBanner"]

export const imageFormats = [
  { value: "png", label: "PNG" },
  { value: "jpeg", label: "JPEG" },
  { value: "tiff", label: "TIFF" },
]

export const adServers = [
  { value: "dcm", label: "Campaign Manager 360" },
  { value: "dv360", label: "Display & Video 360" },
  { value: "flashtalking", label: "Flashtalking" },
  { value: "ttd", label: "The Trade Desk" },
  { value: "googleAds", label: "Google Ads" },
  { value: "googleAdsUAC", label: "Google Ads UAC" },
  { value: "googleAdManager", label: "Google Ad Manager" },
  { value: "sizmek", label: "Sizmek" },
  { value: "adForm", label: "AdForm" },
  { value: "generic", label: "Generic" },
]

export const serversWithFallbackImagesToggle = ["dcm", "dv360"]
