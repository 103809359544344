import axios from "axios"
import mitt from "mitt"
import { createApp } from "vue"
import DesignSystemNext from "@celtra/design-system-next"
import { store } from "./store"
import router from "./router"
import App from "./App.vue"
import * as Sentry from "@sentry/vue";
import { CaptureConsole } from "@sentry/integrations"

// import styles
import "@celtra/design-system-next/dist/design-system-next.css"

const url = new URL(window.location.href)
const password = url.searchParams.get("auth")
const basicAuth = "Basic " + btoa("DistributionPrototype" + ":" + password)

axios.defaults.baseURL = process.env.VUE_APP_UAB_API_URL
axios.defaults.tokenExchangeURL = process.env.VUE_APP_TOKEN_EXCHANGE_API_URL
axios.defaults.withCredentials = true
axios.defaults.headers = {
  "Authorization": basicAuth,
  "Content-Type": "application/json; charset=utf-8",
}

store.dispatch("initialize", password)
const emitter = mitt()
const app = createApp(App)
Sentry.init({
  app,
  dsn: process.env.VUE_APP_SENTRY_DSN,
  integrations: [
    new CaptureConsole({ levels: ["error"] }),
  ],
});

app.use(router)
app.use(store)
app.use(DesignSystemNext, {})
app.provide("emitter", emitter)
app.provide("trackingContextProvider", { namespace: "DistributionPrototype", data: {} })
app.mount("#app")
