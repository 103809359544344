<template>
  <div class="flashtalking-step">
    <a-tooltip placement="bottom" v-if="areIncompatibleCreativesSelected">
      <div class="flashtalking-step__notice">
        You have selected creatives that cannot be distributed to Flashtalking. To distribute to Flashtalking, please close the dialog and limit the selection to Flashtalking creatives.
      </div>
      <template #title>
        <div>{{ formatsDistributableToFlashtalking }}</div>
      </template>
    </a-tooltip>

    <template v-else>
      <flashtalking-login-step v-if="!isAuthorized" />
      <flashtalking-distribute-step v-else />
    </template>
  </div>
</template>

<script>
import { computed, ref } from "vue"
import { useStore } from "vuex"
import FlashtalkingLoginStep from "./FlashtalkingLoginStep"
import FlashtalkingDistributeStep from "./FlashtalkingDistributeStep"
import { formatsDistributableToFlashtalkingLabels } from "@/store/modules/distribution_dialog/shared/constants.js"

export default {
  components: {
    FlashtalkingLoginStep,
    FlashtalkingDistributeStep,
  },
  setup() {
    const store = useStore()
    const isAuthorized = computed(() => store.state.distributionDialog.flashtalking.isAuthorized)

    const formatsDistributableToFlashtalking = ref(`Supported creatives: ${formatsDistributableToFlashtalkingLabels.join(",")}`)
    const areIncompatibleCreativesSelected = computed(() => store.getters["distributionDialog/flashtalking/areIncompatibleCreativesSelected"])

    return {
      isAuthorized,
      formatsDistributableToFlashtalking,
      areIncompatibleCreativesSelected,
    }
  },
}
</script>

<style lang="less" scoped>
@import (reference) '~design-system-variables/index';

.flashtalking-step__notice {
  text-align: center;
  font-size: 14px;
}
</style>
