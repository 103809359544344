module.exports = class EntityEventListeners {
    constructor (entityNotificationsClient) {
        this._entityNotificationsClient = entityNotificationsClient
        this._listeners = []
    }

    addListener (eventFilter, initialCallback, incrementalCallback) {
        this._listeners.push({ eventFilter, initialCallback, incrementalCallback })
        this._entityNotificationsClient.addEntityEventListener(eventFilter, initialCallback, incrementalCallback)
    }

    removeAllListeners () {
        for (const listener of this._listeners) {
            this._entityNotificationsClient.removeEntityEventListener(listener.eventFilter, listener.initialCallback, listener.incrementalCallback)
        }
        this._listeners = []
    }
}
