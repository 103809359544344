<template>
  <app-header @distribute="distributeCreatives" />
  <div class="app__container">
    <div class="app__loader" v-if="isLoading">
        <a-spin class="app__loader-spinner" size="large" tip="Loading..." />
    </div>

    <div class="app__login" v-else-if="isCeltraLoginRequired">
    <div class="app__login__headline">{{ loginHeadline() }}</div>
    <div class="app__login__description">{{ loginDescription() }}</div>
    <a-button
        track-name="celtra-login"
        type="primary"
        @click="onCeltraLogin"
    >
        <span>Sign in with Celtra</span>
    </a-button>
    </div>

    <template v-else-if="pageLoaded">
      <filters />
      <div class="creatives-content" v-if="creativesOverflow">
        <creatives-overflow-error />
      </div>
      <div class="creatives-content" v-else>
          <creative-brief-bar
          @openCreativeBrief="openCreativeBriefDialog"
          @openDistributionErrors="openDistributionErrorsDialog"
          />
          <creatives-table />
          <app-footer />
      </div>
    </template>
  </div>
  <a-modal
      v-if="errorMessage"
      :closable="false"
      :visible="true"
      :footer="null"
      :width="600"
      track-name="error-dialog"
    >
      <div class="app__error">
      <c-icon class="app__error-icon" name="x-circle-regular" size="large" />
      <div>{{ errorMessage }}</div>
      </div>
    </a-modal>

  <distribution-dialog v-if="isDistributionDialogOpen" @close="closeDistributionDialog" />
  <creative-brief-dialog v-if="isCreativeBriefDialogOpen" @close="closeCreativeBriefDialog" />
  <distribution-errors-dialog v-if="isDistributionErrorsDialogOpen" @close="closeDistributionErrorsDialog" />
</template>

<script>
import { computed, ref, watch, nextTick, onMounted, onUnmounted } from "vue"
import { useStore } from "vuex"
import { notification } from "@celtra/design-system-next"
import Header from "./Header.vue"
import Footer from "./Footer.vue"
import Filters from "./creatives/Filters.vue"
import CreativeBriefBar from "./creatives/CreativeBriefBar.vue"
import CreativeBriefDialog from "./creative_brief_dialog/CreativeBriefDialog.vue"
import CreativesTable from "./creatives/CreativesTable.vue"
import DistributionDialog from "./distribution_dialog/DistributionDialog.vue"
import DistributionErrorsDialog from "./creatives/DistributionErrorsDialog.vue"
import CreativesOverflowError from "./creatives/CreativesOverflowError.vue"

export default {
  name: "Main",
  components: {
    AppHeader: Header,
    AppFooter: Footer,
    Filters,
    CreativeBriefBar,
    CreativesTable,
    DistributionDialog,
    CreativeBriefDialog,
    DistributionErrorsDialog,
    CreativesOverflowError,
  },
  setup () {
    onMounted(() => window.addEventListener("message", handleCeltraCodeMessage))
    onUnmounted(() => window.removeEventListener("message", handleCeltraCodeMessage))

    const store = useStore()
    const localStorage = window.localStorage
    const isLoading = computed(() => store.state.isLoading)
    const pageLoaded = computed(() => store.state.pageLoaded)
    const errorMessage = computed(() => store.state.errorMessage)
    const isCeltraLoginRequired = computed(() => store.state.celtraLoginRequired)
    const accountAccessDenied = computed(() => store.state.accountAccessDenied)
    const showFailedDistributionsNotification = computed(() => store.state.creativesTable.showFailedDistributionsNotification)
    const failedDistributionsToNotify = computed(() => store.state.creativesTable.failedDistributionsToNotify)
    const creativesOverflow = computed(() => store.state.creativesOverflow)

    const isDistributionDialogOpen = computed(() => store.state.distributionDialog.isDistributionDialogOpen)
    function distributeCreatives () {
      const creativeIds = store.getters["creativesTable/selectedFilteredRowKeys"]
      if (creativeIds.length > 0) {
        store.dispatch("distributionDialog/initialize", creativeIds)
        store.dispatch("distributionDialog/setIsDistributionDialogOpen", true)
      }
    }

    function closeDistributionDialog () {
      store.dispatch("distributionDialog/setIsDistributionDialogOpen", false)
      store.dispatch("distributionDialog/resetDialog")
    }

    const creativeBriefDialogLocalStorageKey = computed(() => `${store.state.distributionPrototypeAccessKeyId}:wasCreativeBriefDialogClosed`)
    const isCreativeBriefDialogOpen = ref(false)
    function openCreativeBriefDialog () {
      isCreativeBriefDialogOpen.value = true
    }
    function closeCreativeBriefDialog () {
      isCreativeBriefDialogOpen.value = false
      localStorage.setItem(creativeBriefDialogLocalStorageKey.value, true)
    }

    const isDistributionErrorsDialogOpen = ref(false)
    function openDistributionErrorsDialog () {
      isDistributionErrorsDialogOpen.value = true
    }
    function closeDistributionErrorsDialog () {
      isDistributionErrorsDialogOpen.value = false
    }

    function onCeltraLogin () {
      store.dispatch("openCeltraOAuthDialog")
    }

    function loginHeadline () {
      return accountAccessDenied.value ? "Access denied" : "User authentication needed"
    }

    function loginDescription () {
      return accountAccessDenied.value ? "You don't have permission to access this page." : "Please sign in using the link below."
    }

    watch(pageLoaded, (newValue, oldValue) => {
      const isPageLoaded = oldValue === false && newValue === true
      if (!isPageLoaded) {
        return
      }

      const wasCreativeBriefDialogClosed = localStorage.getItem(creativeBriefDialogLocalStorageKey.value)
      if (wasCreativeBriefDialogClosed || creativesOverflow.value) {
        return
      }

      nextTick(() => openCreativeBriefDialog())
    })

    watch(showFailedDistributionsNotification, (newValue) => {
      if (newValue && failedDistributionsToNotify.value.length > 0) {
        notification.warn({
          key: "failed-distributions",
          message: `${failedDistributionsToNotify.value.length === 1 ? "One creative" : `${failedDistributionsToNotify.value.length} creatives`} failed to export.`,
          theme: "dark",
          duration: 8,
          closable: true,
          action: {
            isVisible: true,
            title: "Review",
            label: "Review",
            onClick: () => openDistributionErrorsDialog(),
          },
        })
      }

      nextTick(() => store.dispatch("creativesTable/resetFailedDistributionsNotification"))
    })

    return {
      isLoading,
      pageLoaded,
      errorMessage,
      isCeltraLoginRequired,
      accountAccessDenied,
      isDistributionDialogOpen,
      isCreativeBriefDialogOpen,
      creativesOverflow,
      isDistributionErrorsDialogOpen,
      distributeCreatives,
      closeDistributionDialog,
      openCreativeBriefDialog,
      closeCreativeBriefDialog,
      openDistributionErrorsDialog,
      closeDistributionErrorsDialog,
      onCeltraLogin,
      loginHeadline,
      loginDescription,
    }

    function handleCeltraCodeMessage (ev) {
      store.dispatch("handleCeltraCodeMessage", ev)
    }
  },
}
</script>

<style lang="less">
@import (reference) '~design-system-variables/index';

html,
body {
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: @void;
  font-family: @font-family;
}

* {
  box-sizing: border-box;
}

.app__loader-spinner.app__loader-spinner.app__loader-spinner {
  svg {
    width: 32px;
    height: 32px;
  }

  .ant-spin-text {
    margin-top: 8px;
    font-size: 24px;
  }
}

.app__login {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__headline {
    width: 264px;
    font-size: 16px;
    line-height: 20px;
    color: @fog;
    font-weight: 500;
    margin-bottom: 4px;
    text-align: center;
  }

  &__description {
    width: 264px;
    font-size: 12px;
    line-height: 16px;
    color: @smog;
    font-weight: 400;
    margin-bottom: 16px;
    text-align: center;
  }
}

::-webkit-scrollbar {
  background-color: @ink;
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: @slate;
  border-radius: 8px;
}

::-webkit-scrollbar-corner {
  background-color: @ink;
}
</style>

<style lang="less" scoped>
@import (reference) '~design-system-variables/index';

#app {
  width: 100vw;
  height: 100vh;
}

.app__container {
  display: flex;
  min-height: calc(100vh - 48px);
}

.app__loader {
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.app__error {
  padding: 16px 0;
  display: flex;
  justify-content: center;
  color: @danger-red;
  font-size: 16px
}

.app__error-icon {
  margin-right: 8px;
  position: relative;
  top: 2px;
}

.creatives-content {
  background: @ink;
  display: flex;
  flex-direction: column;
}

</style>
