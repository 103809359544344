<template>
  <div class="creative-brief-table" ref="tableRef">
    <div class="creative-brief-table__header">
      <template v-for="item in items" :key="item.id">
        <a-tooltip placement="bottom">
          <div class="creative-brief-table__header-item" :style="{ 'width': computedItemWidth, 'min-width': computedItemWidth }">
            <div class="creative-brief-table__header-item-label">{{ item.label }}</div>
            <div class="creative-brief-table__header-item-value">{{ item.values.length }}</div>
          </div>
          <template #title>
            <div class="creative-brief-table__tooltip-data" v-for="value in item.values" :key="value">{{ value }}</div>
          </template>
        </a-tooltip>
      </template>
    </div>
    <div class="creative-brief-table__content">
      <table-node
        v-for="child in tableNodes"
        :key="child.name"
        :name="child.name"
        :nesting-level="1"
        :children="child.children"
        :width="computedItemWidth"
      />
    </div>
  </div>
</template>

<script>
import { ref, computed, nextTick, onMounted } from "vue"
import { useStore } from "vuex"
import TableNode from "./TableNode"

export default {
  components: {
    TableNode,
  },
  setup () {
    const store = useStore()
    const tableRef = ref(null)
    const itemWidth = ref("auto")
    const computedItemWidth = computed(() => {
      if (itemWidth.value === "auto") {
        return "auto"
      }

      return `${itemWidth.value}px`
    })
    const items = computed(() => store.getters["creativeBrief/tableValueItems"])
    const tableNodes = computed(() => {
      const mappedChildren = []
      for (const [name, items] of Object.entries(store.getters["creativeBrief/tableValues"])) {
        mappedChildren.push({
          name: name,
          children: items,
          useChips: false,
        })
      }

      return mappedChildren
    })

    onMounted(() => {
      nextTick(() => {
        const observer = new ResizeObserver(() => {
          if (!items.value || items.value.length === 0 || !tableRef.value) {
            itemWidth.value = "auto"
            return
          }

          const numberOfItems = items.value.length
          const tableWidth = tableRef.value.clientWidth - 1
          itemWidth.value = Math.max(130, Math.round(tableWidth / numberOfItems))
        })
        observer.observe(tableRef.value)
      })
    })

    return {
      tableRef,
      items,
      computedItemWidth,
      tableNodes,
    }
  },
}
</script>

<style lang="less" scoped>
@import (reference) "~design-system-variables/index";

.creative-brief-table {
  margin: auto;
  overflow-x: scroll;

  &__header {
    display: flex;
    height: 56px;

    &-item {
      display: flex;
      justify-content: space-between;
      padding: 0 16px;
      font-weight: 600;
      font-size: 16px;
      line-height: 52px;
      color: @fog;
      background-color: @brand-purple;
      border: 1px solid @silver;
      overflow: hidden;

      &:first-of-type {
        border-radius: 8px 0 0 0;
      }
      &:last-of-type {
        border-radius: 0 8px 0 0;
      }
    }

    &-item-label {
      max-width: calc(100% - 16px);
      margin-right: 8px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  &__tooltip-data {
    margin-left: 4px;
    font-size: 10px;
  }
}
</style>

<style lang="less">
@import (reference) '~design-system-variables/index';

.creative-brief-table::-webkit-scrollbar {
  background-color: @silver;
}
.creative-brief-table::-webkit-scrollbar-thumb {
  background-color: @dust;
}
</style>
