<template>
  <div class="spinner">
    <a-spin tip="Redirecting" size="large" />
  </div>
</template>

<script>
export default {
  name: "LoginCallback",
  props: {
    code: { type: String, required: true },
  },
  setup (props) {
    window.opener.postMessage({ code: props.code, type: "celtra" }, process.env.VUE_APP_PROTOTYPE_ORIGIN);
    window.close()
  },
}
</script>

<style lang="less">
  .spinner {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>
