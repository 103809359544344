import axios from "axios"
import { orderBy } from "lodash-es"
import { formatsDistributableToFlashtalking } from "../shared/constants.js"
import { extractErrorMessageFromError, getLocalStorageItem } from "@store/utils"

const state = {
  isAuthorized: false,
  isAuthorizationLoading: false,
  authorizationErrorMessage: null,
  isFlashtalkingDataLoading: false,
  flashtalkingBusinessEntityOptions: null,
  flashtalkingAdvertiserOptions: null,
  flashtalkingBusinessEntityId: null,
  flashtalkingAdvertiserId: null,
  flashtalkingCreativeLibraryOptions: null,
  flashtalkingCreativeLibraryId: null,
  flashtalkingNewCreativeLibraryName: null,
}

const getState = () => JSON.parse(JSON.stringify(state))

export default {
  namespaced: true,
  state: getState(),
  getters: {
    creativesForExport(state, _, rootState) {
      return rootState.creatives.filter(c => rootState.distributionDialog.creativeIds.includes(c.id))
    },
    areIncompatibleCreativesSelected(_, getters) {
      return getters.creativesForExport.filter(c => !formatsDistributableToFlashtalking.includes(c.clazz)).length > 0
    },
    isDialogValid (state) {
      const creativeLibrarySelected = state.flashtalkingCreativeLibraryId != null || state.flashtalkingNewCreativeLibraryName != null
      return state.flashtalkingBusinessEntityId != null && state.flashtalkingAdvertiserId != null && creativeLibrarySelected
    },
    postData(state, getters, rootState, rootGetters) {
      return {
        creativeIds: getters.creativesForExport.map(c => c.id),
        endpoint: "Flashtalking",
        flashtalkingBusinessEntityId: state.flashtalkingBusinessEntityId,
        flashtalkingCreativeLibraryId: state.flashtalkingCreativeLibraryId,
        flashtalkingNewCreativeLibraryName: state.flashtalkingNewCreativeLibraryName,
        caller: "prototype",
        distributionPrototypeUserId: getLocalStorageItem(rootGetters.accountUserIdLocalStorageKey),
      }
    },
    creativeLibraryPostData (state, getters, rootState) {
      return {
        advertiserId: state.flashtalkingAdvertiserId,
        name: state.flashtalkingNewCreativeLibraryName,
        accountId: rootState.accountId,
      }
    },
  },
  actions: {
    initialize () {
      return
    },
    loginToFlashtalking({ state, dispatch, commit }, credentials) {
      state.isAuthorizationLoading = true
      commit("SET_AUTHORIZATION_ERROR_MESSAGE", null)
      axios.post("flashtalking/externalUserCredentials", {
        username: credentials.username,
        password: credentials.password,
        provider: "flashtalking",
        accessTokenType: "DistributionAccessToken",
      }).then(() => {
        dispatch("setIsAuthorized", true)
      }).catch((error) => {
        console.error(error)
        commit("SET_AUTHORIZATION_ERROR_MESSAGE", extractErrorMessageFromError(error))
      }).finally(() => {
        state.isAuthorizationLoading = false
      })
    },
    loadFlashtalkingAssets ({ dispatch, state }) {
      if (!state.isAuthorized) {
        throw new Error("User is not authorized, cannot load Flashtalking data!")
      }
      dispatch("loadFlashtalkingBusinessEntityOptions")
    },
    loadFlashtalkingBusinessEntityOptions: ({ commit, rootState }) => {
      commit("SET_FLASHTALKING_BUSINESS_ENTITY_OPTIONS", null)
      commit("SET_FLASHTALKING_ADVERTISER_OPTIONS", null)

      let userUrl = "flashtalking/businessEntities?accessType=flashtalkingUserCredentials"
      let systemUrl = `flashtalking/businessEntities?accountId=${rootState.accountId}&accessType=system`

      return Promise.all([
        axios.get(userUrl),
        axios.get(systemUrl),
      ])
        .then((response) => {
          const businessEntityOptions = mapBusinessEntityOptions(response)
          commit("SET_FLASHTALKING_BUSINESS_ENTITY_OPTIONS", businessEntityOptions)
        })
        .catch(error => console.error(error))

      function mapBusinessEntityOptions([userTokenBusinessEntities, systemTokenBusinessEntities]) {
        const options = userTokenBusinessEntities.data.map(businessEntity => {
          const disabled = systemTokenBusinessEntities.data.every(item => item.id !== businessEntity.id)
          return {
            value: businessEntity.id,
            label: businessEntity.name,
            disabled: disabled,
            tooltip: disabled ? "Celtra does not yet have access to this flashtalking account, you need to link it first." : null,
            metadata: businessEntity.id,
          }
        })
        return orderBy(options, ["disabled", "label"])
      }
    },
    loadFlashtalkingAdvertiserOptions: async ({ commit, state, rootState }) => {
      if (!state.flashtalkingBusinessEntityId) {
        return
      }

      commit("SET_FLASHTALKING_ADVERTISER_OPTIONS", null)

      let userUrl = `flashtalking/advertisers?flashtalkingBusinessEntityId=${state.flashtalkingBusinessEntityId}&accessType=flashtalkingUserCredentials`
      let systemUrl = `flashtalking/advertisers?flashtalkingBusinessEntityId=${state.flashtalkingBusinessEntityId}&accountId=${rootState.accountId}&accessType=system`

      return Promise.all([
        axios.get(userUrl),
        axios.get(systemUrl),
      ])
        .then((response) => {
          const advertiserOptions = mapAdvertiserOptions(response)
          commit("SET_FLASHTALKING_ADVERTISER_OPTIONS", advertiserOptions)
        })
        .catch(error => console.error(error))

      function mapAdvertiserOptions([userTokenAdvertisers, systemTokenAdvertisers]) {
        const options = userTokenAdvertisers.data.map(advertiser => {
          const disabled = systemTokenAdvertisers.data.every(item => item.id !== advertiser.id)
          return {
            value: advertiser.id,
            label: advertiser.name,
            disabled: disabled,
            tooltip: disabled ? "Celtra does not yet have access to this advertiser, you need to link it first." : null,
            metadata: advertiser.id,
          }
        })
        return orderBy(options, ["disabled", "label"])
      }
    },
    loadFlashtalkingCreativeLibraryOptions: async ({ commit, state, rootState }) => {
      if (!state.flashtalkingAdvertiserId) {
        return
      }

      commit("SET_FLASHTALKING_CREATIVE_LIBRARY_OPTIONS", null)

      let userUrl = `flashtalking/creativeLibraries?flashtalkingBusinessEntityId=${state.flashtalkingBusinessEntityId}&flashtalkingAdvertiserId=${state.flashtalkingAdvertiserId}&accessType=flashtalkingUserCredentials&perPage=200`
      let systemUrl = `flashtalking/creativeLibraries?flashtalkingBusinessEntityId=${state.flashtalkingBusinessEntityId}&flashtalkingAdvertiserId=${state.flashtalkingAdvertiserId}&accountId=${rootState.accountId}&accessType=system&perPage=200`

      return Promise.all([
        axios.get(userUrl),
        axios.get(systemUrl),
      ])
        .then((response) => {
          const creativeLibraryOptions = mapCreativeLibraryOptions(response)
          commit("SET_FLASHTALKING_CREATIVE_LIBRARY_OPTIONS", creativeLibraryOptions)
        })
        .catch(error => console.error(error))

      function mapCreativeLibraryOptions([userTokenCreativeLibraries, systemTokenCreativeLibraries]) {
        const options = userTokenCreativeLibraries.data.map(creativeLibrary => {
          const disabled = systemTokenCreativeLibraries.data.every(item => item.id !== creativeLibrary.id)
          return {
            value: creativeLibrary.id,
            label: creativeLibrary.name,
            disabled: disabled,
            tooltip: disabled ? "Celtra does not yet have access to this creative library, you need to link it first." : null,
            metadata: creativeLibrary.id,
          }
        })
        return orderBy(options, ["disabled", "label"])
      }
    },
    selectFlashtalkingBusinessEntity({ commit, dispatch }, flashtalkingBusinessEntity) {
      commit("SET_FLASHTALKING_BUSINESS_ENTITY", flashtalkingBusinessEntity)
      commit("SET_FLASHTALKING_ADVERTISER", null)
      commit("SET_FLASHTALKING_CREATIVE_LIBRARY", null)
      return dispatch("loadFlashtalkingAdvertiserOptions")
    },
    selectFlashtalkingAdvertiser({ commit, dispatch }, flashtalkingAdvertiser) {
      commit("SET_FLASHTALKING_ADVERTISER", flashtalkingAdvertiser)
      commit("SET_FLASHTALKING_CREATIVE_LIBRARY", null)
      return dispatch("loadFlashtalkingCreativeLibraryOptions")
    },
    selectFlashtalkingCreativeLibrary({ commit }, flashtalkingCreativeLibraryId) {
      commit("SET_FLASHTALKING_CREATIVE_LIBRARY", flashtalkingCreativeLibraryId)
    },
    addNewFlashtalkingCreativeLibrary ({ commit }, newFlashtalkingCreativeLibraryName) {
      commit("SET_FLASHTALKING_NEW_CREATIVE_LIBRARY_NAME", newFlashtalkingCreativeLibraryName)
    },
    distributeCreatives ({ dispatch, getters }) {
      if (getters.areIncompatibleCreativesSelected) {
        return
      }

      return axios
        .get(`creatives?id.in=${getters.postData.creativeIds.join(",")}&fields=id,units&returnFullUnits=1`)
        .then(response => {
          const unitVariantLocalIds = getters.postData.creativeIds.map(creativeId => response.data.find(creative => creative.id === creativeId).units.banner.variants[0].localId)
          let postData = {
            unitVariantLocalIds,
            ...getters.postData,
          }

          if (postData.flashtalkingNewCreativeLibraryName === null) {
            return dispatch("createCreativeDistributionJobRequest", postData)
          }

          return dispatch("createCreativeLibrary")
            .then(response => {
              postData.flashtalkingCreativeLibraryId = response.data.id
              return dispatch("createCreativeDistributionJobRequest", postData)
            })
        })
    },
    createCreativeDistributionJobRequest ({ dispatch }, postData) {
      return axios
        .post("creativeDistributionJobsRequest", postData)
        .then(response => dispatch("addDistributions", response.data.creativeDistributionIds))
    },
    createCreativeLibrary ({ getters }) {
      return axios.post("flashtalking/creativeLibraries", getters.creativeLibraryPostData)
    },
    addDistributions ({ dispatch, getters }, distributionIds) {
      const newDistributions = []
      const exportedCreatives = getters.creativesForExport
      for (const index in distributionIds) {
        const nowISO = new Date().toISOString().split(".")[0].replace("T", " ")
        const exportedCreative = exportedCreatives[index]
        newDistributions.push({
          id: distributionIds[index],
          creativeId: exportedCreative.id,
          builderCreativeVersion: exportedCreative.builderCreativeVersion,
          creationTimestamp: nowISO,
          status: "IN_PROGRESS",
          errorMessage: null,
          flashtalkingCreativeId: null,
        })
      }
      dispatch("creativesTable/addDistributions", newDistributions, { root: true })
    },
    setIsAuthorized ({ commit, dispatch }, isAuthorized) {
      commit("SET_IS_AUTHORIZED", isAuthorized)
      dispatch("loadFlashtalkingAssets")
    },
    setAuthorizationErrorMessage ({ commit }, authorizationErrorMessage) {
      commit("SET_AUTHORIZATION_ERROR_MESSAGE", authorizationErrorMessage)
    },
    resetState({ commit }) {
      commit("RESET_STATE")
    },
  },
  mutations: {
    SET_IS_AUTHORIZED(state, isAuthorized) {
      state.isAuthorized = isAuthorized
    },
    SET_IS_FLASHTALKING_DATA_LOADING(state, isFlashtalkingDataLoading) {
      state.isFlashtalkingDataLoading = isFlashtalkingDataLoading
    },
    SET_FLASHTALKING_BUSINESS_ENTITY_OPTIONS (state, flashtalkingBusinessEntityOptions) {
      state.flashtalkingBusinessEntityOptions = flashtalkingBusinessEntityOptions
    },
    SET_FLASHTALKING_ADVERTISER_OPTIONS (state, flashtalkingAdvertiserOptions) {
      state.flashtalkingAdvertiserOptions = flashtalkingAdvertiserOptions
    },
    SET_FLASHTALKING_CREATIVE_LIBRARY_OPTIONS (state, flashtalkingCreativeLibraryOptions) {
      state.flashtalkingCreativeLibraryOptions = flashtalkingCreativeLibraryOptions
    },
    SET_FLASHTALKING_BUSINESS_ENTITY(state, flashtalkingBusinessEntityId) {
      state.flashtalkingBusinessEntityId = flashtalkingBusinessEntityId
      state.flashtalkingAdvertiserId = null
      state.flashtalkingCreativeLibraryId = null
    },
    SET_FLASHTALKING_ADVERTISER(state, flashtalkingAdvertiserId) {
      state.flashtalkingAdvertiserId = flashtalkingAdvertiserId
      state.flashtalkingCreativeLibraryId = null
    },
    SET_FLASHTALKING_CREATIVE_LIBRARY(state, flashtalkingCreativeLibraryId) {
      state.flashtalkingCreativeLibraryId = flashtalkingCreativeLibraryId
      state.newFlashtalkingCreativeLibraryName = null
    },
    SET_FLASHTALKING_NEW_CREATIVE_LIBRARY_NAME(state, flashtalkingNewCreativeLibraryName) {
      state.flashtalkingNewCreativeLibraryName = flashtalkingNewCreativeLibraryName
      state.flashtalkingCreativeLibraryId = null
    },
    SET_AUTHORIZATION_ERROR_MESSAGE(state, authorizationErrorMessage) {
      state.authorizationErrorMessage = authorizationErrorMessage
    },
    RESET_STATE(state) {
      Object.assign(state, getState())
    },
  },
}
