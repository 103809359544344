"use strict";
/* eslint semi: ["error", "always"] */
(function (namespace) {
  function PendoTracking() {
    this.started = false;
  }

  PendoTracking.prototype.start = function (apiKey, celtraAccount, celtraCampaignId, keyGeneratedByUser) {
    if (this.started) {
      return warn("Already started.");
    }

    if (typeof apiKey !== "string") {
      return warn("Missing `apiKey`, aborting.");
    }

    if (celtraAccount == null) {
      return warn("Missing `celtraAccount`, aborting.");
    }

    if (celtraCampaignId == null) {
      return warn("Missing `celtraCampaignId`, aborting.");
    }

    let account = {
      id: `CA_${celtraAccount.id}`,
      identifier: celtraAccount.identifier,
      clientType: celtraAccount.clientType,
      featurePacks: celtraAccount.featurePacks,
      isActive: celtraAccount.isActive,
      productType: "CA",
      creationTime: typeof celtraAccount.creationTimestamp === "number" ? new Date(celtraAccount.creationTimestamp * 1000).toISOString() : undefined,
      campaignId: celtraCampaignId,
    };

    let visitor = keyGeneratedByUser;

    return loadPendo(apiKey)
      .then(function () {
        initPendo.call(this, { account, visitor });
      }.bind(this))
      .catch(function (e) {
        // gracefully fail, we don"t want to block page load just because tracking exploded
        console.error("Pendo tracking: Error occurred: ", e);
      });
  };

  function loadPendo(apiKey) {
    if (namespace.pendo && typeof namespace.pendo.initialize === "function") {
      return Promise.resolve(); // skip loading if already present
    }

    return new Promise(function (resolve, reject) {
      (function (p, e, n, d, o) {
        let w, x; o = p[d] = p[d] || {}; o._q = o._q || [];
        const v = ["initialize", "identify", "updateOptions", "pageLoad", "track"]; for (w = 0, x = v.length; w < x; ++w) {
          (function (m) {
            o[m] = o[m] || function () {
              o._q[m === v[0] ? "unshift" : "push"]([m].concat([].slice.call(arguments, 0)));
            };
          })(v[w]);
        }
        const y = e.createElement(n); y.async = !0;
        y.src = "https://cdn.pendo.io/agent/static/" + apiKey + "/pendo.js";
        y.onload = resolve;
        y.onerror = reject;
        const z = e.getElementsByTagName(n)[0]; z.parentNode.insertBefore(y, z);
      })(window, document, "script", "pendo");
    });
  }

  function initPendo({ account, visitor }) {
    this.started = true;
    namespace.pendo.initialize({ account, visitor });
  }

  function warn(message) {
    console.warn(`Pendo tracking: ${message}`);
  }

  // export as Singleton
  if (typeof module !== "undefined" && module !== null) {
    module.exports = new PendoTracking();
  } else if (namespace.PendoTracking) {
    warn("Already imported, skipping import.");
  } else {
    namespace.PendoTracking = new PendoTracking();
  }
})(window);
