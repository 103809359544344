import { cloneDeep, orderBy } from "lodash-es"
import { standardAttributes } from "./store_constants"

const state = {
}

const getState = () => JSON.parse(JSON.stringify(state))

export default {
  namespaced: true,
  state: getState(),
  getters: {
    tiles (state, _, rootState) {
      const standardAttributesObject = cloneDeep(standardAttributes)
      const tiles = [...standardAttributesObject]
      const mediaAttributeKeys = ["product", "format", "size"]
      rootState.creatives.forEach(creative => {
        appendDataToStandardTiles(creative)
        for (let [key, value] of Object.entries(creative.contentAttributesWithLabels)) {
          if (mediaAttributeKeys.includes(key)) {
            key = `${key}ContentAttributes`
          }
          appendDataToTile(key, value.label, value.value)
        }
        for (let [key, value] of Object.entries(creative.layoutAttributesWithLabels)) {
          if (mediaAttributeKeys.includes(key)) {
            key = `${key}LayoutAttributes`
          }
          appendDataToTile(key, value.label, value.value)
        }
      })

      const standardTileIds = standardAttributes.map(t => t.id)
      const nonStandardTiles = orderBy(tiles.filter(t => !standardTileIds.includes(t.id)), [(t) => t.values.length], ["desc"])
      const tilesOrderIds = standardTileIds.concat(nonStandardTiles.map(t => t.id))
      return tilesOrderIds.map(tileId => getTileWithId(tileId))

      function appendDataToTile (id, label, value) {
        const tileOfAttribute = getTileWithId(id)
        if (tileOfAttribute && !tileOfAttribute.values.includes(value)) {
          tileOfAttribute.values.push(value)
        } else if (!tileOfAttribute) {
          tiles.push({
            id: id,
            label: label,
            values: [value],
          })
        }
      }

      function appendDataToStandardTiles (creative) {
        const countTile = getTileWithId("count")
        countTile.values.push(creative.name)

        appendDataToTile("product", null, creative.mediaAttributes.product)
        appendDataToTile("format", null, creative.mediaAttributes.format)
        appendDataToTile("size", null, creative.mediaAttributes.size)
      }

      function getTileWithId (id) {
        for (const tile of tiles) {
          if (tile.id === id) {
            return tile
          }
        }
        return null
      }
    },
    tableValueItems (_, getters) {
      // 1st tile is creative count that is not displayed in the table
      return [...getters.tiles].slice(1)
    },
    tableValues (_, getters, rootState) {
      const tableValues = {}
      const attributeSequence = getters.tableValueItems.map(item => item.id)
      rootState.creatives.forEach(creative => {
        const attributes = getCreativeAttributes(creative)
        let objectToUpsert = tableValues
        attributeSequence.forEach(tileId => {
          const attributeKey = attributes[tileId] || attributes[`${tileId}ContentAttributes`] || attributes[`${tileId}LayoutAttributes`] || "N/A"
          if (!objectToUpsert[attributeKey]) {
            objectToUpsert[attributeKey] = {}
          }
          objectToUpsert = objectToUpsert[attributeKey]
        })
      })

      return tableValues

      function getCreativeAttributes(creative) {
        const attributes = { ...creative.mediaAttributes }

        const attributeTypes = ["contentAttributesWithLabels", "layoutAttributesWithLabels"]
        attributeTypes.forEach(attributeType => {
          for (const [key, value] of Object.entries(creative[attributeType])) {
            if (attributeType === "contentAttributesWithLabels") {
              attributes[`${key}ContentAttributes`] = value.value
            } else if (attributeType === "layoutAttributesWithLabels") {
              attributes[`${key}LayoutAttributes`] = value.value
            }
          }
        })

        return attributes
      }
    },
  },
  actions: {},
  mutations: {},
}
