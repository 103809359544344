<template>
    <div class="flashtalking-login">
        <div class="flashtalking-login__form">
            <div class="flashtalking-login__text">
                Please enter your Flashtalking credentials
            </div>
            <a-form
              trackName="flashtalking-login-form"
              :rules="rules"
              :model="loginFormState"
              @finish="handleFinish"
            >
              <a-form-item name="username" class="flashtalking-login__username">
                  <a-input
                      trackName="flashtalking-username"
                      placeholder="Username"
                      v-model:value="loginFormState.username"
                      @change="clearAuthorizationErrorMessage"
                  />
              </a-form-item>
              <a-form-item name="password" class="flashtalking-login__password">
                  <a-input-password
                      trackName="flashtalking-password"
                      placeholder="Password"
                      v-model:value="loginFormState.password"
                      @change="clearAuthorizationErrorMessage"
                  />
              </a-form-item>
              <a-button
                trackName="flashtalking-login-submit"
                html-type="submit"
                type="primary"
                class="flashtalking-login__button"
                :loading="isAuthorizationLoading"
              >
                Login
              </a-button>
          </a-form>
          <div v-if="authorizationErrorMessage" class="flashtalking-login__error">{{ authorizationErrorMessage }}</div>
        </div>
    </div>
</template>

<script>
import { computed, reactive, ref } from "vue"
import { useStore } from "vuex"

export default {
  name: "FlashtalkingLoginStep",
  setup () {
    const store = useStore()

    const loginFormState = reactive({
      username: "",
      password: "",
    })
    const rules = ref({
      username: [{
        required: true,
        message: "Username is required",
        trigger: "change",
      }],
      password: [{
        required: true,
        message: "Password is required",
        trigger: "change",
      }],
    })
    const isAuthorizationLoading = computed(() => store.state.distributionDialog.flashtalking.isAuthorizationLoading)
    const authorizationErrorMessage = computed(() => store.state.distributionDialog.flashtalking.authorizationErrorMessage)

    function handleFinish (userPassObject) {
      store.dispatch("distributionDialog/flashtalking/loginToFlashtalking", userPassObject)
    }

    function clearAuthorizationErrorMessage () {
      store.dispatch("distributionDialog/flashtalking/setAuthorizationErrorMessage", null)
    }

    return {
      loginFormState,
      rules,
      isAuthorizationLoading,
      authorizationErrorMessage,
      handleFinish,
      clearAuthorizationErrorMessage,
    }
  },
}
</script>

<style lang="less">
@import (reference) '~design-system-variables/index';

.flashtalking-login {
  position: relative;
  height: 200px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__form {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__text {
    margin-bottom: 38px;
    font-size: 14px;
  }

  &__error {
    position: absolute;
    top: 100px;
    left: 20px;
    width: 70%;
    font-size: 12px;
    color: @danger-red;
  }
}

.flashtalking-login__button.flashtalking-login__button.flashtalking-login__button {
  position: absolute;
  right: 16px;
  bottom: 50px;
}

.flashtalking-login__password.flashtalking-login__password.flashtalking-login__password {
  width: 175px;
  margin-left: 10px;
  margin-right: 0;
}

.flashtalking-login__username.flashtalking-login__username {
  width: 175px;
}
</style>
