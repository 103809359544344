<template>
  <c-theme-provider theme="dark">
    <router-view />
  </c-theme-provider>
</template>

<script>

export default {
  name: "App",
  components: {},
  setup () {
    document.title = "Distribution Portal - Celtra"
  },
}
</script>
