<template>
  <div class="dcm-step">
    <a-tooltip placement="bottom" v-if="areIncompatibleCreativesSelected">
      <div class="dcm-step__notice">
        You have selected creatives that cannot be distributed to Campaign Manager 360. To distribute to Campaign Manager 360, please close the dialog and limit the selection to Campaign Manager 360 creatives.
      </div>
      <template #title>
        <div>{{ formatsDistributableToDcm }}</div>
      </template>
    </a-tooltip>

    <template v-else>
      <div class="dcm-step__intro" v-if="!isOAuthDone">
        <div class="dcm-step__intro-text">Authorize to push creatives directly to Campaign Manager 360.</div>
        <a-button
          class="dcm-step__login-button"
          type=primary
          track-name="dcm-login"
          @click="onLogin"
        >
          Authorize via Google
        </a-button>
        <div class="dcm-step__intro-button" />
      </div>

      <div class="dcm-step__destination" v-else>
        <div class="dcm-step__destination-loading" v-if="isDcmDataLoading">
          <a-spin class="dcm-step__destination-spinner" tip="Loading Campaign Manager 360 assets" size="large" />
        </div>

        <template v-else>
          <distribution-autocomplete
            placeholder="Select account"
            name="Campaign Manager 360 Account"
            :options="dcmAccountOptions ?? []"
            @change="selectDcmAccount"
          />
          <distribution-autocomplete
            placeholder="Select advertiser"
            name="Campaign Manager 360 Advertiser"
            :options="dcmAdvertiserOptions ?? []"
            :is-loading="areDcmAdvertiserOptionsLoading"
            :is-disabled="isDcmAdvertiserSelectDisabled"
            :on-search-method="onSearchMethod"
            @change="selectDcmAdvertiser"
          />
        </template>
      </div>
    </template>
  </div>
</template>

<script>
import { computed, onMounted, onUnmounted } from "vue"
import { useStore } from "vuex"
import DistributionAutocomplete from "./DistributionAutocomplete"

export default {
  components: {
    DistributionAutocomplete,
  },
  setup() {
    onMounted(() => window.addEventListener("message", handleCodeMessage))
    onUnmounted(() => window.removeEventListener("message", handleCodeMessage))

    const store = useStore()
    const isOAuthDone = computed(() => store.state.distributionDialog.dcm.isOAuthDone)
    function onLogin () {
      store.dispatch("distributionDialog/dcm/openOAuthDialog")
    }

    const formatsDistributableToDcm = computed(() => store.getters["distributionDialog/dcm/formatsDistributableToDcmString"])
    const areIncompatibleCreativesSelected = computed(() => store.getters["distributionDialog/dcm/areIncompatibleCreativesSelected"])
    const isDcmDataLoading = computed(() => store.state.distributionDialog.dcm.isDcmDataLoading)

    const dcmAccountOptions = computed(() => store.state.distributionDialog.dcm.dcmAccountOptions)
    function selectDcmAccount (value) {
      store.dispatch("distributionDialog/dcm/selectDcmAccount", value)
    }
    const dcmAdvertiserOptions = computed(() => store.state.distributionDialog.dcm.dcmAdvertiserOptions)
    const areDcmAdvertiserOptionsLoading = computed(() => store.state.distributionDialog.dcm.dcmAccountId != null && dcmAdvertiserOptions.value == null)
    function selectDcmAdvertiser (dcmAdvertiser) {
      store.dispatch("distributionDialog/dcm/selectDcmAdvertiser", dcmAdvertiser)
    }

    const isDcmAdvertiserSelectDisabled = computed(() => store.state.distributionDialog.dcm.dcmAccountId == null || dcmAdvertiserOptions.value == null)
    const onSearchMethod = (value) => store.dispatch("distributionDialog/dcm/loadDcmAdvertiserOptions", value)

    return {
      isOAuthDone,
      formatsDistributableToDcm,
      areIncompatibleCreativesSelected,
      isDcmDataLoading,
      dcmAccountOptions,
      dcmAdvertiserOptions,
      areDcmAdvertiserOptionsLoading,
      selectDcmAccount,
      selectDcmAdvertiser,
      isDcmAdvertiserSelectDisabled,
      onSearchMethod,
      onLogin,
    }

    function handleCodeMessage (ev) {
      store.dispatch("distributionDialog/dcm/handleCodeMessage", ev)
    }
  },
}
</script>

<style lang="less" scoped>
@import (reference) '~design-system-variables/index';

.dcm-step__notice {
  text-align: center;
  font-size: 14px;
}

.dcm-step__destination-loading {
  display: flex;
  justify-content: center;
}

.dcm-step__intro {
  text-align: center;
}

.dcm-step__intro-text {
  margin-bottom: 16px;
}

</style>
