<template>
  <a-alert
    track-name="ad-set-alert"
    message="Warning"
    type="warning"
    showIcon
    class="distribution-alert"
  >
    <template #description>
      <span class="distribution-alert-description">{{ message }}</span>
      <a-checkbox
        v-if="solvable"
        track-name="create-live-ad"
        :checked="checked"
        @update:checked="updateChecked"
        class="distribution-alert-checkbox"
      >
        {{ solvableMessage }}
      </a-checkbox>
    </template>
  </a-alert>
</template>

<script>
import { ref } from "vue"

export default {
  props: {
    message: { type: String, required: true },
    solvable: { type: Boolean, required: true },
    solvableMessage: { type: String },
  },
  emits: ["solve"],
  setup (_, { emit }) {
    const checked = ref(false)
    function updateChecked(value) {
      checked.value = value
      emit("solve", value)
    }

    return {
      checked,
      updateChecked,
    }
  },
}
</script>

<style lang="less" scoped>

.distribution-alert.distribution-alert.distribution-alert.distribution-alert {
  box-shadow: none;
  margin-top: -14px;
  margin-bottom: 20px;
}

.distribution-alert-description {
  display: inline-block;
}

.distribution-alert-checkbox {
  position: relative;
  left: -37px;
  margin-top: 16px;
}
</style>
