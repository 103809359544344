import Main from "@components/Main.vue"
import LoginCallback from "@components/login/LoginCallback.vue"

export const routes = [
  {
    name: "Main",
    path: "/",
    component: Main,
  },
  {
    name: "CeltraOAuthCallback",
    path: "/CeltraOAuthCallback",
    component: LoginCallback,
    props: (route) => ({ code: route.query.code }),
  },
]
