<template>
  <c-theme-provider theme="light">
    <div class="distribution-dialog">
      <a-modal
        :visible="true"
        track-name="distribution-dialog"
        :width="820"
        @cancel="onClose"
      >
        <template #title>
          Export
        </template>

        <div class="distribution-dialog-wrapper">
          <div class="distribution-dialog__error" v-if="errorMessage">
            {{ errorMessage }}
          </div>

          <template v-else>
            <div class="distribution-dialog__methods">
              <div class="distribution-dialog__methods-heading">
                CONNECTORS
              </div>
              <div
                class="distribution-dialog__method"
                :class="{ 'distribution-dialog__method--selected': namespace === method.id }"
                v-for="method in distributionMethods"
                :key="method.id"
                @click="selectMethod(method.id)"
              >
                <img class="distribution-dialog__method-icon" :src="method.icon" >
                <div class="distribution-dialog__method-title">{{ method.label }}</div>
              </div>
            </div>

            <div class="distribution-dialog__step">
              <facebook-step v-if="namespace === 'facebook'" />
              <dcm-step v-if="namespace === 'dcm'" />
              <flashtalking-step v-if="namespace === 'flashtalking'" />
              <zip-step v-if="namespace === 'zip'" />
            </div>
          </template>
        </div>

        <template #footer>
          <a-button
            track-name="distribution-dialog-cancel"
            type="ghost"
            @click="onClose"
          >
            Cancel
          </a-button>
          <a-button
            track-name="distribution-dialog-submit"
            type="primary"
            @click="handleExport"
            :disabled="!isDialogValid"
            :loading="isDialogLoading"
          >
            Export
          </a-button>
        </template>
      </a-modal>
      <prompt-text @close="onClose" />
    </div>
  </c-theme-provider>
</template>

<script>
import { computed } from "vue"
import { useStore } from "vuex"
import FacebookStep from "./FacebookStep"
import ZipStep from "./ZipStep"
import DcmStep from "./DcmStep"
import FlashtalkingStep from "./FlashtalkingStep.vue"
import PromptText from "./PromptText.vue"

export default {
  components: {
    FacebookStep,
    ZipStep,
    DcmStep,
    FlashtalkingStep,
    PromptText,
  },
  setup (_, { emit }) {
    const store = useStore()
    const namespace = computed(() => store.state.distributionDialog.namespace)
    const distributionMethods = computed(() => store.getters["distributionDialog/distributionMethods"])
    const isDialogValid = computed(() => store.getters["distributionDialog/isDialogValid"])
    const isDialogLoading = computed(() => store.state.distributionDialog.isLoading)
    const promptVisible = computed(() => store.state.distributionDialog.zip.promptVisible)
    const errorMessage = computed(() => store.state.distributionDialog.errorMessage)

    function selectMethod (method) {
      store.dispatch("distributionDialog/selectDistributionMethod", method)
    }

    function onClose () {
      store.dispatch("distributionDialog/resetDialog")
      emit("close")
    }

    function handleExport () {
      store.dispatch("distributionDialog/distributeCreatives")
        .then(() => {
          if (promptVisible.value !== true) {
            onClose()
          }
        })
    }

    return {
      namespace,
      distributionMethods,
      isDialogLoading,
      isDialogValid,
      errorMessage,
      selectMethod,
      handleExport,
      onClose,
    }
  },
}
</script>

<style lang="less" scoped>
@import (reference) '~design-system-variables/index';

.distribution-dialog {
  color: white;
}

.distribution-dialog__error {
  width: 100%;
  color: @danger-red;
  text-align: center;
  font-size: 16px;
}

.distribution-dialog-wrapper {
  display: flex;
}

.distribution-dialog__methods {
  padding-right: 8px;
  border-right: 1px solid @silver;
}

.distribution-dialog__step {
  width: 100%;
  margin: 32px 8px 32px 32px;
}

.distribution-dialog__methods-heading {
  margin: 0 0 20px 16px;
  color: @smog;
  text-transform: uppercase;
  font-weight: 500;
}

.distribution-dialog__method {
  display: flex;
  cursor: pointer;
  width: 240px;
  padding-left: 16px;
  margin-bottom: 8px;
  border-radius: 8px;
}

.distribution-dialog__method--selected {
  color: @primary-blue;
  background-color: #EBF1FF;
}

.distribution-dialog__method-icon {
  margin: 5px 8px 0 0;
  width: 20px;
  height: 20px;
}

.distribution-dialog__method-title {
  line-height: 32px;
}
</style>
