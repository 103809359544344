export const initialAttributeGroups = [
  {
    value: "Distribution Id",
    options: [
      {
        value: "Non-distributed",
        creativeIds: [],
      },
      {
        value: "Distributed",
        creativeIds: [],
      },
    ],
  },
  {
    value: "Distribution channel",
    options: [
      {
        value: "Facebook",
        creativeIds: [],
      },
      {
        value: "Campaign Manager",
        creativeIds: [],
      },
      {
        value: "Flashtalking",
        creativeIds: [],
      },
    ],
  },
  {
    value: "Product",
    options: [],
  },
  {
    value: "Format",
    options: [],
  },
  {
    value: "Size",
    options: [],
  },
]

export const attributeTypes = ["mediaAttributes", "contentAttributesWithLabels", "layoutAttributesWithLabels"]

export const initialSortByOptions = [
  {
    id: "Name",
    label: "Name",
    value: "name",
  },
  {
    id: "Distribution Id",
    label: "Distribution",
    value: "orderByDistribution",
  },
]

export const supportedDistributions = ["DCM", "Flashtalking"]

export const supportedDistributionAllocations = ["Facebook"]

export const errorMessageSolutionIdentification = {
  "Error publishing Facebook creative: You don't have required permission to access this profile": {
    identifier: "profile-permission",
    displayMessage: "Your Facebook account doesn't have the required page or ad account permissions.",
  },
  "To use nearby store information in ads, a Store Set must be specified for the ad group": {
    identifier: "store-set",
    displayMessage: "The Facebook Campaign you are pushing to has Store Traffic as the Campaign Objective and a Store Set must be specified for the ad group.",
  },
  "Unsupported request - method type: post": {
    identifier: "post",
    displayMessage: "There was an issue with Facebook's Marketing API.",
  },
  "The Page selected for your ad doesn't match the Page associated with the object you're promoting, like a Page post or app.  Please make sure the Pages are the same.": {
    identifier: "same-pages",
    displayMessage: "The page selected for distribution does not match the page or app you are promoting on Facebook.",
  },
  "Please ensure that the CTA exists and the URL associated with your ad creative matches the object store URL set on your promoted object": {
    identifier: "same-pages",
    displayMessage: "The page selected for distribution does not match the page or app you are promoting on Facebook.",
  },
  "Error uploading media file to Facebook: We failed uploading image due to system error. Please try again later.": {
    identifier: "image-upload-fail",
    displayMessage: "An issue occurred while uploading the creative to Facebook.",
  },
  "A Reach and Frequency campaign must have at least one active ad": {
    identifier: "reach-frequency",
    displayMessage: "Campaigns with Reach and Frequency objectives must have at least one active ad.",
  },
  "Instagram Feed only supports aspect ratios up to 4:5 tall and 1.91:1 wide.": {
    identifier: "aspect-ratio",
    displayMessage: "Instagram Feed only supports aspect ratios up to 4:5 tall and 1.91:1 wide.",
  },
  "call_to_action_type field in creative is required in this ad": {
    identifier: "missing-cta",
    displayMessage: "Call-to-action is required to distribute this ad.",
  },
  "Your campaign objective requires an external website URL. Select a call to action and enter a website URL in the ad creative section.": {
    identifier: "missing-cta",
    displayMessage: "Call-to-action is required to distribute this ad.",
  },
  "The link in this ad is either missing or invalid for Link Click Ads optimization.": {
    identifier: "missing-cta",
    displayMessage: "Call-to-action is required to distribute this ad.",
  },
  "The call to action type [A-Z_]* is not supported for the objective [A-Z_]* in .*": {
    identifier: "invalid-cta",
    displayMessage: "Invalid call-to-action type for the selected campaign objective.",
  },
  "link field is required. Please complete the field ": {
    identifier: "missing-link",
    displayMessage: "Depending on the campaign objective, a CTA, Deep Link or Website URL is required to distribute this ad.",
  },
  "To set link_description in video_data, you must specify call_to_action": {
    identifier: "missing-cta-for-video-description",
    displayMessage: "CTA and Website URL are required for the video link description.",
  },
  "Please remove all values from the location_types field.": {
    identifier: "old-targeting-setting",
    displayMessage: "Targeting setting on this ad set is no longer supported on Meta.",
  },
}

export const numberOfCreativesPerPage = 40
