import axios from "axios"
import { extractErrorMessageFromError, getLocalStorageItem, setLocalStorageItem } from "@store/utils"
import pendoTracking from "@/PendoTracking.js"

const CELTRA_LOGIN_REQUIRED_FEATURE_PACK = "distributionPrototypeLoginRequired"

const state = {
  distributionPrototypeAccessKeyId: null,
  accountId: null,
  campaignId: null,
  campaignName: null,
  timezone: null,
  locale: null,
  celtraLoginRequired: false,
  accountAccessDenied: false,
  user: {},
  creatives: [],
  creativesOverflow: false,
  creativesFilterParams: [],
  isLoading: true,
  pageLoaded: false,
  errorMessage: null,
}

const getState = () => JSON.parse(JSON.stringify(state))

export default {
  namespaced: true,
  state: getState(),
  getters: {
    isExportButtonDisabled (state, getters, rootState, rootGetters) {
      return state.isLoading || rootGetters["creativesTable/selectedFilteredRowKeys"].length === 0
    },
    exportButtonDisabledTooltip (state, getters) {
      if (!getters.isExportButtonDisabled) {
        return null
      }

      if (state.isLoading) {
        return "Please wait for the creatives to load."
      }

      if (state.creativesTable.selectedRowKeysArray.length === 0) {
        return "Please select at least one creative."
      }

      return null
    },
    accountAccessLocalStorageKey (state) {
      return state.accountId === null ? null : `${state.accountId}:accessExpirationDate`
    },
    accountUserIdLocalStorageKey (state) {
      return state.accountId === null ? null : `${state.accountId}:userId`
    },
  },
  actions: {
    async initialize ({ commit, dispatch, getters }, secret) {
      if (!secret) {
        commit("SET_ERROR_MESSAGE", "This url is invalid!")
        commit("SET_IS_LOADING", false)
        commit("SET_PAGE_LOADED", true)
        return
      }

      try {
        const accessKeyResponse = await axios.get("distributionPrototypeAccessKeys?perPage=1&fields=id,accountId,campaignId,user,creativesFilterParams")
        if (accessKeyResponse.data.length === 0) {
          throw new Error()
        }

        const accessKey = accessKeyResponse.data[0]
        commit("SET_DISTRIBUTION_PROTOTYPE_ACCESS_KEY", accessKey.id)
        commit("SET_ACCOUNT_ID", accessKey.accountId)
        commit("SET_CAMPAIGN_ID", accessKey.campaignId)
        commit("SET_USER", accessKey.user)
        commit("SET_CREATIVES_FILTER_PARAMS", accessKey.creativesFilterParams)

        const campaignResponse = await axios.get(`folders/${accessKey.campaignId}?fields=name`)
        if (campaignResponse.data.length === 0) {
          throw new Error()
        }
        const campaign = campaignResponse.data
        commit("SET_CAMPAIGN_NAME", campaign.name)

        const accountResponse = await axios.get(`accounts/${accessKey.accountId}?fields=id,identifier,clientType,isActive,creationTimestamp,timezone,locale,featurePacks`)
        if (accountResponse.data.length === 0) {
          throw new Error()
        }
        const account = accountResponse.data
        pendoTracking.start(process.env.VUE_APP_PENDO_API_KEY, account, accessKey.campaignId, accessKey.user)

        const celtraLoginRequired = account.featurePacks.includes(CELTRA_LOGIN_REQUIRED_FEATURE_PACK)
        commit("SET_TIMEZONE", account.timezone)
        commit("SET_LOCALE", account.locale.replace("_", "-"))

        if (celtraLoginRequired) {
          const accountAccessExpirationDate = getLocalStorageItem(getters.accountAccessLocalStorageKey)
          const accountUserId = getLocalStorageItem(getters.accountUserIdLocalStorageKey)
          if (accountAccessExpirationDate === null || Date.now() > parseInt(atob(accountAccessExpirationDate)) || accountUserId === null) {
            commit("SET_IS_LOADING", false)
            commit("SET_CELTRA_LOGIN_REQUIRED", true)
            return
          }
        }

        dispatch("initializeCreatives")
      } catch (error) {
        if (error.response.status === 401 && error.response.data.message === "Distribution prototype access key expired.") {
          commit("SET_ERROR_MESSAGE", "Access key expired. Please contact the same user who granted you the initial access key to obtain a new one.")
        } else {
          console.error(error)
          commit("SET_ERROR_MESSAGE", "Initializing failed, please check if the url is correct and try again!")
        }
      }
    },
    async initializeCreatives ({ state, commit, dispatch }) {
      try {
        const creativesResponse = await dispatch("loadCreatives")
        if (state.creativesOverflow) {
          return
        }

        if (creativesResponse.length === 0) {
          throw new Error()
        }
        const creativesResponseFiltered = creativesResponse.filter(c => !["AnimatedBanner", "Snapchat"].includes(c.clazz))

        for (const creative of creativesResponseFiltered) {
          if (creative.product === "FACEBOOK" && creative.units && creative.units.banner) {
            const facebookPlacements = new Set()
            if (creative.units.banner.variants) {
              for (const variant of creative.units.banner.variants) {
                if (variant.facebookPlacementId) {
                  facebookPlacements.add(variant.facebookPlacementId)
                }
              }
            }

            creative.facebookPlacements = [...facebookPlacements]
            creative.facebookCallToAction = creative.units.banner.facebookCallToAction ?? null
            creative.facebookWebsiteUrl = creative.units.banner.facebookWebsiteUrl ?? null
            creative.facebookDeeplink = creative.units.banner.facebookDeeplink ?? null
          }

          delete creative.units
        }

        commit("SET_CREATIVES", creativesResponseFiltered)
        dispatch("creativesTable/initialize")
      } catch (error) {
        console.error(error)
        commit("SET_ERROR_MESSAGE", "Initializing failed, please check if the url is correct and try again!")
      } finally {
        commit("SET_IS_LOADING", false)
        commit("SET_PAGE_LOADED", true)
      }
    },
    async loadCreatives ({ state, commit }) {
      const creativeFields = [
        "id",
        "name",
        "product",
        "clazz",
        "folderId",
        "isAbleToExportVideoUnit",
        "adServerExportRestrictions",
        "mediaAttributes",
        "contentAttributesWithLabels",
        "layoutAttributesWithLabels",
        "builderCreativeVersion",
        "thumbnailUrl",
        "units",
      ]

      let creativesUrl = "creatives?isArchived=0&isDeleted=0&folderClazz.in=UniversalCampaign,Batch&isEffectivelyArchived=0&returnFullUnits=1"
      if (state.creativesFilterParams.length > 0) {
        creativesUrl += `&${state.creativesFilterParams.join("&")}`
      }

      const creativesResponse = await axios.get(`${creativesUrl}&page=1&perPage=1&fields=id`)
      const creativesCount = creativesResponse.headers["x-total-count"]
      if (creativesCount > 10000) {
        commit("SET_CREATIVES_OVERFLOW", true)
        return
      }

      const perPage = 250
      const numberOfPages = Math.ceil(creativesCount / perPage)
      const promises = []

      for (let page = 1; page <= numberOfPages; page++) {
        const creativesUrlForPage = `${creativesUrl}&page=${page}&perPage=${perPage}&fields=${creativeFields.join(",")}`
        promises.push(axios.get(creativesUrlForPage))
      }

      return Promise.all(promises).then(responses => responses.flatMap(response => response.data))
    },
    setErrorMessage ({ commit }, errorMessage) {
      commit("SET_ERROR_MESSAGE", errorMessage)
    },
    openCeltraOAuthDialog: () => {
      window.open(`${axios.defaults.baseURL}oauth/authorize?client_id=${process.env.VUE_APP_UAB_OAUTH_CLIENT_ID}&response_type=code&state=${window.location.origin}`, "oauth", "width=1024,height=800")
    },
    handleCeltraCodeMessage: async ({ dispatch, commit, state, getters }, ev) => {
      if (!ev.data.code || !ev.data.type || ev.data.type !== "celtra") {
        return
      }

      commit("SET_IS_LOADING", true)

      const accessTokenResponse = await axios.get(`${axios.defaults.tokenExchangeURL}?code=${ev.data.code}`)
      if ("access_token" in accessTokenResponse.data) {
        const tokenAccountsResponse = await axios.get(`${axios.defaults.baseURL}/accounts?token=${accessTokenResponse.data.access_token}&fields=id`)
        const accountIdInTokenAccountsResponse = tokenAccountsResponse.data.some((account) => account.id === state.accountId)
        if (accountIdInTokenAccountsResponse) {
          setLocalStorageItem(getters.accountAccessLocalStorageKey, btoa((Date.now() + 7*24*60*60*1000).toString()))
          const userData = await axios.get(`${axios.defaults.baseURL}me?token=${accessTokenResponse.data.access_token}&fields=id`)
          setLocalStorageItem(getters.accountUserIdLocalStorageKey, userData.data.id)

          commit("SET_CELTRA_LOGIN_REQUIRED", false)
          dispatch("initializeCreatives")
        } else {
          commit("SET_ACCOUNT_ACCESS_DENIED", true)
          commit("SET_IS_LOADING", false)
        }
      }
    },
  },
  mutations: {
    SET_DISTRIBUTION_PROTOTYPE_ACCESS_KEY (state, distributionPrototypeAccessKeyId) {
      state.distributionPrototypeAccessKeyId = distributionPrototypeAccessKeyId
    },
    SET_ACCOUNT_ID (state, accountId) {
      state.accountId = accountId
    },
    SET_CAMPAIGN_ID (state, campaignId) {
      state.campaignId = campaignId
    },
    SET_CAMPAIGN_NAME (state, campaignName) {
      state.campaignName = campaignName
    },
    SET_TIMEZONE (state, timezone) {
      state.timezone = timezone
    },
    SET_LOCALE (state, locale) {
      state.locale = locale
    },
    SET_CELTRA_LOGIN_REQUIRED (state, celtraLoginRequired) {
      state.celtraLoginRequired = celtraLoginRequired
    },
    SET_ACCOUNT_ACCESS_DENIED (state, accountAccessDenied) {
      state.accountAccessDenied = accountAccessDenied
    },
    SET_USER (state, user) {
      state.user = user
    },
    SET_CREATIVES_FILTER_PARAMS (state, creativesFilterParams) {
      state.creativesFilterParams = creativesFilterParams
    },
    SET_CREATIVES (state, creatives) {
      state.creatives = creatives
    },
    SET_IS_LOADING (state, isLoading) {
      state.isLoading = isLoading
    },
    SET_PAGE_LOADED (state, pageLoaded) {
      state.pageLoaded = pageLoaded
    },
    SET_ERROR_MESSAGE (state, error) {
      state.errorMessage = extractErrorMessageFromError(error)
    },
    SET_CREATIVES_OVERFLOW (state, creativesOverflow) {
      state.creativesOverflow = creativesOverflow
    },
  },
}
