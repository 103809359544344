<template>
  <div class="creative-brief-bar">
    <div class="creative-brief-bar__tiles">
      <div
        class="creative-brief-bar__tile"
        v-for="tile in tiles"
        :key="tile.id"
      >
        <a-tooltip
          class="creative-brief-bar__tooltip"
          placement="bottom"
        >
          <div class="creative-brief-bar__tile-label">{{ tile.label }}</div>
          <div class="creative-brief-bar__tile-value">{{ tile.values.length }}</div>
          <template #title v-if="tile.id !== 'count'">
            <div class="creative-brief-bar__tooltip-title">{{ tile.label }}</div>
            <div class="creative-brief-bar__tooltip-values">
              <div v-for="value in tile.values" :key="value">{{ value }}</div>
            </div>
          </template>
        </a-tooltip>
      </div>
    </div>

    <div class="creative-brief-bar__buttons">
      <div v-if="numberOfFailedDistributions > 0" class="creative-brief-bar__button--wider creative-brief-bar__button" @click="openDistributionErrorsDialog">
        <c-icon name="warning-regular" size="large" class="creative-brief-bar__warning" />
        <span>{{ numberOfFailedDistributionsString() }}</span>
      </div>
      <div class="creative-brief-bar__button--bigger creative-brief-bar__button" @click="openCreativeBriefDialog">Creative brief</div>
      <a-dropdown track-name="dropdown">
        <a-button track-name="a-button" class="creative-brief-bar__gear">
          <template #icon>
            <img src="gear.svg" alt="Select columns">
          </template>
        </a-button>
        <template #overlay>
          <a-menu track-name="a-menu">
            <a-menu-item
              v-for="column in allColumns"
              :key="column"
              class="columns-dropdown-item"
            >
                <div class="creative-brief-bar__item">
                  {{ column }}
                  <c-icon
                    v-if="isColumnVisible(column)"
                    @click.prevent.stop="setColumnVisible(column, true)"
                    name="eye-open-regular"
                    class="creative-brief-bar__eye-icon"
                  />
                  <c-icon
                    v-else
                    @click.prevent.stop="setColumnVisible(column, false)"
                    name="eye-closed-regular"
                    class="creative-brief-bar__eye-icon creative-brief-bar__eye-icon--ghost"
                  />
              </div>
            </a-menu-item>
          </a-menu>
        </template>
      </a-dropdown>
    </div>
  </div>
</template>

<script>
import { computed } from "vue"
import { useStore } from "vuex"

export default {
  setup (_, { emit }) {
    const store = useStore()
    const tiles = computed(() => store.getters["creativeBrief/tiles"])

    const allColumns = computed(() => store.getters["creativesTable/allColumns"])
    const selectedColumns = computed(() => store.state.creativesTable.selectedColumns)

    const numberOfFailedDistributions = computed(() => store.getters["creativesTable/numberOfFailedDistributions"])
    function numberOfFailedDistributionsString () {
      return numberOfFailedDistributions.value === 1 ? "One ad with error" : `${numberOfFailedDistributions.value} ads with errors`
    }

    function isColumnVisible (column) {
      return selectedColumns.value.includes(column)
    }

    function setColumnVisible (column, visible) {
      const updatedSelectedColumns = (visible) ? selectedColumns.value.filter(c => c !== column) : selectedColumns.value.concat([column])
      store.dispatch("creativesTable/setSelectedColumns", updatedSelectedColumns)
    }

    function openCreativeBriefDialog () {
      emit("openCreativeBrief")
    }

    function openDistributionErrorsDialog () {
      emit("openDistributionErrors")
    }

    return {
      tiles,
      allColumns,
      selectedColumns,
      numberOfFailedDistributions,
      numberOfFailedDistributionsString,
      isColumnVisible,
      setColumnVisible,
      openCreativeBriefDialog,
      openDistributionErrorsDialog,
    }
  },
}
</script>

<style lang="less" scoped>
@import (reference) '~design-system-variables/index';

.creative-brief-bar {
  display: flex;
  justify-content: space-between;
  height: 96px;
  width: calc(100vw - 230px);
  padding: 16px 16px 16px 24px;
  background: @ink;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;

  &__tiles {
    display: flex;
    max-width: calc(100% - 106px);
    overflow-x: scroll;
    overflow-y: hidden;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__buttons {
    margin-left: 12px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    width: 300px;
  }

  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    align-self: end;
    width: 102px;
    padding: 4px 12px;
    border: 1px solid @graphite;
    box-sizing: border-box;
    border-radius: 4px;
    font-weight: 500;
    font-size: 11px;
    line-height: 16px;
    text-align: center;
    color: white;

    &:hover {
      border-color: white;
    }

    &--wider {
      width: 150px;
      margin-right: 8px;
    }

    &--bigger {
      padding: 6px 12px;
    }
  }

  &__tile {
    min-width: 90px;
    width: 120px;
    height: 64px;
    padding: 8px 16px;
    margin-right: 16px;
    border-radius: 8px;
    background-color: @slate;
    color: @dust;
    font-size: 12px;
    line-height: 16px;
    overflow: hidden;
  }

  &__tile-label {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__tile-value {
    color: @silver;
    font-size: 26px;
    line-height: 32px;
    font-weight: 700;
  }

  &__tooltip-title {
    padding: 4px 4px 0 4px;
    margin-bottom: 8px;
    font-weight: 500;
    font-size: 11px;
    line-height: 16px;
    color: @smog;
  }

  &__tooltip-values {
    padding:0 4px;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: @charcoal;

    &:last-of-type {
      padding-bottom: 4px;
    }
  }

  &__item {
    padding-right: 42px;
    display: flex;
    align-items: center;
    color: @charcoal;

    &:hover {
      .creative-brief-bar__eye-icon {
        opacity: 1;
      }
    }
  }

  &__eye-icon {
    position: absolute;
    right: 8px;
    cursor: pointer;

    &--ghost {
      opacity: 0;
      transition: opacity @transition-15 ease;
    }
  }

  &__warning {
    color: @warning-yellow,
  }
}

.btn--default.btn--dark.ant-btn {
  border: transparent;
  width: 20px;
  height: 20px;
  margin-left: 8px;
  margin-bottom: 4px;
}

::v-deep(.columns-dropdown-item.columns-dropdown-item) {
  cursor: default;
}

</style>
