<template>
  <div class="table-node">
    <div class="table-node__name" :style="{ 'width': computedWidth }">
      <a-tag v-if="useChips" track-name="table-node-tag">
        <c-ellipsis :text="nameRef" :style="{ 'max-width': `calc(${computedWidth} - 32px)` }" />
      </a-tag>
      <template v-else>{{ nameRef }}</template>
    </div>
    <div class="table-node__children">
      <table-node
        class="table-node__child"
        v-for="child in computedChildren"
        :key="child.name"
        :name="child.name"
        :nestingLevel="nestingLevelRef + 1"
        :children="child.children"
        :width="computedWidth"
      />
    </div>
  </div>
</template>

<script>
import { ref, computed } from "vue"
import { standardAttributes } from "@store/modules/creative_brief/store_constants.js"

export default {
  name: "TableNode",
  props: {
    name: { type: String, required: true },
    children: { type: Object, required: true },
    width: { type: String, required: true },
    nestingLevel: { type: Number, required: true },
  },
  setup (props) {
    const nameRef = ref(props.name)
    const nestingLevelRef = ref(props.nestingLevel)
    // standard attributes are not displayed in chips, others are
    const useChips = computed(() => nestingLevelRef.value > standardAttributes.length - 1)
    const computedWidth = computed(() => props.width)
    const computedChildren = computed(() => {
      const mappedChildren = []
      for (const [name, items] of Object.entries(props.children)) {
        mappedChildren.push({
          name: name,
          children: items,
        })
      }

      return mappedChildren
    })

    return {
      nameRef,
      nestingLevelRef,
      useChips,
      computedWidth,
      computedChildren,
    }
  },
}
</script>

<style lang="less" scoped>
@import (reference) "~design-system-variables/index";

.table-node {
  display: flex;
}

.table-node__name {
  padding: 8px;
  border: 1px solid @silver;
  color: @void;
  font-size: 12px;
  line-height: 16px;
}

.table-node__name,
.table-node__children {
  flex-shrink: 0;
}
</style>
