import { isString, isObject } from "lodash-es"

export function extractErrorMessageFromError (error) {
  if (isString(error)) {
    return error
  }

  if (isObject(error) && error.response && error.response.data && error.response.data.message) {
    return error.response.data.message
  }

  if (isObject(error) && error.message) {
    return error.message
  }

  return "An unknown error occurred, please retry later."
}

export function getLocalStorageItem (key) {
  const localStorageItem = window.localStorage.getItem(key)
  return localStorageItem === "" ? null : localStorageItem
}

export function setLocalStorageItem (key, value) {
  if (value == null || value === "") {
    window.localStorage.removeItem(key)
    return
  }
  window.localStorage.setItem(key, value)
}

export function dateToLocaleString (date, locale, timezone) {
  if (date === null) {
    return ""
  }

  const dateObject = new Date(Date.parse(date.replace(/ /g, "T")))
  return dateObject.toLocaleString(locale, { timezone: timezone, year: "numeric", month: "numeric", day: "numeric", hour: "numeric", minute: "2-digit" })
}

export function humanizeFileSize (size) {
  const sizeNumber = parseInt(size)
  if (isNaN(sizeNumber)) {
    return size
  }

  const sizeUnits = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
  const i = Math.floor(Math.log(sizeNumber) / Math.log(1024))
  const formattedSize = (sizeNumber / Math.pow(1024, i)).toFixed(2)
  return `${formattedSize} ${sizeUnits[i]}`
}
