<template>
  <div class="flashtalking-distribution-step__destination">
    <div class="flashtalking-distribution-step__destination-loading" v-if="isFlashtalkingDataLoading">
      <a-spin class="flashtalking-distribution-step__destination-spinner" tip="Loading Flashtalking assets" size="large" />
    </div>

    <template v-else>
      <distribution-autocomplete
        placeholder="Select business entity"
        name="Flashtalking Business Entity"
        :options="flashtalkingBusinessEntityOptions ?? []"
        @change="selectFlashtalkingBusinessEntity"
      />
      <distribution-autocomplete
        placeholder="Select advertiser"
        name="Flashtalking Advertiser"
        :options="flashtalkingAdvertiserOptions ?? []"
        :is-loading="areFlashtalkingAdvertiserOptionsLoading"
        :is-disabled="isFlashtalkingAdvertiserSelectDisabled"
        @change="selectFlashtalkingAdvertiser"
      />
      <distribution-autocomplete
        placeholder="Select creative library"
        name="Flashtalking Creative Library"
        :options="flashtalkingCreativeLibraryOptions ?? []"
        :is-loading="areFlashtalkingCreativeLibraryOptionsLoading"
        :is-disabled="isFlashtalkingCreativeLibrarySelectDisabled"
        :can-add-new="true"
        @change="selectFlashtalkingCreativeLibrary"
        @addNew="addNewFlashtalkingCreativeLibrary"
      />
    </template>
  </div>
</template>

<script>
import { computed } from "vue"
import { useStore } from "vuex"
import DistributionAutocomplete from "./DistributionAutocomplete"

export default {
  components: {
    DistributionAutocomplete,
  },
  setup() {
    const store = useStore()

    const isFlashtalkingDataLoading = computed(() => store.state.distributionDialog.flashtalking.isFlashtalkingDataLoading)

    const flashtalkingBusinessEntityOptions = computed(() => store.state.distributionDialog.flashtalking.flashtalkingBusinessEntityOptions)
    function selectFlashtalkingBusinessEntity (value) {
      store.dispatch("distributionDialog/flashtalking/selectFlashtalkingBusinessEntity", value)
    }
    const flashtalkingAdvertiserOptions = computed(() => store.state.distributionDialog.flashtalking.flashtalkingAdvertiserOptions)
    const areFlashtalkingAdvertiserOptionsLoading = computed(() => store.state.distributionDialog.flashtalking.flashtalkingBusinessEntityId != null && flashtalkingAdvertiserOptions.value == null)
    function selectFlashtalkingAdvertiser (flashtalkingAdvertiser) {
      store.dispatch("distributionDialog/flashtalking/selectFlashtalkingAdvertiser", flashtalkingAdvertiser)
    }
    const flashtalkingCreativeLibraryOptions = computed(() => store.state.distributionDialog.flashtalking.flashtalkingCreativeLibraryOptions)
    const areFlashtalkingCreativeLibraryOptionsLoading = computed(() => store.state.distributionDialog.flashtalking.flashtalkingAdvertiserId != null && flashtalkingCreativeLibraryOptions.value == null)
    function selectFlashtalkingCreativeLibrary (flashtalkingCreativeLibrary) {
      store.dispatch("distributionDialog/flashtalking/selectFlashtalkingCreativeLibrary", flashtalkingCreativeLibrary)
    }
    function addNewFlashtalkingCreativeLibrary (newFlashtalkingCreativeLibrary) {
      store.dispatch("distributionDialog/flashtalking/addNewFlashtalkingCreativeLibrary", newFlashtalkingCreativeLibrary)
    }

    const isFlashtalkingAdvertiserSelectDisabled = computed(() => store.state.distributionDialog.flashtalking.flashtalkingBusinessEntityId == null || flashtalkingAdvertiserOptions.value == null)
    const isFlashtalkingCreativeLibrarySelectDisabled = computed(() => isFlashtalkingAdvertiserSelectDisabled.value || store.state.distributionDialog.flashtalking.flashtalkingAdvertiserId == null || flashtalkingCreativeLibraryOptions.value == null)

    return {
      isFlashtalkingDataLoading,
      flashtalkingBusinessEntityOptions,
      flashtalkingAdvertiserOptions,
      flashtalkingCreativeLibraryOptions,
      areFlashtalkingAdvertiserOptionsLoading,
      areFlashtalkingCreativeLibraryOptionsLoading,
      selectFlashtalkingBusinessEntity,
      selectFlashtalkingAdvertiser,
      selectFlashtalkingCreativeLibrary,
      addNewFlashtalkingCreativeLibrary,
      isFlashtalkingAdvertiserSelectDisabled,
      isFlashtalkingCreativeLibrarySelectDisabled,
    }
  },
}
</script>

<style lang="less" scoped>
@import (reference) '~design-system-variables/index';

.flashtalking-distribution-step__destination-loading {
  display: flex;
  justify-content: center;
}

</style>
