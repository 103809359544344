<template>
  <div class="container">
    <div>
      <div class="error-icon-row">
        <c-icon name="error-bold" />
      </div>
      <div class="error-message error-message__main">Too many creatives selected</div>
      <div class="error-message error-message__sub">Go back to the Creatives dashboard to select fewer creatives.</div>
      <div class="contact-support-row">
        <a-button
            type="primary"
            target="_blank"
            track-name="learn-more-button"
            href="https://support.celtra.io/account/integrations-and-connectors/distribute-with-celtras-distribution-portal#ShareCreativesForDistribution"
>
          Learn more
        </a-button>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
@import (reference) '~design-system-variables/index';
.container {
  height: calc(100vh - 48px - 40px - 96px - 2px);
  width: calc(100vw - 210px);
  background: @ink;
  display: flex;
  justify-content: center;
  align-items: center;
}

.error-icon-row {
  color: @danger-red;
  margin-bottom: 15px;
  text-align: center;
}

.contact-support-row {
  text-align: center;
  margin-top: 20px;
}

.error-message {
  font-size: 14px;
  text-align: center;

  &__main {
    margin-bottom: 7px;
  }

  &__sub {
    max-width: 300px;
    color: @smog;
    margin-top: 3px;

  }
}
</style>
