import axios from "axios"
import { orderBy } from "lodash-es"
import { formatsDistributableToDcm, accessTokenExpirationDuration } from "../shared/constants.js"
import { getLocalStorageItem, setLocalStorageItem } from "@store/utils.js"

const mapDCMAccounts = (accounts) => {
  return accounts.map(account => {
    return {
      id: account.accountId,
      value: account.accountId,
      label: account.accountName,
      metadata: account.accountId,
    }
  })
}

const mapDCMAdvertisers = (advertisers) => {
  return advertisers.map(advertiser => {
    return {
      id: advertiser.id,
      value: advertiser.id,
      label: advertiser.name,
      metadata: advertiser.id,
    }
  })
}

const state = {
  isOAuthDone: false,
  isDcmDataLoading: false,
  dcmAccountOptions: null,
  dcmAdvertiserOptions: null,
  dcmAccountId: null,
  dcmAdvertiserId: null,
}

const getState = () => JSON.parse(JSON.stringify(state))

export default {
  namespaced: true,
  state: getState(),
  getters: {
    creativesForExport(state, _, rootState) {
      return rootState.creatives.filter(c => rootState.distributionDialog.creativeIds.includes(c.id))
    },
    formatsDistributableToDcmString() {
      return `Supported creatives: ${formatsDistributableToDcm.join(",")}`
    },
    areIncompatibleCreativesSelected(_, getters) {
      return getters.creativesForExport.filter(c => !formatsDistributableToDcm.includes(c.clazz)).length > 0
    },
    isDialogValid (state) {
      return state.dcmAccountId != null && state.dcmAdvertiserId != null
    },
    postData(state, getters, rootState, rootGetters) {
      const postData = {
        creativeIds: getters.creativesForExport.map(c => c.id),
        endpoint: "DCM",
        dcmAccountId: state.dcmAccountId,
        dcmAdvertiserId: state.dcmAdvertiserId,
        caller: "prototype",
        isUpdatable: false,
        accessToken: "dcmDistributionAccessToken",
        distributionPrototypeUserId: getLocalStorageItem(rootGetters.accountUserIdLocalStorageKey),
      }

      return postData
    },
    tokenExpirationLocalStorageKey (state, getters, rootState) {
      return `${rootState.distributionPrototypeAccessKeyId}:dcmTokenExpirationTimestamp`
    },
  },
  actions: {
    initialize: ({ commit, dispatch, state, getters }) => {
      if (state.isOAuthDone) {
        return
      }

      const accessTokenExpirationTimestamp = getLocalStorageItem(getters.tokenExpirationLocalStorageKey)
      if (accessTokenExpirationTimestamp === null || Date.now() > parseInt(atob(accessTokenExpirationTimestamp))) {
        return
      }

      commit("SET_IS_OAUTH_DONE", true)
      commit("SET_IS_DCM_DATA_LOADING", true)

      return dispatch("loadDcmAssets")
        .catch(error => {
          if (error.response.status === 503) {
            console.error(error)
            setLocalStorageItem(getters.tokenExpirationLocalStorageKey, null)
            commit("SET_IS_OAUTH_DONE", false)
          }
        })
        .finally(() => commit("SET_IS_DCM_DATA_LOADING", false))
    },
    openOAuthDialog: () => {
      window.open(`${axios.defaults.baseURL}creativeDistributionAuthorization/dcm?state=${window.location.origin}`, "oauth", "width=1024,height=800")
    },
    handleCodeMessage: ({ commit, dispatch, getters }, ev) => {
      if (!ev.data.code) {
        return
      }

      const postData = {
        provider: "dcm",
        accessTokenType: "DistributionAccessToken",
        code: ev.data.code,
      }

      commit("SET_IS_DCM_DATA_LOADING", true)
      return axios.post("externalAccessTokens", postData)
        .then(() => {
          setLocalStorageItem(getters.tokenExpirationLocalStorageKey, btoa((Date.now() + accessTokenExpirationDuration).toString()))
          commit("SET_IS_OAUTH_DONE", true)
          return dispatch("loadDcmAssets")
        })
        .catch(error => {
          console.error(error)
          dispatch("setErrorMessage", error, { root: true })
        })
        .finally(() => commit("SET_IS_DCM_DATA_LOADING", false))
    },
    loadDcmAssets ({ dispatch }) {
      return dispatch("loadDcmAccountOptions")
    },
    loadDcmAccountOptions: async ({ commit, state }) => {
      if (!state.isOAuthDone) {
        throw new Error("User is not authorized, cannot load Campaign Manager 360 data!")
      }

      commit("SET_DCM_ACCOUNT_OPTIONS", null)
      commit("SET_DCM_ADVERTISER_OPTIONS", null)

      const accountsResponse = await axios.get("dcm/userprofiles?accessToken=dcmDistributionAccessToken")
      const accounts = mapDCMAccounts(accountsResponse.data)
      commit("SET_DCM_ACCOUNT_OPTIONS", orderBy(accounts, ["label"]))
    },
    loadDcmAdvertiserOptions: async ({ commit, state }, search) => {
      if (!state.dcmAccountId) {
        return
      }

      let advertisersUrl = `dcm/advertisers?dcmAccountId=${state.dcmAccountId}&accessToken=dcmDistributionAccessToken&perPage=200`
      if (search) {
        advertisersUrl += `&idOrName.like=${search}`
      }

      const advertisersResponse = await axios.get(advertisersUrl)
      const advertisers = mapDCMAdvertisers(advertisersResponse.data)
      commit("SET_DCM_ADVERTISER_OPTIONS", orderBy(advertisers, ["label"]))
    },
    selectDcmAccount({ commit, dispatch }, dcmAccount) {
      commit("SET_DCM_ACCOUNT", dcmAccount)
      commit("SET_DCM_ADVERTISER", null)
      return dispatch("loadDcmAdvertiserOptions")
    },
    selectDcmAdvertiser({ commit }, dcmAdvertiser) {
      commit("SET_DCM_ADVERTISER", dcmAdvertiser)
    },
    distributeCreatives ({ dispatch, getters }) {
      if (getters.areIncompatibleCreativesSelected) {
        return
      }

      return axios
        .post("creativeDistributionJobsRequest", getters.postData)
        .then(response => dispatch("addDistributions", response.data.creativeDistributionIds))
    },
    addDistributions ({ dispatch, getters }, distributionIds) {
      const newDistributions = []
      const exportedCreatives = getters.creativesForExport
      for (const index in distributionIds) {
        const nowISO = new Date().toISOString().split(".")[0].replace("T", " ")
        const exportedCreative = exportedCreatives[index]
        newDistributions.push({
          id: distributionIds[index],
          creativeId: exportedCreative.id,
          builderCreativeVersion: exportedCreative.builderCreativeVersion,
          creationTimestamp: nowISO,
          status: "IN_PROGRESS",
          errorMessage: null,
          dcmCreativeId: null,
        })
      }
      dispatch("creativesTable/addDistributions", newDistributions, { root: true })
    },
    resetState({ commit }) {
      commit("RESET_STATE")
    },
  },
  mutations: {
    SET_IS_OAUTH_DONE(state, isOAuthDone) {
      state.isOAuthDone = isOAuthDone
    },
    SET_IS_DCM_DATA_LOADING(state, isDcmDataLoading) {
      state.isDcmDataLoading = isDcmDataLoading
    },
    SET_DCM_ACCOUNT_OPTIONS (state, dcmAccountOptions) {
      state.dcmAccountOptions = dcmAccountOptions
    },
    SET_DCM_ADVERTISER_OPTIONS (state, dcmAdvertiserOptions) {
      state.dcmAdvertiserOptions = dcmAdvertiserOptions
    },
    SET_DCM_ACCOUNT(state, dcmAccountId) {
      state.dcmAccountId = dcmAccountId
      state.dcmAdvertiserId = null
    },
    SET_DCM_ADVERTISER(state, dcmAdvertiserId) {
      state.dcmAdvertiserId = dcmAdvertiserId
    },
    RESET_STATE(state) {
      Object.assign(state, getState())
    },
  },
}
