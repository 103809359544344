import axios from "axios"
import { uniq, intersection, orderBy, mapValues, cloneDeep } from "lodash-es"
import entityNotificationsClient from "@/DashboardEntityNotificationsClient.js"
import EntityEventListeners from "@/EntityEventListeners.js"
import { extractErrorMessageFromError, getLocalStorageItem, setLocalStorageItem } from "@store/utils"
import { initialAttributeGroups, attributeTypes, initialSortByOptions, supportedDistributions, supportedDistributionAllocations, errorMessageSolutionIdentification, numberOfCreativesPerPage } from "./constants.js"
import { formatsDistributableToFacebook, formatsDistributableToDcm, formatsDistributableToFlashtalking } from "@store/modules/distribution_dialog/shared/constants.js"

const POLLING_INTERVAL = 15e3 // 15s

const distributionFields = [
  "id",
  "creativeId",
  "status",
  "errorMessage",
  "facebookAdId",
  "dcmCreativeId",
  "flashtalkingCreativeId",
  "creationTimestamp",
  "builderCreativeVersion",
  "endpoint",
  "facebookAdAccountId",
  "facebookAdSetId",
  "dcmAdvertiserId",
  "flashtalkingCreativeLibraryId",
]

const state = {
  distributions: [],
  searchFiltersText: "",
  selectedRowKeysArray: [],
  attributeGroups: [],
  checkedAttributesObject: {},
  entityEventListeners: [],
  selectedColumns: [],
  sortBy: null,
  sortByOrder: "desc",
  failedDistributionsToNotify: [],
  showFailedDistributionsNotification: false,
  creativeVariantWeights: [],
  fetchCreativeWeightsTimeout: null,
  currentPage: 1,
}

const getState = () => JSON.parse(JSON.stringify(state))

export default {
  namespaced: true,
  state: getState(),
  getters: {
    creatives (state, _, rootState) {
      const rootStateCreatives = cloneDeep(rootState.creatives)
      return rootStateCreatives.map(creative => {
        const creativeDistributionsFiltered = state.distributions.filter(distribution => distribution.creativeId === creative.id && distribution.builderCreativeVersion === creative.builderCreativeVersion)
        const latestDistributions = creativeDistributionsFiltered.sort((a, b) => {
          if (a.creationTimestamp > b.creationTimestamp) {
            return -1
          }
          if (b.creationTimestamp > a.creationTimestamp) {
            return 1
          }
          return 0
        })
        const latestDistribution = (latestDistributions.length > 0) ? latestDistributions[0] : { "status": "" }
        creative.distribution = latestDistribution
        return creative
      })
    },
    failedDistributionsGroupedByMessage (_, getters) {
      return getters.creatives.reduce((failedDistributions, creative) => {
        if (creative.distribution.status === "ERROR") {
          const existingFailedDistribution = failedDistributions.find(o => o.displayMessage === creative.distribution.errorMessage)
          if (existingFailedDistribution) {
            existingFailedDistribution.distributions.push({
              name: creative.name,
              ...creative.distribution,
            })
          } else {
            const solutionIdentifierAndDisplayMessageObject = solutionIdentifierAndDisplayMessage(creative.distribution.errorMessage)
            failedDistributions.push({
              endpoint: creative.distribution.endpoint,
              distributions: [{
                name: creative.name,
                ...creative.distribution,
              }],
              ...solutionIdentifierAndDisplayMessageObject,
            })
          }
        }
        return failedDistributions
      }, [])

      function solutionIdentifierAndDisplayMessage (errorMessage) {
        for (const [errorMatch, errorSolution] of Object.entries(errorMessageSolutionIdentification)) {
          if (errorMessage.match(errorMatch) !== null) {
            return errorSolution
          }
        }
        return {
          identifier: "default",
          displayMessage: errorMessage,
        }
      }
    },
    numberOfFailedDistributions (_, getters) {
      return getters.failedDistributionsGroupedByMessage.reduce((failedDistributionsSum, failedDistributions) => failedDistributionsSum + failedDistributions.distributions.length, 0)
    },
    areAnyDistributionsInProgress (_, getters) {
      return getters.creatives.some(creative => creative.distribution.status === "IN_PROGRESS")
    },
    attributeLabels (state) {
      return state.attributeGroups.map(attributeGroup => attributeGroup.value).slice(2)
    },
    filteredCreativeIds (state, getters) {
      if (!getters.anyFiltersChecked) {
        return getters.creatives.map(c => c.id)
      }

      let allCreativeIds = []
      let attributeGroupsCreativeIds = []
      for (const attributeGroup of state.attributeGroups) {
        let attributeGroupCreativeIds = []
        if (state.checkedAttributesObject[attributeGroup.value] && Object.values(state.checkedAttributesObject[attributeGroup.value]).some(c => c)) {
          for (let option of attributeGroup.options) {
            if (state.checkedAttributesObject[attributeGroup.value][option.value]) {
              attributeGroupCreativeIds.push(...option.creativeIds)
              allCreativeIds.push(...option.creativeIds)
            }
          }
          attributeGroupsCreativeIds.push(attributeGroupCreativeIds)
        }
      }
      if (attributeGroupsCreativeIds.length === 0) {
        return uniq(allCreativeIds)
      }
      return intersection(...attributeGroupsCreativeIds)
    },
    dataset (state, getters) {
      if (state.isLoading) {
        return []
      }

      return getters.creatives.reduce((augmentedCreatives, creative) => {
        if (!getters.anyFiltersChecked || getters.filteredCreativeIds.includes(creative.id)) {
          let attributes = setAttributesObject(creative)
          const creativeObj = {
            id: creative.id,
            name: creative.name,
            distribution: creative.distribution,
            thumbnailUrl: creative.thumbnailUrl,
            attributes: attributes,
            ...getSortingAttributeValues(attributes, creative.distribution),
          }
          augmentedCreatives.push(creativeObj)
        }

        return augmentedCreatives
      }, [])

      function setAttributesObject (creative) {
        let attributes = []
        attributeTypes.forEach(attributeType => {
          for (let [key, value] of Object.entries(creative[attributeType])) {
            if (attributeType !== "mediaAttributes") {
              key = value.label
              value = value.value
            } else {
              key = key.charAt(0).toUpperCase() + key.slice(1)
            }
            attributes.push({
              "label": key,
              "value": value,
            })
          }
        })
        return attributes
      }

      function getSortingAttributeValues (attributes, distribution) {
        let orderByAttributes = {}
        for (const attribute of Object.values(attributes)) {
          let orderByValue = attribute.value
          if (attribute.label === "Size") {
            let [width, height] = orderByValue.split("x")
            orderByValue = parseFloat(`${width}.${height}`)
          }
          orderByAttributes[`orderBy${attribute.label}`] = orderByValue
        }
        return {
          orderByDistribution: getDistributionValue(distribution),
          ...orderByAttributes,
        }
      }

      function getDistributionValue (distribution) {
        if (["IN_PROGRESS", "ERROR"].includes(distribution.status)) {
          return (distribution.status === "IN_PROGRESS") ? 0 : 1
        }
        return getters.getCreativeDistributionId(distribution) != null ? parseInt(getters.getCreativeDistributionId(distribution)) : Infinity
      }
    },
    sortedDataset (state, getters) {
      if (state.sortBy == null) {
        return getters.dataset
      }
      return orderBy(getters.dataset, [state.sortBy], [state.sortByOrder])
    },
    attributeGroupsSelected (state) {
      return state.attributeGroups.filter(g => state.selectedColumns.includes(g.value) || g.value === "Distribution channel")
    },
    attributeGroupsFiltered (state, getters) {
      if (state.searchFiltersText === "") {
        return getters.attributeGroupsSelected
      }
      return getters.attributeGroupsSelected.reduce((groups, attribute) => {
        const attributesFiltered = attribute.options.filter(option => option.value.toLowerCase().includes(state.searchFiltersText.toLowerCase()))
        if (attributesFiltered.length > 0) {
          groups.push({
            ...attribute,
            options: attributesFiltered,
          })
        }
        return groups
      }, [])
    },
    openSubMenus (_, getters) {
      return getters.attributeGroupsFiltered.map(attributeGroup => attributeGroup.value)
    },
    anyFiltersChecked (state) {
      return Object.values(state.checkedAttributesObject).some(checkedAttributeObject => Object.values(checkedAttributeObject).some(checked => checked))
    },
    allColumns (_, getters) {
      return ["Name", "Creative Id"].concat(getters.attributeLabels).concat(["Creative Weight", "Distribution Id", "Distributed"])
    },
    defaultColumns (_, getters) {
      return ["Name", "Creative Id"].concat(getters.attributeLabels.slice(0, 3)).concat(["Creative Weight", "Distribution Id", "Distributed"])
    },
    creativesText (state, getters, rootState) {
      if (!rootState.creatives.length) {
        return ""
      }
      let text = `${rootState.creatives.length} creative variants`
      if (getters.selectedFilteredRowKeys.length !== 0) {
        text = text.concat(` (${getters.selectedFilteredRowKeys.length} selected)`)
      }
      if (getters.anyFiltersChecked) {
        text = `${getters.filteredCreativeIds.length} out of `.concat(text)
      }
      return text
    },
    selectedFilteredRowKeys (state, getters) {
      if (!getters.anyFiltersChecked) {
        return state.selectedRowKeysArray
      }
      return state.selectedRowKeysArray.filter(id => getters.filteredCreativeIds.includes(id))
    },
    sortByOptions (state, getters) {
      const initialOptions = initialSortByOptions.filter((o => state.selectedColumns.includes(o.id)))
      const attributeLabelsSortByOptions = getters.attributeLabels.reduce((options, attribute) => {
        if (state.selectedColumns.includes(attribute)) {
          options.push({
            id: attribute,
            label: attribute,
            value: `orderBy${attribute}`,
          })
        }
        return options
      }, [])

      return [
        ...initialOptions,
        ...attributeLabelsSortByOptions,
      ]
    },
    checkedAttributesLocalStorageKey (state, getters, rootState) {
      return `${rootState.distributionPrototypeAccessKeyId}:checkedAttributes`
    },
    sortByLocalStorageKey (state, getters, rootState) {
      return `${rootState.distributionPrototypeAccessKeyId}:sortBy`
    },
    sortByOrderLocalStorageKey (state, getters, rootState) {
      return `${rootState.distributionPrototypeAccessKeyId}:sortByOrder`
    },
    selectedColumnsLocalStorageKey (state, getters, rootState) {
      return `${rootState.distributionPrototypeAccessKeyId}:selectedColumns`
    },
    selectedRowKeysLocalStorageKey (state, getters, rootState) {
      return `${rootState.distributionPrototypeAccessKeyId}:selectedRowKeys`
    },
    getCreativeDistributionId () {
      return getCreativeDistributionId

      function getCreativeDistributionId (distribution) {
        for (const supportedDistribution of supportedDistributions) {
          const supportedDistributionLowercase = supportedDistribution.toLowerCase()
          if (`${supportedDistributionLowercase}CreativeId` in distribution) {
            return distribution[`${supportedDistributionLowercase}CreativeId`]
          } else if ("facebookAdId" in distribution) {
            return distribution.facebookAdId
          }
        }
        return null
      }
    },
    creativeWeights (state) {
      return mapValues(creativeIdToCreativeVariantWeights(state.creativeVariantWeights), creativeVariantWeights => {
        return creativeVariantWeights.includes(null) ? null : {
          weight: creativeVariantWeights.reduce((sum, weight) => sum + weight, 0),
          numOfVariants: creativeVariantWeights.length,
        }
      })

      function creativeIdToCreativeVariantWeights(creativeVariantWeights) {
        return creativeVariantWeights.reduce((creativeWeights, creativeVariant) => {
          if (creativeVariant.creativeId in creativeWeights) {
            creativeWeights[creativeVariant.creativeId].push(creativeVariant.weight)
          } else {
            creativeWeights[creativeVariant.creativeId] = [creativeVariant.weight]
          }
          return creativeWeights
        }, {})
      }
    },
    currentlyDisplayedCreativeIds (state, getters) {
      const creativeDataset = getters.sortedDataset
      const startIndex = (state.currentPage - 1) * numberOfCreativesPerPage;
      return creativeDataset
        .slice(startIndex, startIndex + numberOfCreativesPerPage)
        .map(currentlyDisplayedCreative => currentlyDisplayedCreative.id)
    },
  },
  actions: {
    initialize ({ commit, dispatch }) {
      dispatch("initializeEntityEventListeners")

      return dispatch("loadDistributions")
        .then((distributions) => {
          commit("SET_DISTRIBUTIONS", distributions)

          dispatch("setAttributeGroups")
          dispatch("setInitialCheckedAttributes")
          dispatch("setLocalStorageItems")
        })
        .catch((error) => {
          commit("SET_ERROR_MESSAGE", error)
          throw error
        })
    },
    async loadDistributions ({ rootState }) {
      const distributionsUrl = `creativeDistributions?rootFolderId=${rootState.campaignId}&isRemoved=0&endpoint.in=${supportedDistributions.join(",")}`
      const distributionAllocationsUrl = `creativeDistributionAllocations?rootFolderId=${rootState.campaignId}&isRemoved=0&endpoint.in=${supportedDistributionAllocations.join(",")}`
      let distributionsResponse = null
      let distributionAllocationsResponse = null
      try {
        const promises = [
          axios.get(`${distributionsUrl}&page=1&perPage=1&fields=id`),
          axios.get(`${distributionAllocationsUrl}&page=1&perPage=1&fields=id`),
        ];

        [distributionsResponse, distributionAllocationsResponse] = await Promise.all(promises)
      } catch (error) {
        console.error(`Unexpected error occured while retrieving distributions. Distributed creatives will not appear in the Distribution column, however distributing creatives will still work. Error message: '${extractErrorMessageFromError(error)}'`)
        return []
      }

      const distributionsCount = distributionsResponse.headers["x-total-count"]
      const perPage = 250
      let numberOfPages = Math.ceil(distributionsCount / perPage)
      const promises = []
      for (let page = 1; page <= numberOfPages; page++) {
        const distributionsUrlForPage = `${distributionsUrl}&page=${page}&perPage=${perPage}&fields=${distributionFields.join(",")}`
        promises.push(axios.get(distributionsUrlForPage))
      }

      const distributionAllocationsCount = distributionAllocationsResponse.headers["x-total-count"]
      numberOfPages = Math.ceil(distributionAllocationsCount / perPage)
      for (let page = 1; page <= numberOfPages; page++) {
        const distributionAllocationsUrlForPage = `${distributionAllocationsUrl}&page=${page}&perPage=${perPage}&fields=${distributionFields.join(",")}`
        promises.push(axios.get(distributionAllocationsUrlForPage))
      }

      return Promise.all(promises)
        .then(responses => responses.flatMap(response => response.data))
        .catch(error => {
          console.error(`Unexpected error occured while retrieving distributions. Distributed creatives will not appear in the Distribution column, however distributing creatives will still work. Error message: '${extractErrorMessageFromError(error)}'`)
          return []
        })
    },
    initializeEntityEventListeners ({ commit, dispatch, rootState }) {
      if (entityNotificationsClient.connectionState === entityNotificationsClient.DISCONNECTED) {
        entityNotificationsClient.url = process.env.VUE_APP_UAB_WS_URL
        entityNotificationsClient.connect()
      }
      const entityEventListeners = new EntityEventListeners(entityNotificationsClient)
      commit("SET_ENTITY_EVENT_LISTENERS", entityEventListeners)
      entityEventListeners.addListener(
        {
          clazz: "CreativeDistribution",
          attributes: { rootFolderId: rootState.campaignId },
        },
        () => {},
        (ev) => dispatch("handleDistributionUpdate", [ev, "CreativeDistribution"]),
      )
      entityEventListeners.addListener(
        {
          clazz: "CreativeDistributionAllocation",
          attributes: { rootFolderId: rootState.campaignId },
        },
        () => {},
        (ev) => dispatch("handleDistributionUpdate", [ev, "CreativeDistributionAllocation"]),
      )
    },
    async handleDistributionUpdate ({ state, getters, commit }, [entityUpdateEvent, clazz]) {
      const eventData = entityUpdateEvent.data
      const creativeDistribution = state.distributions.find(distribution => distribution.id === eventData.attributes.id)

      if (creativeDistribution) {
        const creativeDistributionObject = await createCreativeDistributionObject()
        commit("UPDATE_CREATIVE_DISTRIBUTION", {
          id: eventData.attributes.id,
          creativeDistributionObject,
        })

        if (creativeDistributionObject.status === "ERROR") {
          commit("SET_FAILED_DISTRIBUTIONS_TO_NOTIFY", [
            eventData.attributes.id,
            ...state.failedDistributionsToNotify,
          ])
        }

        if (!getters.areAnyDistributionsInProgress && state.failedDistributionsToNotify.length > 0) {
          commit("SET_SHOW_FAILED_DISTRIBUTIONS_NOTIFICATION", true)
        }
      }

      async function createCreativeDistributionObject () {
        if (eventData.attributes.status === "IN_SYNC" || eventData.attributes.status === "ERROR") {
          const url = clazz === "CreativeDistribution" ? "creativeDistributions" : "creativeDistributionAllocations"
          const response = await axios.get(`${url}/${eventData.attributes.id}?isRemoved=0&fields=${distributionFields.join(",")}`)
          return response.data
        } else {
          return {
            status: eventData.attributes.status,
          }
        }
      }
    },
    async loadCreativeWeights ({ state, commit, dispatch, getters }) {
      if (state.fetchCreativeWeightsTimeout) {
        clearTimeout(state.fetchCreativeWeightsTimeout)
        commit("SET_FETCH_CREATIVE_VARIANT_WEIGHT_TIMEOUT", null)
      }

      const displayedCreativeIdsWithoutCreativeWeight = getters.currentlyDisplayedCreativeIds
        .filter(creativeId => !(creativeId in getters.creativeWeights) || getters.creativeWeights[creativeId] === null)

      if (displayedCreativeIdsWithoutCreativeWeight.length === 0) {
        return Promise.resolve()
      }

      await dispatch("loadCreativeUnitVariantWeights", displayedCreativeIdsWithoutCreativeWeight)

      if (displayedCreativeIdsWithoutCreativeWeight.length > 0) {
        const fetchCreativeWeightsTimeout = setTimeout(() => dispatch("loadCreativeWeights"), POLLING_INTERVAL)
        commit("SET_FETCH_CREATIVE_VARIANT_WEIGHT_TIMEOUT", fetchCreativeWeightsTimeout)
      }
    },
    async loadCreativeUnitVariantWeights ({ commit, rootState }, creativeIds) {
      const commaSeperatedCreativeIds = creativeIds.join(",")
      const creativeVariantsUrl = `creativeVariants?creativeId.in=${commaSeperatedCreativeIds}&accountId=${rootState.accountId}&fields=creativeId,localId,weight`

      const creativeVariantWeights = await axios.get(creativeVariantsUrl)
        .then(response => response.data)
        .catch(error => {
          console.error(`Unexpected error occured while retrieving creative unit variants. Error message: '${extractErrorMessageFromError(error)}'`)
          return []
        })
      commit("UPDATE_CREATIVE_VARIANT_WEIGHTS", creativeVariantWeights)
    },
    setAttributeGroups ({ commit, getters }) {
      const attributeGroups = getters.creatives.reduce((groups, creative) => {
        setDistributionFilterValues(groups, creative)
        setChannelFilterValues(groups, creative)

        attributeTypes.forEach(attributeType => {
          setDataForAttributes(groups, creative.id, creative[attributeType], attributeType)
        })
        return groups
      }, cloneDeep(initialAttributeGroups))

      commit("SET_ATTRIBUTE_GROUPS", attributeGroups)

      function setDistributionFilterValues(groups, creative) {
        const successullyDistributedFilterOption = groups[0].options[1]
        const unsuccessullyDistributedFilterOption = groups[0].options[0]
        if (["IN_PROGRESS", "IN_SYNC"].includes(creative.distribution.status)) {
          successullyDistributedFilterOption.creativeIds.push(creative.id)
        } else {
          unsuccessullyDistributedFilterOption.creativeIds.push(creative.id)
        }
      }

      function setChannelFilterValues(groups, creative) {
        const creativesValidForFacebook = groups[1].options[0]
        const creativesValidForDCM = groups[1].options[1]
        const creativesValidForFlashtalking = groups[1].options[2]

        if (formatsDistributableToFacebook.includes(creative.clazz)) {
          creativesValidForFacebook.creativeIds.push(creative.id)
        }

        if (formatsDistributableToDcm.includes(creative.clazz)) {
          creativesValidForDCM.creativeIds.push(creative.id)
        }

        if (formatsDistributableToFlashtalking.includes(creative.clazz)) {
          creativesValidForFlashtalking.creativeIds.push(creative.id)
        }
      }

      function setDataForAttributes(data, creativeId, attributes, attributeType) {
        for (let [key, value] of Object.entries(attributes)) {
          if (attributeType === "mediaAttributes") {
            key = key.charAt(0).toUpperCase() + key.slice(1)
          } else {
            key = value.label
            value = value.value
          }
          const keyMatchingObject = data.find(el => el.value === key)
          if (keyMatchingObject) {
            const valueMatchingObject = keyMatchingObject.options.find(el => el.value === value)
            if (valueMatchingObject) {
              if (!valueMatchingObject.creativeIds.includes(creativeId)) {
                valueMatchingObject.creativeIds.push(creativeId)
              }
            } else {
              keyMatchingObject.options.push({
                value: value,
                creativeIds: [creativeId],
              })
            }
          } else {
            data.push({
              id: key,
              value: key,
              options: [
                {
                  value: value,
                  creativeIds: [creativeId],
                },
              ],
            })
          }
        }
      }
    },
    setInitialCheckedAttributes({ commit, state, getters }) {
      let checkedAttributes = getLocalStorageItem(getters.checkedAttributesLocalStorageKey)
      if (checkedAttributes === null) {
        checkedAttributes = getInitialCheckedAttributes(state.attributeGroups)
      } else {
        try {
          checkedAttributes = JSON.parse(checkedAttributes)
          const initialCheckedAttributes = getInitialCheckedAttributes(state.attributeGroups)
          const additionalInitialAttributeKeys = Object.keys(initialCheckedAttributes).filter(a => !(a in checkedAttributes))
          const additionalCheckedAttributeKeys = Object.keys(checkedAttributes).filter(a => !(a in initialCheckedAttributes))

          for (const key of additionalInitialAttributeKeys) {
            checkedAttributes[key] = initialCheckedAttributes[key]
          }

          for (const key of additionalCheckedAttributeKeys) {
            delete checkedAttributes[key]
          }
        } catch (error) {
          console.error(error)
          checkedAttributes = getInitialCheckedAttributes(state.checkedAttributes)
        }
      }
      const checkedAttributesLocalStorageKey = getters.checkedAttributesLocalStorageKey
      commit("SET_CHECKED_ATTRIBUTES", { checkedAttributes, checkedAttributesLocalStorageKey })

      function getInitialCheckedAttributes (attributeGroups) {
        return attributeGroups.reduce((checkedAttributes, attributeGroup) => {
          checkedAttributes[attributeGroup.value] = attributeGroup.options.reduce((options, option) => {
            options[option.value] = false
            return options
          }, {})
          return checkedAttributes
        }, {})
      }
    },
    setLocalStorageItems ({ dispatch, getters }) {
      let selectedColumns = getters.defaultColumns
      let selectedColumnsLocalStorage = getLocalStorageItem(getters.selectedColumnsLocalStorageKey)
      if (selectedColumnsLocalStorage !== null) {
        selectedColumns = selectedColumnsLocalStorage.split(",")
      }
      dispatch("setSelectedColumns", selectedColumns)

      const sortByLocalStorage = getLocalStorageItem(getters.sortByLocalStorageKey)
      if (sortByLocalStorage !== null) {
        dispatch("setSortBy", sortByLocalStorage)
      }

      const sortByOrderLocalStorage = getLocalStorageItem(getters.sortByOrderLocalStorageKey)
      if (sortByOrderLocalStorage !== null) {
        dispatch("setSortByOrder", sortByOrderLocalStorage)
      }

      const selectedRowKeysLocalStorage = getLocalStorageItem(getters.selectedRowKeysLocalStorageKey)
      if (selectedRowKeysLocalStorage !== null) {
        dispatch("setSelectedRowKeys", selectedRowKeysLocalStorage.split(","))
      }
    },
    setSelectedRowKeys ({ commit, getters }, selectedRowKeys) {
      const selectedRowKeysLocalStorageKey = getters.selectedRowKeysLocalStorageKey
      commit("SET_SELECTED_ROW_KEYS", { selectedRowKeys, selectedRowKeysLocalStorageKey })
    },
    setSearchFilters ({ commit }, searchFilters) {
      commit("SET_SEARCH_FILTERS", searchFilters)
    },
    setCheckedAttributes ({ commit, state, getters }, [group, option, value]) {
      let checkedAttributesObject = state.checkedAttributesObject
      checkedAttributesObject[group][option] = value

      const checkedAttributesLocalStorageKey = getters.checkedAttributesLocalStorageKey
      commit("SET_CHECKED_ATTRIBUTES", { checkedAttributes: checkedAttributesObject, checkedAttributesLocalStorageKey })
    },
    setDistributions ({ commit }, distributions) {
      commit("SET_DISTRIBUTIONS", distributions)
    },
    addDistributions ({ commit }, distributions) {
      commit("ADD_DISTRIBUTIONS", distributions)
    },
    updateCreativeDistribution ({ commit, dispatch }, distribution) {
      commit("UPDATE_CREATIVE_DISTRIBUTION", distribution)
      dispatch("setAttributeGroups")
    },
    setSelectedColumns ({ commit, state, getters }, selectedColumns) {
      if (state.sortBy !== null && !selectedColumns.includes(state.sortBy.replace("orderBy", ""))) {
        commit("SET_SORT_BY", { sortBy: null, sortByLocalStorageKey: getters.sortByLocalStorageKey })
      }

      let checkedAttributesObject = JSON.parse(JSON.stringify(state.checkedAttributesObject))
      for (let group of Object.keys(checkedAttributesObject)) {
        if (!selectedColumns.includes(group)) {
          checkedAttributesObject[group] = mapValues(checkedAttributesObject[group], () => false)
        }
      }

      const checkedAttributesLocalStorageKey = getters.checkedAttributesLocalStorageKey
      commit("SET_CHECKED_ATTRIBUTES", { checkedAttributes: checkedAttributesObject, checkedAttributesLocalStorageKey })

      const selectedColumnsLocalStorageKey = getters.selectedColumnsLocalStorageKey
      commit("SET_SELECTED_COLUMNS", { selectedColumns, selectedColumnsLocalStorageKey })
    },
    setSortBy ({ commit, getters }, sortBy) {
      const sortByLocalStorageKey = getters.sortByLocalStorageKey
      commit("SET_SORT_BY", { sortBy, sortByLocalStorageKey })
    },
    setSortByOrder ({ commit, getters }, sortByOrder) {
      const sortByOrderLocalStorageKey = getters.sortByOrderLocalStorageKey
      commit("SET_SORT_BY_ORDER", { sortByOrder, sortByOrderLocalStorageKey })
    },
    resetFailedDistributionsNotification ({ commit }) {
      commit("SET_SHOW_FAILED_DISTRIBUTIONS_NOTIFICATION", false)
      commit("SET_FAILED_DISTRIBUTIONS_TO_NOTIFY", [])
    },
    setCurrentPage ({ commit }, currentPage) {
      commit("SET_CURRENT_PAGE", currentPage)
    },
  },
  mutations: {
    SET_DISTRIBUTIONS (state, distributions) {
      state.distributions = distributions
    },
    ADD_DISTRIBUTIONS (state, distributions) {
      state.distributions = state.distributions.concat(distributions)
    },
    SET_ATTRIBUTE_GROUPS (state, attributeGroups) {
      state.attributeGroups = attributeGroups
    },
    SET_SELECTED_ROW_KEYS (state, { selectedRowKeys, selectedRowKeysLocalStorageKey }) {
      state.selectedRowKeysArray = selectedRowKeys
      setLocalStorageItem(selectedRowKeysLocalStorageKey, selectedRowKeys)
    },
    SET_SEARCH_FILTERS (state, searchFilters) {
      state.searchFiltersText = searchFilters
    },
    SET_CHECKED_ATTRIBUTES (state, { checkedAttributes, checkedAttributesLocalStorageKey }) {
      state.checkedAttributesObject = checkedAttributes
      setLocalStorageItem(checkedAttributesLocalStorageKey, JSON.stringify(checkedAttributes))
    },
    UPDATE_CREATIVE_DISTRIBUTION (state, { id, creativeDistributionObject }) {
      const index = state.distributions.findIndex(distribution => distribution.id === id)
      if (index === -1) {
        return
      }

      const updatedCreativeDistribution = {
        ...state.distributions[index],
        ...creativeDistributionObject,
      }
      state.distributions[index] = updatedCreativeDistribution
    },
    SET_ENTITY_EVENT_LISTENERS (state, entityEventListeners) {
      state.entityEventListeners = entityEventListeners
    },
    SET_ERROR_MESSAGE (state, error) {
      state.errorMessage = extractErrorMessageFromError(error)
    },
    SET_IS_LOADING (state, isLoading) {
      state.isLoading = isLoading
    },
    SET_SELECTED_COLUMNS (state, { selectedColumns, selectedColumnsLocalStorageKey }) {
      state.selectedColumns = selectedColumns
      setLocalStorageItem(selectedColumnsLocalStorageKey, selectedColumns)
    },
    SET_SORT_BY (state, { sortBy, sortByLocalStorageKey }) {
      state.sortBy = sortBy
      setLocalStorageItem(sortByLocalStorageKey, sortBy)
    },
    SET_SORT_BY_ORDER (state, { sortByOrder, sortByOrderLocalStorageKey }) {
      state.sortByOrder = sortByOrder
      setLocalStorageItem(sortByOrderLocalStorageKey, sortByOrder)
    },
    SET_FAILED_DISTRIBUTIONS_TO_NOTIFY (state, failedDistributionsToNotify) {
      state.failedDistributionsToNotify = failedDistributionsToNotify
    },
    SET_SHOW_FAILED_DISTRIBUTIONS_NOTIFICATION (state, showFailedDistributionsNotification) {
      state.showFailedDistributionsNotification = showFailedDistributionsNotification
    },
    UPDATE_CREATIVE_VARIANT_WEIGHTS (state, creativeVariantWeights) {
      for (const creativeVariantWeight of creativeVariantWeights) {
        const existingVariant = state.creativeVariantWeights.find(variant => creativeVariantWeight.creativeId === variant.creativeId && creativeVariantWeight.localId === variant.localId)
        if (existingVariant) {
          existingVariant.weight = creativeVariantWeight.weight
        } else {
          state.creativeVariantWeights.push(creativeVariantWeight)
        }
      }
    },
    SET_FETCH_CREATIVE_VARIANT_WEIGHT_TIMEOUT (state, timeout) {
      state.fetchCreativeWeightsTimeout = timeout
    },
    SET_CURRENT_PAGE (state, currentPage) {
      state.currentPage = currentPage
    },
  },
}
