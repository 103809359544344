(function (namespace) {
    // to avoid connection spikes client reconnects within a window instead of on a fixed timeout
    const RECONNECT_TIMEOUT_MIN = 10000
    const RECONNECT_TIMEOUT_MAX = 20000
    const CONNECTION_STATES = {
        DISCONNECTED: 'DISCONNECTED',
        CONNECTING_INITIAL: 'CONNECTING_INITIAL',
        CONNECTING_WAITING: 'CONNECTING_WAITING',
        CONNECTING_RETRYING: 'CONNECTING_RETRYING',
        CONNECTED: 'CONNECTED',
    }
    const CLIENT_MESSAGE_TYPES = {
        SUBSCRIBE: 'SUBSCRIBE',
        UNSUBSCRIBE: 'UNSUBSCRIBE',
    }
    const SERVER_MESSAGE_TYPES = {
        SUBSCRIBED: 'SUBSCRIBED',
        UNSUBSCRIBED: 'UNSUBSCRIBED',
        ENTITY_EVENT: 'ENTITY_EVENT',
        ERROR: 'ERROR',
    }

    class EventEmitter {
        constructor () {
            this._listeners = {} // { eventName: [f1, f2, f3], ... }
        }

        addEventListener (name, callback) {
            if (!this._listeners[name]) {
                this._listeners[name] = []
            }

            this._listeners[name].push(callback)
        }

        removeEventListener (name, callback) {
            if (this._listeners[name]) {
                const index = this._listeners[name].findIndex(c => c === callback)
                this._listeners[name].splice(index, 1)
            }

            if (this._listeners[name].length === 0) {
                delete this._listeners[name]
            }
        }

        removeAllEventListeners () {
            this._listeners = {}
        }

        _emit (name, event) {
            const listeners = this._listeners[name]
            if (!listeners) {
                return
            }

            for (const callback of listeners) {
                callback(event)
            }
        }
    }

    const LISTENER_STATES = {
        NOT_CALLED_YET: 'NOT_CALLED_YET',
        CALLED_SUCCESS_IN_SYNC: 'CALLED_SUCCESS_IN_SYNC',
        CALLED_WILL_NOT_SYNC: 'CALLED_WILL_NOT_SYNC',
    }

    class SubscriptionListener {
        constructor (subscription, initialCallback, incrementalCallback) {
            this._subscription = subscription
            this._initialCallback = initialCallback
            this._incrementalCallback = incrementalCallback
            this._state = LISTENER_STATES.NOT_CALLED_YET

            this._onSubscriptionStateChange = this._sync.bind(this)
            this._subscription.addEventListener('stateChange', this._onSubscriptionStateChange)
            this._sync()
        }

        isEqual (initialCallback, incrementalCallback) {
            return initialCallback === this._initialCallback && incrementalCallback === this._incrementalCallback
        }

        incrementalCallback (event) {
            if (this._state !== LISTENER_STATES.CALLED_SUCCESS_IN_SYNC) {
                throw new Error('Initial callback must be invoked prior to incremental.')
            }
            this._incrementalCallback({ type: 'entityEvent', data: event })
        }

        destroy () {
            this._subscription.removeEventListener('stateChange', this._onSubscriptionStateChange)
        }

        _sync () {
            switch (this._subscription.state) {
            case this._subscription.SUBSCRIBED:
                switch (this._state) {
                case LISTENER_STATES.NOT_CALLED_YET:
                case LISTENER_STATES.CALLED_WILL_NOT_SYNC:
                    this._initialCallback()
                default:
                    this._state = LISTENER_STATES.CALLED_SUCCESS_IN_SYNC
                }
                break
            case this._subscription.NOT_SUBSCRIBED_AFTER_ATTEMPT:
                switch (this._state) {
                case LISTENER_STATES.NOT_CALLED_YET:
                    this._initialCallback()
                    this._state = LISTENER_STATES.CALLED_WILL_NOT_SYNC
                    break
                case LISTENER_STATES.CALLED_SUCCESS_IN_SYNC:
                    this._state = LISTENER_STATES.CALLED_WILL_NOT_SYNC
                    break
                }
                break
            }
        }
    }

    const SUBSCRIPTION_STATES = {
        NOT_SUBSCRIBED_NO_ATTEMPT: 'NOT_SUBSCRIBED_NO_ATTEMPT',
        NOT_SUBSCRIBED_AFTER_ATTEMPT: 'NOT_SUBSCRIBED_AFTER_ATTEMPT',
        SUBSCRIBING: 'SUBSCRIBING',
        SUBSCRIBED: 'SUBSCRIBED',
        UNSUBSCRIBING: 'UNSUBSCRIBING',
    }

    class Subscription extends EventEmitter {
        constructor (client, eventFilter) {
            super()
            this._client = client
            this._eventFilter = eventFilter
            this._state = SUBSCRIPTION_STATES.NOT_SUBSCRIBED_NO_ATTEMPT
            this._eventFilterListeners = []

            this._onConnectionStateChange = this._sync.bind(this)
            this._client.addEventListener('connectionStateChange', this._onConnectionStateChange)

            // Expose SUBSCRIPTION_STATES as public API.
            for (const key of Object.keys(SUBSCRIPTION_STATES)) {
                this[key] = SUBSCRIPTION_STATES[key]
            }
        }

        get state () {
            return this._state
        }

        get size () {
            return this._eventFilterListeners.length
        }

        addListener (initialCallback, incrementalCallback) {
            if (this.state === SUBSCRIPTION_STATES.UNSUBSCRIBING) {
                throw new Error('Subscription is already unsubscribing.')
            }

            this._eventFilterListeners.push(new SubscriptionListener(this, initialCallback, incrementalCallback))
            this._sync()
        }

        removeListener (initialCallback, incrementalCallback) {
            const listener = this._eventFilterListeners.find(listener => listener.isEqual(initialCallback, incrementalCallback))
            if (!listener) {
                throw new Error('Listener not found.')
            }

            const index = this._eventFilterListeners.findIndex(l => l === listener)
            listener.destroy()
            this._eventFilterListeners.splice(index, 1)
            this._sync()
        }

        handleSubscribed () {
            if (this.state !== SUBSCRIPTION_STATES.SUBSCRIBING) {
                throw new Error('Subscription is not is subscribing state.')
            }

            this._setState(SUBSCRIPTION_STATES.SUBSCRIBED)
        }

        handleEntityEvent (event) {
            if (this.state !== SUBSCRIPTION_STATES.SUBSCRIBED) {
                throw new Error('Subscription is not subscribed.')
            }

            for (const listener of this._eventFilterListeners) {
                listener.incrementalCallback(event)
            }
        }

        _sync () {
            switch (this._client.connectionState) {
            case this._client.CONNECTING_WAITING:
                switch (this.state) {
                case SUBSCRIPTION_STATES.NOT_SUBSCRIBED_NO_ATTEMPT:
                case SUBSCRIPTION_STATES.SUBSCRIBING:
                case SUBSCRIPTION_STATES.SUBSCRIBED:
                    this._setState(SUBSCRIPTION_STATES.NOT_SUBSCRIBED_AFTER_ATTEMPT)
                }
                break
            case this._client.CONNECTED:
                switch (this.state) {
                case SUBSCRIPTION_STATES.NOT_SUBSCRIBED_NO_ATTEMPT:
                case SUBSCRIPTION_STATES.NOT_SUBSCRIBED_AFTER_ATTEMPT:
                    if (this._eventFilterListeners.length) {
                        this._setState(SUBSCRIPTION_STATES.SUBSCRIBING)
                        this._emit('subscribe', this._eventFilter)
                    }
                    break
                case SUBSCRIPTION_STATES.SUBSCRIBED:
                    if (!this._eventFilterListeners.length) {
                        this._setState(SUBSCRIPTION_STATES.UNSUBSCRIBING)
                        this._client.removeEventListener('connectionStateChange', this._onConnectionStateChange)
                        this._emit('unsubscribe', this._eventFilter)
                    }
                    break
                }
                break
            }
        }

        _setState (state) {
            if (!Object.values(SUBSCRIPTION_STATES).includes(state)) {
                throw new Error(`Unknown state: ${state}.`)
            }

            if (this._state !== state) {
                this._state = state
                this._emit('stateChange')
            }
        }
    }

    class EntityNotificationsClient extends EventEmitter {
        constructor (options = {}) {
            super()
            this._eventFilters = new Map() // { eventFilterToString(ef): { listeners: [f1, f2, ...], eventFilter: ef, serverConfirmed: bool } }

            this._reconnectTimeout = null
            this._url = options.url || null
            this._connectionState = CONNECTION_STATES.DISCONNECTED

            // Set iff connectionState is CONNECTING_INITIAL, CONNECTING_WAITING, CONNECTING_RETRYING, CONNECTED
            this._socket = null

            // Expose CONNECTION_STATES as public API.
            for (const key of Object.keys(CONNECTION_STATES)) {
                this[key] = CONNECTION_STATES[key]
            }
        }

        get url () {
            return this._url
        }

        set url (url) {
            if (this.connectionState !== CONNECTION_STATES.DISCONNECTED) {
                throw new Error('Url can only be changed in disconnected state.')
            }

            this._url = url
        }

        get connectionState () {
            return this._connectionState
        }

        connect () {
            if (!this._url) {
                throw 'Cannot connect. Url not set.'
            }

            switch (this.connectionState) {
            case CONNECTION_STATES.DISCONNECTED:
                this._setConnectionState(CONNECTION_STATES.CONNECTING_INITIAL)
                break
            case CONNECTION_STATES.CONNECTING_WAITING:
                clearTimeout(this._reconnectTimeout)
                this._reconnectTimeout = null
                this._setConnectionState(CONNECTION_STATES.CONNECTING_RETRYING)
                break
            case CONNECTION_STATES.CONNECTED:
            case CONNECTION_STATES.CONNECTING_INITIAL:
            case CONNECTION_STATES.CONNECTING_RETRYING:
            case CONNECTION_STATES.CONNECTED:
                throw new Error('Already connecting or connected.')
            }

            this._socket = new KeepAliveWrapper(new WebSocket(this._url))
            this._socket.addEventListener('open', this._onOpen.bind(this))
            this._socket.addEventListener('message', this._onMessage.bind(this))
            this._socket.addEventListener('close', this._onClose.bind(this))
            this._socket.addEventListener('error', this._onError.bind(this))
        }

        /* eventFilter:
         *   clazz: <string>
         *   attributes: <map<string,string>>
         *   name: <"CREATED"|"CHANGED"|"DELETED"|null>
         *
         * Callbacks to load from api:
         * - Initial callback is invoked in case:
         *   - request succeeded,
         *   - immediately if waiting to reconnect
         *   - or when connection is reestablished.
         * - Incremental callback is invoked only on subscribed subscriptions when an event is received from server.
         */
        addEntityEventListener (eventFilter, initialCallback, incrementalCallback) {
            const filterHash = this._eventFilterToString(eventFilter)
            let subscription = this._eventFilters.get(filterHash)
            if (!subscription) {
                subscription = new Subscription(this, eventFilter)
                subscription.addEventListener('subscribe', eventFilter => this._send(CLIENT_MESSAGE_TYPES.SUBSCRIBE, eventFilter))
                subscription.addEventListener('unsubscribe', eventFilter => this._send(CLIENT_MESSAGE_TYPES.UNSUBSCRIBE, eventFilter))
                this._eventFilters.set(filterHash, subscription)
            }

            subscription.addListener(initialCallback, incrementalCallback)
        }

        removeEntityEventListener (eventFilter, initialCallback, incrementalCallback) {
            const filterHash = this._eventFilterToString(eventFilter)
            const subscription = this._eventFilters.get(filterHash)
            if (subscription) {
                subscription.removeListener(initialCallback, incrementalCallback)
            } else {
                throw new Error('Listener not found.')
            }

            if (!subscription.size) {
                this._eventFilters.delete(filterHash)
            }
        }

        destroy () {
            this.removeAllEventListeners()
            this._eventFilters = new Map()
            this._socket.destroy()
        }

        _onOpen () {
            this._setConnectionState(CONNECTION_STATES.CONNECTED)
        }

        _onMessage (event) {
            const message = JSON.parse(event.data)
            let filterHash

            switch (message.messageType) {
            case SERVER_MESSAGE_TYPES.SUBSCRIBED:
                filterHash = this._eventFilterToString(message.eventFilter)
                this._eventFilters.get(filterHash).handleSubscribed()
                break
            case SERVER_MESSAGE_TYPES.UNSUBSCRIBED:
                break
            case SERVER_MESSAGE_TYPES.ENTITY_EVENT:
                filterHash = this._eventFilterToString(message.eventFilter)
                this._eventFilters.get(filterHash).handleEntityEvent(message.event)
                break
            case SERVER_MESSAGE_TYPES.ERROR:
                throw new Error('Bad request.', message)
            default:
                log(`Unknown 'messageType': ${message.messageType}.`)
            }
        }

        _onClose () {
            this._setConnectionState(CONNECTION_STATES.CONNECTING_WAITING)
            this._reconnect()
        }

        _onError (e) {
            log('Connection error,', e)
        }

        _setConnectionState (state) {
            if (!Object.values(CONNECTION_STATES).includes(state)) {
                throw new Error(`Unknown state: ${state}.`)
            }

            if (this._connectionState !== state) {
                this._connectionState = state
                this._emit('connectionStateChange')
            }
        }

        _reconnect () {
            this._reconnectTimeout = setTimeout(() => {
                this.connect()
            }, this._randomNumber(RECONNECT_TIMEOUT_MIN, RECONNECT_TIMEOUT_MAX))
        }

        _send (messageType, eventFilter) {
            if (this.connectionState !== CONNECTION_STATES.CONNECTED) {
                throw new Error('Not connected.')
            }

            this._socket.send(JSON.stringify({ messageType, eventFilter }))
        }

        _randomNumber (min, max) {
            return Math.floor(Math.random() * (max - min + 1) + min)
        }

        _eventFilterToString ({ clazz, attributes, name }) {
            const keys = Object.keys(attributes).sort()

            const sortedAttributes = keys.reduce((result, key) => {
                result[key] = attributes[key]
                return result
            }, {})

            return JSON.stringify({
                clazz,
                attributes: sortedAttributes,
                name,
            })
        }
    }

    // Protocol constants - must match on client and server
    //
    // Client interval is longer because some browsers use throttling of timeout functions for inactive tabs,
    // and we do not want to disconnect clients too early.
    const SERVER_KEEPALIVE_INTERVAL = 10000
    const CLIENT_KEEPALIVE_INTERVAL = 30000

    const KEEP_ALIVE_TIMEOUT = CLIENT_KEEPALIVE_INTERVAL
    const DEADLINE_TIMEOUT = SERVER_KEEPALIVE_INTERVAL * 2
    const KEEPALIVE = 'KEEPALIVE'

    class KeepAliveWrapper extends EventEmitter {
        constructor (ws) {
            super()
            this._ws = ws
            this._keepAliveTimeout = null
            this._deadlineTimeout = null

            this._ws.addEventListener('open', this._onOpen.bind(this))
            this._ws.addEventListener('message', this._onMessage.bind(this))
            this._ws.addEventListener('close', this._onClose.bind(this))
            this._ws.addEventListener('error', this._onError.bind(this))
        }

        get readyState () {
            return this._ws.readyState
        }

        send () {
            this._resetKeepAlive()
            this._ws.send(...arguments)
        }

        destroy () {
            clearTimeout(this._keepAliveTimeout)
            clearTimeout(this._deadlineTimeout)

            this.removeAllEventListeners()
        }

        _onOpen () {
            this._resetKeepAlive()
            this._resetDeadline()
            this._emit('open')
        }

        _onMessage (payload) {
            this._resetDeadline()
            if (payload.data !== KEEPALIVE) {
                this._emit('message', payload)
            }
        }

        _onClose (payload) {
            this._emit('close', payload)
            this.destroy()
        }

        _onError (payload) {
            this._emit('error', payload)
        }

        _resetKeepAlive () {
            clearTimeout(this._keepAliveTimeout)
            this._keepAliveTimeout = setTimeout(() => {
                this.send(KEEPALIVE)
            }, KEEP_ALIVE_TIMEOUT)
        }

        _resetDeadline () {
            clearTimeout(this._deadlineTimeout)
            this._deadlineTimeout = setTimeout(() => {
                this._ws.close()
            }, DEADLINE_TIMEOUT)
        }
    }

    function log (message) {
        if (typeof process !== 'undefined' && process.env.NODE_ENV !== 'test') {
            console.log(message)
        }
    }

    // using legacy es5 module to ensure library can
    // be shared with builder
    if (typeof module !== 'undefined' && module !== null) {
        module.exports = EntityNotificationsClient
    } else {
        namespace.EntityNotificationsClient = EntityNotificationsClient
    }
})(this)
