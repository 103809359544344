<template>
  <div v-if="identifier === 'profile-permission'">
    <div class="error-solution__header">Ask a Meta Business admin to do the following:</div>
    <ol class="error-solution__list">
      <li>Navigate to Business Manager settings.</li>
      <li>Select “People“ in sidebar.</li>
      <li>Ensure you have a “Manage campaigns“ access level for the Ad Account you're using.</li>
      <li>Ensure zou have a “Publish content“ access level for the Facebook/Instagram Page.</li>
    </ol>
    <div class="error-solution__footer">
      <a href="https://business.facebook.com/" class="error-solution__link" target="_blank">Go to Business Manager</a>
    </div>
  </div>
  <div v-if="identifier === 'store-set'">
    <div class="error-solution__header">
      <div>
        Navigate to the ad set in Meta Ads Manager and ensure a Store Set is specified.
        <br>
        See how to setup a store traffic ad campaign in Facebook
        <a href="https://www.facebook.com/business/help/956093091134327?id=429905037479832" class="error-solution__link" target="_blank">here</a>.
      </div>
    </div>
  </div>
  <div v-if="identifier === 'post' || identifier === 'image-upload-fail'">
    <div class="error-solution__header">
      <div>
        Facebook's Marketing API may be experiencing an issue. Please try again in 10 minutes.
        <br>
        If the problem persists, please contact
        <a href="https://www.facebook.com/business/help/support" class="error-solution__link" target="_blank">Meta</a>
        or
        <a href="support@celtra.com" class="error-solution__link" target="_blank">Celtra support</a>.
      </div>
    </div>
  </div>
  <div v-if="identifier === 'same-pages'">
    <div class="error-solution__header">To ensure the creative and ad set pages match, do the following:</div>
    <ol class="error-solution__list">
      <li>Navigate to the ad set in Meta Ads Manager.</li>
      <li>Review the page or app you are promoting.</li>
      <li>Distribute again with the page or app set in the ad set.</li>
    </ol>
  </div>
  <div v-if="identifier === 'aspect-ratio'">
    <div class="error-solution__header">To remove incompatible placements:</div>
    <ol class="error-solution__list">
      <li>Navigate to the ad set in Meta Ads Manager.</li>
      <li>In the Placements section, select “manual”.</li>
      <li>Remove Instagram placements.</li>
    </ol>
    <div class="error-solution__footer">
      <div>
        Alternatively, you can change your creatives to support Instagram Feed placements.
        <br>
        <a href="https://www.facebook.com/business/ads-guide/image/instagram-feed/traffic" class="error-solution__link" target="_blank">Learn more</a>
        about Instagram placement.
      </div>
    </div>
  </div>
  <div v-if="identifier === 'reach-frequency'">
    <div class="error-solution__header">
      <div>
          Push ads live or create at least one active ad in your Meta Ads Manager.
      </div>
    </div>
  </div>
  <div v-if="identifier === 'missing-cta'">
    <div class="error-solution__header">To add a compatible call-to-action type:</div>
    <ol class="error-solution__list">
      <li>Select the creatives again, and click “Export“.</li>
      <li>Enable the “Export creatives with placeholder CTA“ option, and click export.</li>
      <li>Find the ad in the Meta Ads Manager, and set your desired CTA.</li>
    </ol>
  </div>
  <div v-if="identifier === 'invalid-cta'">
    <div class="error-solution__header">To update the incompatible call-to-action type:</div>
    <ol class="error-solution__list">
      <li>Select the creatives again, and click “Export“.</li>
      <li>Enable the “Export creatives with placeholder CTA“ option, and click export.</li>
      <li>Find the ad in the Meta Ads Manager, and set your desired CTA.</li>
    </ol>
  </div>
  <div v-if="identifier === 'missing-link'">
    <div class="error-solution__header">To resolve the issue:</div>
    <ol class="error-solution__list">
      <li>In Design tool, define a CTA, Deep Link OR Website URL</li>
      <li>Reopen the "Push to Meta" window from Export.</li>
    </ol>
  </div>
  <div v-if="identifier === 'missing-cta-for-video-description'">
    <div class="error-solution__header">
      <div>
        In order to distribute this ad with a video link description, a CTA and a Website URL must be
        <br>
        defined in Design mode.
      </div>
    </div>
  </div>
  <div v-if="identifier === 'old-targeting-setting'">
    <div class="error-solution__header">
      <div>
        In Meta Ads Manager update the Ad Set Location targeting to use the new available option.
      </div>
    </div>
  </div>
  <div v-if="identifier === 'default'">
    <div class="error-solution__header">
      <div>
        Please contact support@celtra.io, share the URL to this page, and describe your actions that
        <br>
        prompted this error message.
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "DistributionErrorSolution",
  props: {
    identifier: { type: String, required: true },
  },
}
</script>

<style lang="less" scoped>
@import (reference) '~design-system-variables/index';

.error-solution {
  color: @charcoal;
  font-size: 14px;

  &__header {
    color: @charcoal;
    font-size: 14px;
    line-height: 20px;
    margin-left: 32px;
    margin-top: 11px;
  }

  &__list {
    color: @charcoal;
    font-size: 14px;
    margin-bottom: 0px;
    margin-top: 24px;
    padding-left: 50px;
  }

  &__footer {
    color: @charcoal;
    font-size: 14px;
    margin-left: 32px;
    margin-top: 24px;
  }

  &__link {
    color: @primary-blue;
    text-decoration: none;
  }
}

</style>
