<template>
  <a-modal
    :visible="promptVisible"
    @close="cancelPrompt"
    @cancel="cancelPrompt"
    @ok="confirmPrompt"
    track-name="modal"
  >
    <template #title>Continue downloading?</template>
    <div>{{ promptMessage }}</div>
    <div class="prompt-text__subtitle">
      You can still download .zip file which will contain successfully exported
      creatives and information about failed ones
    </div>
    <br>
    <div class="prompt-text__scrollable-div" ref="scrollable">
      <div>
        <c-scrollable
          :hide-scrollbar="true"
          theme="light"
          class="prompt-text__scrollable"
        >
          <div
            v-for="(creative, error) in failedCreatives"
            :key="error"
            class="prompt-text__creatives"
          >
            <div class="prompt-text__error-message">
              <div class="prompt-text__subtitle">
                {{ getErrorText(error, creative) }}
              </div>
              <a-tooltip theme="light" placement="bottom">
                <c-icon name="circle-help-regular" class="prompt-text__icon" />
                <template #title>
                  <div
                    v-for="message in getCreativesMessages(creative)"
                    :key="message"
                  >
                    {{ message }}
                  </div>
                </template>
              </a-tooltip>
            </div>
          </div>
        </c-scrollable>
      </div>
    </div>
    <br>
  </a-modal>
</template>
<script>
import { computed } from "vue";
import { useStore } from "vuex";

export default {
  setup(_, { emit }) {
    const store = useStore()
    const promptVisible = computed(() => store.state.distributionDialog.zip.promptVisible)
    const failedCreatives = computed(() => store.state.distributionDialog.zip.failedCreatives)

    const promptMessage = computed(() => store.getters["distributionDialog/zip/promptMessage"])

    function getErrorText(error, creatives) {
      const creativesText = creatives.length > 1 ? `${creatives.length} creatives have` : "1 creative has";
      return `${creativesText} failed with error: ${error}`;
    }

    function getCreativesMessages(creatives) {
      return creatives.map((c) => `${c.id}: ${c.name}`);
    }

    function confirmPrompt () {
      store.dispatch("distributionDialog/zip/confirmExportPrompt")
      emit("close")
    }

    function cancelPrompt () {
      store.dispatch("distributionDialog/zip/cancelExportPrompt")
      emit("close")
    }

    return {
      promptVisible,
      failedCreatives,
      promptMessage,
      getErrorText,
      getCreativesMessages,
      confirmPrompt,
      cancelPrompt,
    }
  },
};
</script>
<style lang="less" scoped>
@import (reference) '~design-system-variables/index';

.prompt-text {
  &__subtitle {
    width: 100%;
    margin: 0 10px 10px 10px;
    color: @smog;
    font-size: 14px;
  }

  &__creatives {
    position: relative;
    display: flex;
    width: 100%;
  }

  &__icon {
    min-width: 16px;
    margin: 0 0 0 auto;
  }

  &__scrollable-div {
    position: relative;
    max-height: 50vh;
    display: flex;
  }

  &__error-message {
    display: flex;
    position: relative;
    width: 100%;
    padding: 0 15px 0 15px; // Fix to move scrollbar right of tooltip icon, as to not interfere with :hover events
  }
}
</style>
