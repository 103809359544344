export const campaignObjectiveCallToActionTypes = {
  OUTCOME_AWARENESS: [
    "APPLY_NOW",
    "BOOK_TRAVEL",
    "CONTACT_US",
    "DOWNLOAD",
    "GET_QUOTE",
    "GET_SHOWTIMES",
    "LEARN_MORE",
    "LISTEN_NOW",
    "NO_BUTTON",
    "REQUEST_TIME",
    "SEE_MENU",
    "SHOP_NOW",
    "SIGN_UP",
    "SUBSCRIBE",
    "WATCH_MORE",
    "MESSAGE_PAGE",
    "WHATSAPP_MESSAGE",
  ],
  OUTCOME_ENGAGEMENT: [
    "BOOK_TRAVEL",
    "DOWNLOAD",
    "CALL_NOW",
    "GET_QUOTE",
    "GET_SHOWTIMES",
    "LEARN_MORE",
    "LISTEN_NOW",
    "ORDER_NOW",
    "SHOP_NOW",
    "SIGN_UP",
    "SUBSCRIBE",
    "WATCH_MORE",
    "MESSAGE_PAGE",
    "WHATSAPP_MESSAGE",
  ],
  OUTCOME_TRAFFIC: [
    "APPLY_NOW",
    "BOOK_TRAVEL",
    "CONTACT_US",
    "DOWNLOAD",
    "GET_OFFER",
    "GET_QUOTE",
    "PAY_TO_ACCESS",
    "GET_SHOWTIMES",
    "LEARN_MORE",
    "LISTEN_NOW",
    "NO_BUTTON",
    "ORDER_NOW",
    "REQUEST_TIME",
    "SEE_MENU",
    "SHOP_NOW",
    "SIGN_UP",
    "SUBSCRIBE",
    "WATCH_MORE",
  ],
  OUTCOME_LEADS: [
    "APPLY_NOW",
    "BOOK_TRAVEL",
    "BUY_TICKETS",
    "CONTACT_US",
    "DONATE_NOW",
    "DOWNLOAD",
    "GET_OFFER",
    "GET_QUOTE",
    "GET_SHOWTIMES",
    "LEARN_MORE",
    "LISTEN_NOW",
    "ORDER_NOW",
    "PLAY_GAME",
    "REQUEST_TIME",
    "SEE_MENU",
    "SHOP_NOW",
    "SIGN_UP",
    "SUBSCRIBE",
    "WATCH_MORE",
  ],
  OUTCOME_APP_PROMOTION: [
    "BOOK_TRAVEL",
    "DOWNLOAD",
    "LEARN_MORE",
    "LISTEN_NOW",
    "ORDER_NOW",
    "PLAY_GAME",
    "INSTALL_MOBILE_APP",
    "SHOP_NOW",
    "SIGN_UP",
    "SUBSCRIBE",
    "WATCH_MORE",
  ],
  OUTCOME_SALES: [
    "APPLY_NOW",
    "BOOK_TRAVEL",
    "BUY_TICKETS",
    "CONTACT_US",
    "DOWNLOAD",
    "GET_OFFER",
    "GET_QUOTE",
    "GET_SHOWTIMES",
    "LEARN_MORE",
    "LISTEN_NOW",
    "ORDER_NOW",
    "PLAY_GAME",
    "REQUEST_TIME",
    "SEE_MENU",
    "SHOP_NOW",
    "SIGN_UP",
    "SUBSCRIBE",
    "WATCH_MORE",
  ],
  BRAND_AWARENESS: [
    "APPLY_NOW",
    "BOOK_TRAVEL",
    "CONTACT_US",
    "DOWNLOAD",
    "GET_QUOTE",
    "GET_SHOWTIMES",
    "LEARN_MORE",
    "LISTEN_NOW",
    "REQUEST_TIME",
    "SEE_MENU",
    "SHOP_NOW",
    "SIGN_UP",
    "SUBSCRIBE",
    "WATCH_MORE",
    "MESSAGE_PAGE",
    "WHATSAPP_MESSAGE",
  ],
  REACH: [
    "APPLY_NOW",
    "BOOK_TRAVEL",
    "CALL_NOW",
    "CONTACT_US",
    "DOWNLOAD",
    "GET_DIRECTIONS",
    "GET_QUOTE",
    "GET_SHOWTIMES",
    "LEARN_MORE",
    "LISTEN_NOW",
    "REQUEST_TIME",
    "SAVE",
    "SEE_MENU",
    "SHOP_NOW",
    "SIGN_UP",
    "SUBSCRIBE",
    "WATCH_MORE",
    "MESSAGE_PAGE",
    "WHATSAPP_MESSAGE",
  ],
  LINK_CLICKS: [
    "APPLY_NOW",
    "BOOK_TRAVEL",
    "BUY_TICKETS",
    "CALL_NOW",
    "CONTACT_US",
    "DONATE_NOW",
    "DOWNLOAD",
    "GET_OFFER",
    "GET_QUOTE",
    "GET_SHOWTIMES",
    "LEARN_MORE",
    "LISTEN_NOW",
    "REQUEST_TIME",
    "SEE_MENU",
    "SHOP_NOW",
    "SIGN_UP",
    "SUBSCRIBE",
    "WATCH_MORE",
  ],
  POST_ENGAGEMENT: [
    "GET_QUOTE",
    "LEARN_MORE",
    "SHOP_NOW",
    "MESSAGE_PAGE",
    "WHATSAPP_MESSAGE",
  ],
  PAGE_LIKES: [
    "LIKE_PAGE",
  ],
  EVENT_RESPONSES: [
    "EVENT_RSVP",
  ],
  APP_INSTALLS: [
    "BOOK_TRAVEL",
    "DOWNLOAD",
    "INSTALL_MOBILE_APP",
    "LEARN_MORE",
    "LISTEN_NOW",
    "PLAY_GAME",
    "SHOP_NOW",
    "SIGN_UP",
    "SUBSCRIBE",
    "USE_APP",
    "WATCH_MORE",
  ],
  VIDEO_VIEWS: [
    "BOOK_TRAVEL",
    "DOWNLOAD",
    "GET_QUOTE",
    "GET_SHOWTIMES",
    "INSTALL_MOBILE_APP",
    "LEARN_MORE",
    "LISTEN_NOW",
    "SHOP_NOW",
    "SIGN_UP",
    "SUBSCRIBE",
    "WATCH_MORE",
    "MESSAGE_PAGE",
    "WHATSAPP_MESSAGE",
  ],
  MESSAGES: [
    "APPLY_NOW",
    "BOOK_TRAVEL",
    "CONTACT_US",
    "DONATE_NOW",
    "GET_OFFER",
    "GET_QUOTE",
    "LEARN_MORE",
    "SHOP_NOW",
    "SIGN_UP",
    "SUBSCRIBE",
    "MESSAGE_PAGE",
  ],
  PRODUCT_CATALOG_SALES: [
    "BOOK_TRAVEL",
    "DONATE_NOW",
    "DOWNLOAD",
    "GET_SHOWTIMES",
    "LEARN_MORE",
    "LISTEN_NOW",
    "OPEN_LINK",
    "SHOP_NOW",
    "SIGN_UP",
    "SUBSCRIBE",
  ],
  STORE_VISITS: [
    "CALL_NOW",
    "GET_DIRECTIONS",
    "LEARN_MORE",
    "ORDER_NOW",
    "MESSAGE_PAGE",
    "SHOP_NOW",
  ],
  CONVERSIONS: [
    "APPLY_NOW",
    "BOOK_TRAVEL",
    "CONTACT_US",
    "DONATE_NOW",
    "GET_QUOTE",
    "LEARN_MORE",
    "ORDER_NOW",
    "PLAY_GAME",
    "SHOP_NOW",
    "SIGN_UP",
    "SUBSCRIBE",
    "WHATSAPP_MESSAGE",
  ],
}

export const unsupportedCampaignObjectives = ["LEAD_GENERATION"]

export const supportedOptimizationGoalsByCampaignObjective = {
  "OUTCOME_LEADS": ["OFFSITE_CONVERSIONS"],
}

export const campaignObjectivesWithRequiredCTA = ["OUTCOME_TRAFFIC", "OUTCOME_LEADS", "OUTCOME_SALES", "LINK_CLICKS", "VIDEO_VIEWS"]
