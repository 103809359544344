<template>
  <div class="footer">
    {{ creativesText }}
  </div>
</template>

<script>
import { computed } from "vue"
import { useStore } from "vuex"

export default {
  setup () {
    const store = useStore()
    const creativesText = computed(() => store.getters["creativesTable/creativesText"])

    return {
      creativesText,
    }
  },
}
</script>

<style lang="less" scoped>
@import (reference) '~design-system-variables/index';

.footer {
  display: flex;
  justify-content: center;
  height: 40px;
  color: white;
  align-items: center;
  background: @ink;
  font-size: 12px;
  margin-top: 1px;
}
</style>
