<template>
  <div class="creative-brief-info">
    <div class="creative-brief-info__text">
      <div class="creative-brief-info__campaign-name">{{ campaignName }}</div>
      <div class="creative-brief-info__user-info">
        <div class="creative-brief-info__user-info-title">Sent by</div>
        <div class="creative-brief-info__user-info-data">
          <a-avatar class="creative-brief-info__user-info-avatar">{{ userInitials }}</a-avatar>
          {{ userName }}
        </div>
      </div>
    </div>

    <div class="creative-brief-info__thumbnail-container" v-show="creativeThumbnail">
      <img
        class="creative-brief-info__thumbnail"
        :src="creativeThumbnail"
        alt="Creative Thumbnail"
        @error="onThumbnailError"
      >
    </div>
  </div>
</template>

<script>
import { computed } from "vue"
import { useStore } from "vuex"

export default {
  setup () {
    const store = useStore()
    const campaignName = computed(() => store.state.campaignName)
    const userName = computed(() => {
      if (!store.state.user) {
        return ""
      }

      return `${store.state.user.firstName} ${store.state.user.lastName}`
    })
    const userInitials = computed(() => {
      if (!store.state.user) {
        return ""
      }

      return `${store.state.user.firstName.charAt(0)}${store.state.user.lastName.charAt(0)}`
    })
    const creativeThumbnail = computed(() => {
      if (!store.state.creatives || store.state.creatives.length === 0) {
        return null
      }

      return store.state.creatives[0].thumbnailUrl
    })

    function onThumbnailError(event) {
      event.target.src = "preview-not-available.png"
    }

    return {
      campaignName,
      userName,
      userInitials,
      creativeThumbnail,
      onThumbnailError,
    }
  },
}
</script>

<style lang="less" scoped>
@import (reference) "~design-system-variables/index";

.creative-brief-info {
  display: flex;
  justify-content: space-between;
  margin-bottom: 34px;

  &__campaign-name {
    margin-bottom: 24px;
    .font-style(heading, l);
    overflow-wrap: anywhere;
  }

  &__user-info {
    margin-bottom: 8px;
    color: @smog;
    .font-style(label, s);

    &-data {
      margin-top: 8px;
      color: @charcoal;
      .font-style(label, l);
    }

    &-avatar {
      margin-right: 8px;
    }
  }

  &__thumbnail {
    max-width: 200px;
    max-height: 200px;
    object-fit: contain;
    border-radius: 8px;
  }
}
</style>
