<template>
  <c-theme-provider theme="light">
    <div class="distribution-errors">
      <a-modal
        :visible="true"
        track-name="distribution-errors-dialog"
        :width="866"
        :expandable="false"
        :footer="null"
        :body-style="{ padding: '24px 0 24px 24px' }"
        @cancel="onClose"
      >
        <template #title>
          Ads with errors
        </template>

        <div class="distribution-errors-content">
          <div class="distribution-errors-content__filler">
            <div class="distribution-errors-description">
              <div class="distribution-errors-description__title">{{ numberOfFailedDistributionsString() }}</div>
              <div class="distribution-errors-description__subtitle">Open the below instructions to review and fix errors.</div>
            </div>

            <a-collapse
              track-name="distribution-errors-collapse"
              v-model:active-key="openedFailedDistributionGroups"
            >
              <a-collapse-panel
                v-for="failedDistribution in failedDistributionsGroupedByMessage"
                :key="failedDistribution.displayMessage"
              >
                <template #header>
                  <span>{{ failedDistribution.displayMessage }}</span>
                  <span class="distribution-errors-collapse__header--number"> ({{ failedDistribution.distributions.length }})</span>
                </template>
                <distribution-error-solution :identifier="failedDistribution.identifier" />
                <a-table
                  :data-source="failedDistribution.distributions"
                  :pagination="false"
                  class="distribution-errors-table"
                >
                  <a-table-column key="creative-name" title="Creative Name">
                    <template #default="{ record }">
                      {{ record.name }}
                    </template>
                  </a-table-column>
                  <a-table-column key="celtra-id" title="Celtra ID">
                    <template #default="{ record }">
                      {{ record.creativeId }}
                    </template>
                  </a-table-column>
                  <a-table-column key="channel" title="Channel">
                    <template #default="{ record }">
                      {{ record.endpoint }}
                    </template>
                  </a-table-column>
                  <a-table-column v-if="failedDistribution.endpoint === 'Facebook'" key="facebook-ad-set" title="Ad Set">
                    <template #default="{ record }">
                      <a :href="getLinkToFacebookAdSet(record.facebookAdAccountId, record.facebookAdSetId)" target="_blank" class="distribution-errors-link">
                        {{ record.facebookAdSetId }}
                      </a>
                    </template>
                  </a-table-column>
                  <a-table-column v-if="failedDistribution.endpoint === 'DCM'" key="dcm-advertiser" title="Advertiser">
                    <template #default="{ record }">
                      {{ record.dcmAdvertiserId }}
                    </template>
                  </a-table-column>
                  <a-table-column v-if="failedDistribution.endpoint === 'Flashtalking'" key="flashtalking-creative-library" title="Creative Library">
                    <template #default="{ record }">
                      {{ record.flashtalkingCreativeLibraryId }}
                    </template>
                  </a-table-column>
                  <a-table-column key="date-distributed" title="Distributed">
                    <template #default="{ record }">
                      {{ dateToLocaleString(record.creationTimestamp, locale, timezone) }}
                    </template>
                  </a-table-column>
                </a-table>
              </a-collapse-panel>
            </a-collapse>
          </div>
        </div>
      </a-modal>
    </div>
  </c-theme-provider>
</template>

<script>
import { computed, ref } from "vue"
import { useStore } from "vuex"
import { dateToLocaleString } from "@store/utils.js"
import DistributionErrorSolution from "./DistributionErrorSolution.vue"

export default {
  components: {
    DistributionErrorSolution,
  },
  setup (_, { emit }) {
    const store = useStore()

    const failedDistributionsGroupedByMessage = computed(() => store.getters["creativesTable/failedDistributionsGroupedByMessage"])
    const openedFailedDistributionGroups = ref(failedDistributionsGroupedByMessage.value.length > 0 ? [failedDistributionsGroupedByMessage.value[0].displayMessage] : [])
    const numberOfFailedDistributions = computed(() => store.getters["creativesTable/numberOfFailedDistributions"])
    const locale = computed(() => store.state.locale)
    const timezone = computed(() => store.state.timezone)

    function numberOfFailedDistributionsString () {
      return numberOfFailedDistributions.value === 1 ? "You have one ad with an error." : `You have ${numberOfFailedDistributions.value} ads with errors.`
    }

    function getLinkToFacebookAdSet (facebookAdAccountId, facebookAdSetId) {
      if (facebookAdAccountId == null || facebookAdSetId == null) {
        return "https://business.facebook.com/adsmanager/manage/adsets"
      }
      return `https://business.facebook.com/adsmanager/manage/adsets/edit?act=${facebookAdAccountId.slice(4)}&selected_adset_ids=${facebookAdSetId}`
    }

    function onClose () {
      emit("close")
    }

    return {
      failedDistributionsGroupedByMessage,
      openedFailedDistributionGroups,
      numberOfFailedDistributions,
      locale,
      timezone,
      numberOfFailedDistributionsString,
      getLinkToFacebookAdSet,
      onClose,
      dateToLocaleString,
    }
  },
}
</script>

<style lang="less" scoped>
@import (reference) '~design-system-variables/index';

.distribution-errors-collapse {
  &__header {
    &--number {
      color: @smog;
    }
  }
}

.distribution-errors-content {
  height: 464px;
  margin: 0 8px;
  overflow-y: auto;
  scrollbar-gutter: stable;

  &__filler {
    padding-right: 20px;
  }

  &::-webkit-scrollbar {
    width: 4px;
    background-color: transparent;
    border-radius: 8px;
  }

  &::-webkit-scrollbar-thumb {
  background-color: rgba(216, 218, 223, 0.5);
}
}

.distribution-errors-description {
  font-size: 14px;
  margin-bottom: 32px;

  &__title {
    color: @charcoal;
  }

  &__subtitle {
    color: @smog;
  }
}

.distribution-errors-table {
  margin-top: 24px;
}

.distribution-errors-link {
  color: @primary-blue;
  text-decoration: none;
}

::v-deep(.ant-table-tbody>tr>td) {
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  color: @charcoal;
  padding: 16px 8px;
}

::v-deep(.ant-table-thead>tr>th) {
  font-weight: 400;
  color: @smog;
  padding: 13px 8px;
}

::v-deep(.ant-modal-body) {
  padding-right: 0;
}

.ant-modal-body {
  padding-right: 0;
}

</style>
