import { createStore } from "vuex"
import sharedModule from "./modules/shared"
import creativeBriefModule from "./modules/creative_brief"
import creativesTableModule from "./modules/creatives_module"
import distributionDialogModule from "./modules/distribution_dialog"

export var store = createStore({
  ...sharedModule,
  modules: {
    creativeBrief: creativeBriefModule,
    creativesTable: creativesTableModule,
    distributionDialog: distributionDialogModule,
  },
})
