import sharedModule from "./shared"
import facebookModule from "./facebook"
import dcmModule from "./dcm"
import zipModule from "./zip"
import flashtalkingModule from "./flashtalking"

export default {
  namespaced: true,
  modules: {
    facebook: facebookModule,
    dcm: dcmModule,
    flashtalking: flashtalkingModule,
    zip: zipModule,
  },
  ...sharedModule,
}
