export const standardAttributes = [
  {
    id: "count",
    label: "Creative",
    values: [],
  },
  {
    id: "product",
    label: "Product",
    values: [],
  },
  {
    id: "format",
    label: "Format",
    values: [],
  },
  {
    id: "size",
    label: "Size",
    values: [],
  },
]
