<template>
  <div class="filters">
    <c-scrollable class="filters__scrollable">
      <br>
      <div class="filters__sort">
        <span class="filters__sort__span">Sort by</span>
        <a-select
          v-model:value="sortByOrder"
          :options="sortByOrderOptions"
          size="small"
          track-name="filtersSelect"
          :bordered="false"
        />
      </div>
      <div class="filters__order">
        <a-select
          v-model:value="sortBy"
          :options="sortByOptions"
          track-name="sort-by"
          placeholder="Select"
        />
      </div>
      <br>
      <span class="filters__span">Filter by</span>
      <a-input-search
        v-model:value="searchFilters"
        placeholder="Search filters"
        allowClear
        trackName="searchFilters"
        class="filters__search"
      />
      <a-menu
        v-model:open-keys="openSubMenus"
        trackName="filter"
        key="filter"
        :selectable="true"
        mode="inline"
        class="filters__a-menu"
      >
        <a-sub-menu v-for="group in attributeGroupsFiltered" :key="group.value" :header="group.value">
          <template #title>{{ group.value === 'Distribution Id' ? 'Distribution' : group.value }}</template>
          <a-menu-item v-for="attribute in group.options" :key="`${group.value}-${attribute.value}`">
            <template #title>{{ attribute.value }}</template>
            <a-checkbox
              track-name="checkbox"
              size="small"
              :checked="checked(group.value, attribute.value)"
              @update:checked="setCheckedAttributes(group.value, attribute.value, $event)"
            >
              {{ attribute.value }}
            </a-checkbox>
          </a-menu-item>
        </a-sub-menu>
      </a-menu>
    </c-scrollable>
  </div>
</template>

<script>
import { computed, nextTick, ref } from "vue"
import { useStore } from "vuex"

export default {
  setup () {
    const store = useStore()
    const searchFilters = computed({
      get () {
        return store.state.creativesTable.searchFiltersText
      },
      set (value) {
        store.dispatch("creativesTable/setSearchFilters", value)
      },
    })
    const attributeGroupsFiltered = computed(() => store.getters["creativesTable/attributeGroupsFiltered"])
    const openSubMenus = computed(() => store.getters["creativesTable/openSubMenus"])

    function checked (group, attribute) {
      return store.state.creativesTable.checkedAttributesObject[group][attribute]
    }

    async function setCheckedAttributes (group, attribute, checked) {
      await nextTick()
      store.dispatch("creativesTable/setCheckedAttributes", [group, attribute, checked])
    }

    const sortBy = computed({
      get () {
        return store.state.creativesTable.sortBy
      },
      set (value) {
        store.dispatch("creativesTable/setSortBy", value)
      },
    })
    const sortByOptions = computed(() => store.getters["creativesTable/sortByOptions"])
    const sortByOrder = computed({
      get () {
        return store.state.creativesTable.sortByOrder
      },
      set (value) {
        store.dispatch("creativesTable/setSortByOrder", value)
      },
    })
    const sortByOrderOptions = ref([
      {
        label: "Descending",
        value: "desc",
      },
      {
        label: "Ascending",
        value: "asc",
      },
    ])

    return {
      setCheckedAttributes,
      checked,
      openSubMenus,
      searchFilters,
      attributeGroupsFiltered,
      sortBy,
      sortByOptions,
      sortByOrder,
      sortByOrderOptions,
    }
  },
}
</script>

<style lang="less" scoped>
@import (reference) '~design-system-variables/index';

.filters {
  display: flex;
  flex-direction: column;
  width: 210px;
  max-height: calc(100vh - 48px);
  background: @ink;
  margin-right: 2px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;

  &__sort {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-left: 18px;
    margin-right: 8px;
    font-size: 12px;

    &__span {
      padding-top: 4px;
      margin-left: 0px;
    }
  }

  &__order {
    color: @smog;
    font-size: 12px;
    padding: 12px 16px 24px 16px;
    border-bottom: 1px solid @graphite;
  }

  &__span {
    font-size: 12px;
    margin-left: 18px;
  }

  &__a-menu {
    position: relative;
    left: -32px;
  }
}

.ant-menu-inline.ant-menu-inline {
  margin-bottom: 48px;
}

.ant-input-search.ant-input-search {
  width: 178px;
  margin: 16px 0px 20px 14px;
}

::v-deep(.ant-menu-submenu-title.ant-menu-submenu-title.ant-menu-submenu-title.ant-menu-submenu-title) {
  height: 28px;
  padding-left: 0;
  margin-bottom: 0;
  margin-left: 26px;
}

::v-deep(.ant-menu-title-content.ant-menu-title-content.ant-menu-title-content.ant-menu-title-content.ant-menu-title-content.ant-menu-title-content) {
  font-size: 12px;
}

::v-deep(.filters__order .ant-select-selector.ant-select-selector.ant-select-selector.ant-select-selector) {
    padding-right: 11px;
}

::v-deep(.ant-menu-item.ant-menu-item.ant-menu-item.ant-menu-item.ant-menu-item.ant-menu-item) {
  color: @cement;
  margin: 0 0 0 18px;
  height: 24px;
  font-size: 12px;
  padding-left: 16px;
  &:hover, &-selected {
    background-color: transparent;
  }
}
</style>
