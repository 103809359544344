import axios from "axios"
import { getLocalStorageItem } from "@store/utils";
import {
  imageCreativeFormats,
  videoCreativeFormats,
  serversWithFallbackImagesToggle,
  htmlCreativeFormats,
  imageFormats,
  adServers,
} from "./constansts"

const state = {
  imageFormat: null,
  optimizeImageForSize: false,
  imageMaxFileSize: null,
  adServer: null,
  includeFallbackImages: false,
  isExporting: false,
  progress: 0,
  promptVisible: false,
  failedCreatives: {},
}

const getState = () => JSON.parse(JSON.stringify(state))

export default {
  namespaced: true,
  state: getState(),
  getters: {
    creativesForExport (state, _, rootState) {
      return rootState.creatives.filter(c => rootState.distributionDialog.creativeIds.includes(c.id))
    },
    isDialogValid (state, getters) {
      const isImageSetupValid = state.imageFormat || !getters.isImageFormatPresent
      const isHtmlSetupValid = state.adServer || !getters.isHtmlFormatPresent
      const isMaxFileSizeValid = state.imageMaxFileSize == null || (state.imageMaxFileSize >= 40 && state.imageMaxFileSize < 999999999)

      return isImageSetupValid && isHtmlSetupValid && isMaxFileSizeValid
    },
    isImageFormatPresent (_, getters) {
      return getters.creativesForExport.filter(c => imageCreativeFormats.includes(c.clazz)).length > 0
    },
    imageFormats () {
      return imageFormats
    },
    isHtmlFormatPresent (_, getters) {
      return getters.creativesForExport.filter(c => htmlCreativeFormats.includes(c.clazz)).length > 0
    },
    adServers () {
      return adServers
    },
    isImageSizeSectionVisible (state) {
      return ["png", "jpeg"].includes(state.imageFormat)
    },
    isImageSizeInputVisible (state) {
      return state.imageFormat === "jpeg" && state.optimizeImageForSize
    },
    maxFileSizeValidationError (state, getters) {
      if (getters.isImageSizeInputVisible && state.imageMaxFileSize != null) {
        if (!Number.isInteger(state.imageMaxFileSize)) {
          return "Must be a number"
        } else if (state.imageMaxFileSize < 40) {
          return "40KB or more"
        } else if (state.imageMaxFileSize > 999999999) {
          return "999999999KB or less"
        }
      }
      return null
    },
    isFallbackImageToggleVisible (state) {
      return serversWithFallbackImagesToggle.includes(state.adServer)
    },
    exportUrlParts (state, getters, rootState, rootGetters) {
      const urlParts = [
        "exportToSingleFolder=1",
        "creativeId.in=" + getters.creativesForExport.map(creative => creative.id).join(","),
        "purpose=live",
        `filter.account.id.in=${rootState.accountId}`,
        `distributionPrototypeUserId=${getLocalStorageItem(rootGetters.accountUserIdLocalStorageKey)}`,
      ]
      const creativeClazzes = [... new Set(getters.creativesForExport.map(creative => creative.clazz))]
      creativeClazzes.forEach(clazz => {
        if (htmlCreativeFormats.includes(clazz)) {
          urlParts.push("field." + clazz + "." + state.adServer + "Zip")

          if (getters.isFallbackImageToggleVisible && state.includeFallbackImages) {
            urlParts.push("field." + clazz + ".fallbackImage")
          }
        } else if (videoCreativeFormats.includes(clazz)) {
          urlParts.push("field." + clazz + ".mainVideo")

          if (clazz === "FacebookVideoAdPost") {
            urlParts.push("field." + clazz + ".placements.mainVideo")
          }
        } else {
          var additionalProperties = []
          if (state.imageFormat !== "png" || state.optimizeImageForSize) {
            additionalProperties.push("format:" + state.imageFormat)
          }
          if (getters.isImageFormatPresent && state.imageFormat === "jpeg" && !state.optimizeImageForSize) {
            additionalProperties.push("jpegQuality:100")
          }
          if (getters.isImageFormatPresent && state.imageFormat === "png" && state.optimizeImageForSize) {
            additionalProperties.push("pngColors:256")
          }
          if (getters.isImageSizeInputVisible && state.imageMaxFileSize && getters.maxFileSizeValidationError === null) {
            additionalProperties.push("jpegMaxSize:" + state.imageMaxFileSize)
          }
          const additionalPropertiesString = additionalProperties.length > 0 ? "[" + additionalProperties.join(",") + "]" : ""

          if (clazz === "FacebookCarouselAdPost") {
            urlParts.push("field." + clazz + ".cards.mainImageOrVideo" + additionalPropertiesString)
          } else {
            urlParts.push("field." + clazz + ".mainImage" + additionalPropertiesString)

            if (clazz === "FacebookPhotoAdPost") {
              urlParts.push("field." + clazz + ".placements.mainImage" + additionalPropertiesString)
            }
          }
        }
      })

      const filename = "exportedCreatives"
      urlParts.push("filename=" + filename)

      return urlParts
    },
    numberOfFailedCreatives (state) {
      return Object.values(state.failedCreatives).reduce((totalFailed, error) => totalFailed + error.length, 0)
    },
    promptMessage (_, getters) {
      if (getters.numberOfFailedCreatives === 0) {
        return ""
      }
      const prependMsg = getters.numberOfFailedCreatives > 1 ? `${getters.numberOfFailedCreatives} creatives have` : "One creative has"
      return `${prependMsg} failed while exporting. Do you wish to continue?`
    },
  },
  actions: {
    initialize () {
      return
    },
    selectImageFormat ({ commit }, imageFormat) {
      commit("SET_IMAGE_FORMAT", imageFormat)
    },
    setOptimizeImageForSize ({ commit }, optimizeImageForSize) {
      commit("SET_OPTIMIZE_IMAGE_FOR_SIZE", optimizeImageForSize)
    },
    setImageMaxFileSize ({ commit }, imageMaxFileSize) {
      commit("SET_IMAGE_MAX_FILE_SIZE", imageMaxFileSize)
    },
    selectAdServer ({ commit }, adServer) {
      commit("SET_AD_SERVER", adServer)
    },
    setIncludeFallbackImages ({ commit }, includeFallbackImages) {
      commit("SET_INCLUDE_FALLBACK_IMAGES", includeFallbackImages)
    },
    distributeCreatives ({ commit, dispatch, getters }) {
      commit("SET_IS_EXPORTING", true)
      return waitForExportReady(() => axios.get(`exportCreatives?${getters.exportUrlParts.join("&")}`))
        .then((response) => {
          if (response.data.assetStatuses.error > 0) {
            dispatch("setFailedCreativesGrouped", response.data.creatives)
            commit("SET_PROMPT_VISIBLE", true)
            return
          }
          dispatch("triggerAssetDownload")
        })
        .finally(() => {
          commit("SET_IS_EXPORTING", false)
          commit("SET_PROGRESS", 0)
        })

      function waitForExportReady (getExportFn) {
        return new Promise((resolve, reject) => {
          checkIfExportReady()

          function checkIfExportReady () {
            const timeoutMs = 1500
            return getExportFn()
              .then(response => {
                if (response.data?.assetStatuses?.pending > 0) {
                  const pendingCount = response.data?.assetStatuses?.pending
                  const donePortion = 1 - (pendingCount / getters.creativesForExport.length)
                  commit("SET_PROGRESS", donePortion)
                  setTimeout(checkIfExportReady, timeoutMs)
                } else {
                  resolve(response)
                }
              })
              .catch(e => reject(e))
          }
        })
      }
    },
    triggerAssetDownload ({ getters }) {
      axios({
        url: "exportCreativesToZip?" + [...getters.exportUrlParts, "purpose=live"].join("&"),
        method: "GET",
        responseType: "blob",
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "exportedCreatives.zip");
        document.body.appendChild(link);
        link.click();
        link.remove()
      });
    },
    resetState ({ commit }) {
      commit("RESET_STATE")
    },
    setFailedCreativesGrouped ({ commit }, exportedCreatives) {
      const failedCreatives = exportedCreatives.reduce((failedCreatives, creative) => {
        addCreativeToGroupIfFailed(creative, failedCreatives)
        return failedCreatives
      }, {})
      commit("SET_FAILED_CREATIVES", failedCreatives)

      function addCreativeToGroupIfFailed (creative, failedCreatives) {
        for (const creativeAsset of Object.values(creative.assets)) {
          const assets = (Array.isArray(creativeAsset)) ? creativeAsset : [creativeAsset]
          for (const asset of assets) {
            if (asset.status === "error") {
              addCreativeToFailedCreatives(creative, asset.error, failedCreatives)
            }
          }
        }
      }

      function addCreativeToFailedCreatives(creative, error, failedCreatives) {
        const creativeObj = {
          "id": creative.creativeId,
          "name": creative.creativeName,
        }
        if (error in failedCreatives) {
          if (!failedCreatives[error].some(creativeObject => creativeObject.id === creative.id)) {
            failedCreatives[error].push(creativeObj)
          }
        } else {
          failedCreatives[error] = [creativeObj]
        }
      }
    },
    cancelExportPrompt({ commit }) {
      commit("SET_PROMPT_VISIBLE", false)
    },
    confirmExportPrompt({ commit, dispatch }) {
      commit("SET_PROMPT_VISIBLE", false)
      dispatch("triggerAssetDownload")
    },
  },
  mutations: {
    SET_IMAGE_FORMAT (state, imageFormat) {
      state.imageFormat = imageFormat

      if (imageFormat === "png") {
        state.optimizeImageForSize = true
      }
      if (imageFormat === "jpeg") {
        state.optimizeImageForSize = false
      }
    },
    SET_OPTIMIZE_IMAGE_FOR_SIZE (state, optimizeImageForSize) {
      state.optimizeImageForSize = optimizeImageForSize
    },
    SET_IMAGE_MAX_FILE_SIZE (state, imageMaxFileSize) {
      state.imageMaxFileSize = imageMaxFileSize
    },
    SET_IS_IMAGE_FORMAT_PRESENT (state, isImageFormatPresent) {
      state.isImageFormatPresent = isImageFormatPresent
    },
    SET_AD_SERVER (state, adServer) {
      state.adServer = adServer
    },
    SET_INCLUDE_FALLBACK_IMAGES (state, includeFallbackImages) {
      state.includeFallbackImages = includeFallbackImages
    },
    SET_IS_HTML_FORMAT_PRESENT (state, isHtmlFormatPresent) {
      state.isHtmlFormatPresent = isHtmlFormatPresent
    },
    SET_PROGRESS (state, donePortion) {
      state.progress = (donePortion * 100).toFixed(0)
    },
    SET_IS_EXPORTING (state, isExporting) {
      state.isExporting = isExporting
    },
    RESET_STATE (state) {
      Object.assign(state, getState())
    },
    SET_PROMPT_VISIBLE (state, promptVisible) {
      state.promptVisible = promptVisible
    },
    SET_FAILED_CREATIVES (state, failedCreatives) {
      state.failedCreatives = failedCreatives
    },
  },
}
