<template>
  <div class="facebook-step">
    <div class="facebook-step__notice" v-if="areIncompatibleCreativesSelected">
      You have selected creatives that cannot be distributed to Facebook. To distribute to Facebook, please close the dialog and limit the selection to Facebook creatives.
    </div>

    <template v-else>
      <div class="facebook-step__intro" v-if="!isOAuthDone">
        <div class="facebook-step__intro-text">Authorize to push creatives directly to Facebook.</div>
        <a-button
          class="facebook-step__login-button"
          type="primary"
          track-name="facebook-login"
          @click="onLogin"
        >
          Authorize via Facebook
        </a-button>
        <div class="facebook-step__intro-button"/>
      </div>

      <div class="facebook-step__destination" v-else>
        <div class="facebook-step__destination-loading" v-if="isFacebookDataLoading">
          <a-spin class="facebook-step__destination-spinner" tip="Loading Facebook assets" size="large" />
        </div>

        <template v-else>
          <distribution-autocomplete
            placeholder="Select ad account"
            name="Facebook Ad Account"
            :options="facebookAdAccountOptions"
            @change="selectFacebookAdAccount"
          />
          <distribution-autocomplete
            placeholder="Select page"
            name="Facebook Page"
            :options="facebookPageOptions"
            @change="selectFacebookPage"
          />
          <distribution-autocomplete
            placeholder="Select campaign"
            name="Facebook Campaign"
            :options="facebookCampaignOptions ?? []"
            :is-disabled="isFacebookCampaignSelectDisabled"
            :on-search-method="onSearchMethod"
            :parent-ids="[facebookAdAccountId]"
            @change="selectFacebookCampaign"
          />
          <distribution-warning
            v-if="facebookCampaignWarning"
            :message="facebookCampaignWarning.message"
            :solvable="facebookCampaignWarning.solvable"
            :solvable-message="facebookCampaignWarning.solvableMessage"
            @solve="setCreativesWithDefaultValues"
          />
          <distribution-autocomplete
            placeholder="Select ad set"
            name="Facebook Ad Set"
            :options="facebookAdSetOptions ?? []"
            :is-disabled="isFacebookAdSetSelectDisabled"
            :is-loading="areFacebookAdSetsLoading"
            :parent-ids="[facebookPageId, facebookCampaignId]"
            @change="selectFacebookAdSet"
          />
          <distribution-autocomplete
            :placeholder="instagramFieldPlaceholder"
            name="Instagram Account"
            :options="instagramAccountOptions ?? []"
            :is-disabled="isInstagramSelectDisabled"
            :is-loading="areInstagramAccountsLoading"
            :parent-ids="[facebookAdAccountId, facebookPageId]"
            @change="selectInstagramAccount"
          />
          <a-checkbox
            track-name="create-live-ad"
            :checked="createLiveAd"
            @update:checked="setCreateLiveAd"
          >
            Push ads live
          </a-checkbox>
        </template>
      </div>
    </template>
  </div>
</template>

<script>
import { computed, onMounted, onUnmounted } from "vue"
import { useStore } from "vuex"
import DistributionAutocomplete from "./DistributionAutocomplete"
import DistributionWarning from "./DistributionWarning"

export default {
  components: {
    DistributionAutocomplete,
    DistributionWarning,
  },
  setup () {
    onMounted(() => window.addEventListener("message", handleCodeMessage))
    onUnmounted(() => window.removeEventListener("message", handleCodeMessage))

    const store = useStore()
    const areIncompatibleCreativesSelected = computed(() => store.getters["distributionDialog/facebook/areIncompatibleCreativesSelected"])
    const isOAuthDone = computed(() => store.state.distributionDialog.facebook.isOAuthDone)
    function onLogin () {
      store.dispatch("distributionDialog/facebook/openOAuthDialog")
    }

    const isFacebookDataLoading = computed(() => store.state.distributionDialog.facebook.isFacebookDataLoading)
    const facebookAdAccountId = computed(() => store.state.distributionDialog.facebook.facebookAdAccountId)
    const facebookAdAccountOptions = computed(() => store.state.distributionDialog.facebook.facebookAdAccountOptions)
    function selectFacebookAdAccount (value) {
      store.dispatch("distributionDialog/facebook/selectFacebookAdAccount", value)
    }

    const facebookPageId = computed(() => store.state.distributionDialog.facebook.facebookPageId)
    const facebookPageOptions = computed(() => store.state.distributionDialog.facebook.facebookPageOptions)
    function selectFacebookPage (value) {
      store.dispatch("distributionDialog/facebook/selectFacebookPage", value)
    }

    const facebookCampaignId = computed(() => store.state.distributionDialog.facebook.facebookCampaignId)
    const facebookCampaignOptions = computed(() => store.state.distributionDialog.facebook.facebookCampaignOptions)
    const isFacebookCampaignSelectDisabled = computed(() => store.state.distributionDialog.facebook.facebookAdAccountId == null || facebookCampaignOptions.value == null)

    function selectFacebookCampaign (value) {
      store.dispatch("distributionDialog/facebook/selectFacebookCampaign", value)
    }

    const facebookAdSetOptions = computed(() => store.state.distributionDialog.facebook.facebookAdSetOptions)
    const isFacebookAdSetSelectDisabled = computed(() => store.state.distributionDialog.facebook.facebookCampaignId == null || store.state.distributionDialog.facebook.facebookPageId == null || facebookAdSetOptions.value == null)
    const areFacebookAdSetsLoading = computed(() => store.state.distributionDialog.facebook.facebookCampaignId != null && store.state.distributionDialog.facebook.facebookPageId != null && facebookAdSetOptions.value == null)

    function selectFacebookAdSet (value) {
      store.dispatch("distributionDialog/facebook/selectFacebookAdSet", value)
    }

    const instagramFieldPlaceholder = computed(() => store.getters["distributionDialog/facebook/isInstagramAccountRequired"] ? "Select instagram account (required)" : "Select instagram account (optional)")
    const instagramAccountOptions = computed(() => store.state.distributionDialog.facebook.instagramAccountOptions)
    const isInstagramSelectDisabled = computed(() => store.state.distributionDialog.facebook.facebookPageId == null || store.state.distributionDialog.facebook.facebookAdAccountId == null || instagramAccountOptions.value == null)
    const areInstagramAccountsLoading = computed(() => store.state.distributionDialog.facebook.facebookPageId != null && store.state.distributionDialog.facebook.facebookAdAccountId != null && instagramAccountOptions.value == null)
    function selectInstagramAccount (value) {
      store.dispatch("distributionDialog/facebook/selectInstagramAccount", value)
    }

    const onSearchMethod = (value) => store.dispatch("distributionDialog/facebook/loadFacebookCampaignOptions", value)

    const createLiveAd = computed(() => store.state.distributionDialog.facebook.createLiveAd)
    function setCreateLiveAd (checked) {
      store.dispatch("distributionDialog/facebook/setCreateLiveAd", checked)
    }

    const facebookCampaignWarning = computed(() => store.state.distributionDialog.facebook.facebookCampaignWarning)
    function setCreativesWithDefaultValues (value) {
      store.dispatch("distributionDialog/facebook/setCreativesWithDefaultValues", value)
    }

    return {
      areIncompatibleCreativesSelected,
      isOAuthDone,
      isFacebookDataLoading,
      facebookAdAccountId,
      facebookAdAccountOptions,
      selectFacebookAdAccount,
      facebookPageId,
      facebookPageOptions,
      selectFacebookPage,
      facebookCampaignId,
      facebookCampaignOptions,
      isFacebookCampaignSelectDisabled,
      selectFacebookCampaign,
      facebookAdSetOptions,
      isFacebookAdSetSelectDisabled,
      areFacebookAdSetsLoading,
      selectFacebookAdSet,
      instagramFieldPlaceholder,
      instagramAccountOptions,
      isInstagramSelectDisabled,
      areInstagramAccountsLoading,
      selectInstagramAccount,
      onLogin,
      onSearchMethod,
      createLiveAd,
      setCreateLiveAd,
      facebookCampaignWarning,
      setCreativesWithDefaultValues,
    }

    function handleCodeMessage (ev) {
      store.dispatch("distributionDialog/facebook/handleCodeMessage", ev)
    }
  },
}
</script>

<style lang="less" scoped>
@import (reference) '~design-system-variables/index';

.facebook-step__notice {
  text-align: center;
  font-size: 14px;
}

.facebook-step__destination-loading {
  display: flex;
  justify-content: center;
}

.facebook-step__intro {
  text-align: center;
}

.facebook-step__intro-text {
  margin-bottom: 16px;
}

</style>
