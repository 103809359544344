<template>
  <c-theme-provider theme="light">
    <div class="creative-brief-dialog">
      <a-modal
        :visible="true"
        track-name="creative-brief-dialog"
        :style="{ overflow: 'hidden' }"
        :width="1280"
        :expandable="true"
        :footer="null"
        @cancel="onClose"
      >
        <template #title>
          Creative brief
        </template>

        <div class="creative-brief-dialog-wrapper" ref="dialogWrapper">
          <creative-brief-info class="creative-brief-dialog__info" />
          <creative-brief-table class="creative-brief-dialog__table" />
        </div>
      </a-modal>
    </div>
  </c-theme-provider>
</template>

<script>
import { ref } from "vue"
import CreativeBriefInfo from "./CreativeBriefInfo"
import CreativeBriefTable from "./CreativeBriefTable"

export default {
  components: {
    CreativeBriefInfo,
    CreativeBriefTable,
  },
  setup (_, { emit }) {
    const dialogWrapper = ref(null)
    function onClose () {
      emit("close")
    }

    return {
      dialogWrapper,
      onClose,
    }
  },
}
</script>
