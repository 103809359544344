<template>
  <div class="zip-step zip-step--exporting" v-if="isExporting">
    <a-spin class="zip-step__exporting-spinner" :tip="`${progress}%`" size="large" />
  </div>

  <div class="zip-step" v-else>
    <div class="zip-step__section zip-step__image" v-if="isImageFormatPresent">
      <a-select
        class="zip-step__select zip-step__field"
        :options="imageFormats"
        v-model:value="imageFormat"
        placeholder="Select image format"
        size="large"
        track-name="zip-image-select"
      />

      <div class="zip-step__image-size" v-if="isImageSizeSectionVisible">
        <div class="zip-step__field">
          <a-switch v-model:checked="optimizeImageForSize" class="zip-step__toggle" track-name="zip-image-optimize-for-size" /> Optimize for size
        </div>
        <div class="zip-step__field zip_step__image-size-input-wrapper" v-if="isImageSizeInputVisible">
          Max. file size
          <a-form trackName="form">
            <a-form-item
              name="zip-image-max-size"
              hasFeedback
              :validateStatus="imageMaxFileSizeStatus === null ? null : 'error'"
              :help="imageMaxFileSizeStatus"
            >
              <a-input-number
                class="zip-step__image-size-input"
                v-model:value="imageMaxFileSize"
                size="large"
                track-name="zip-image-max-size"
              />
            </a-form-item>
          </a-form>
          kB
        </div>
      </div>
    </div>

    <div class="zip-step__section zip-step__html" v-if="isHtmlFormatPresent">
      <a-select
        class="zip-step__select zip-step__field"
        :options="adServers"
        v-model:value="adServer"
        placeholder="Select ad server"
        size="large"
        track-name="zip-ad-server-select"
      />

      <div class="zip-step__field" v-if="isFallbackImageToggleVisible">
        <a-switch v-model:checked="includeFallbackImages" class="zip-step__toggle" track-name="zip-include-fallback-images" /> Include fallback images
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from "vue"
import { useStore } from "vuex"

export default {
  setup () {
    const store = useStore()
    const isExporting = computed(() => store.state.distributionDialog.zip.isExporting)
    const progress = computed(() => store.state.distributionDialog.zip.progress)
    const isImageFormatPresent = computed(() => store.getters["distributionDialog/zip/isImageFormatPresent"])
    const imageFormats = computed(() => store.getters["distributionDialog/zip/imageFormats"])
    const imageFormat = computed({
      get () {
        return store.state.distributionDialog.zip.imageFormat
      },
      set (value) {
        store.dispatch("distributionDialog/zip/selectImageFormat", value)
      },
    })
    const isImageSizeSectionVisible = computed(() => store.getters["distributionDialog/zip/isImageSizeSectionVisible"])
    const optimizeImageForSize = computed({
      get () {
        return store.state.distributionDialog.zip.optimizeImageForSize
      },
      set (value) {
        store.dispatch("distributionDialog/zip/setOptimizeImageForSize", value)
      },
    })
    const isImageSizeInputVisible = computed(() => store.getters["distributionDialog/zip/isImageSizeInputVisible"])
    const imageMaxFileSize = computed({
      get () {
        return store.state.distributionDialog.zip.imageMaxFileSize
      },
      set (value) {
        store.dispatch("distributionDialog/zip/setImageMaxFileSize", value)
      },
    })
    const imageMaxFileSizeStatus = computed(() => store.getters["distributionDialog/zip/maxFileSizeValidationError"])

    const isHtmlFormatPresent = computed(() => store.getters["distributionDialog/zip/isHtmlFormatPresent"])
    const adServers = computed(() => store.getters["distributionDialog/zip/adServers"])
    const adServer = computed({
      get () {
        return store.state.distributionDialog.zip.adServer
      },
      set (value) {
        store.dispatch("distributionDialog/zip/selectAdServer", value)
      },
    })
    const isFallbackImageToggleVisible = computed(() => store.getters["distributionDialog/zip/isFallbackImageToggleVisible"])
    const includeFallbackImages = computed({
      get () {
        return store.state.distributionDialog.zip.includeFallbackImages
      },
      set (value) {
        store.dispatch("distributionDialog/zip/setIncludeFallbackImages", value)
      },
    })

    return {
      isExporting,
      progress,
      isImageFormatPresent,
      imageFormats,
      imageFormat,
      isImageSizeSectionVisible,
      isImageSizeInputVisible,
      optimizeImageForSize,
      imageMaxFileSize,
      imageMaxFileSizeStatus,
      adServers,
      isHtmlFormatPresent,
      adServer,
      isFallbackImageToggleVisible,
      includeFallbackImages,
    }
  },
}
</script>

<style lang="less" scoped>
::v-deep(.ant-form-item-with-help.ant-form-item-with-help) {
  margin-bottom: 0;
}

::v-deep(.ant-form-item-explain) {
  position: absolute;
}

::v-deep(.ant-select-selector.ant-select-selector.ant-select-selector.ant-select-selector.ant-select-selector) {
    width: 100%;
}

.zip-step--exporting {
  display: flex;
  justify-content: center;
}

.zip-step__section {
  margin-bottom: 24px;
}

.zip-step__field {
  display: flex;
  margin-bottom: 16px;
  line-height: 38px;
}

.zip-step__image-size {
  display: flex;
  justify-content: space-between;
}

.zip-step__select {
  width: 100%;
}

.zip-step__image-size-input-wrapper {
  display: flex;
  width: 220px;
}

.zip-step__image-size-input {
  margin: 0 4px;
}

.zip-step__toggle {
  position: relative;
  top: 11px;
  margin-right: 8px;
}
</style>
