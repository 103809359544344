<template>
  <div class="header">
    <div class="action-menu">
      <div class="action-menu__item action-menu__item--uppercase action-menu__item--button">
        <span class="action-menu--flex-item">
          <span class="action-menu__item-text">Project</span>
        </span>
      </div>
      <div class="action-menu__item action-menu__item--big action-menu__item--muted action-menu__item--no-padding">|</div>
      <div class="action-menu__item action-menu__item--big action-menu__item--muted action-menu__item--fixed">{{ campaignName }}</div>
    </div>
    <a-tooltip placement="bottom">
      <div class="action-menu">
        <a-button
          track-name="distribute"
          type="primary"
          style="margin-left: 8px"
          :disabled="isExportButtonDisabled"
          @click="onExport"
        >
          <span>Export</span>
        </a-button>
      </div>
      <template v-if="exportButtonDisabledTooltip" #title>
        {{ exportButtonDisabledTooltip }}
      </template>
    </a-tooltip>
  </div>
</template>

<script>
import axios from "axios"
import { ref, computed } from "vue"
import { useStore } from "vuex"

export default {
  emits: ["distribute"],
  setup (_, { emit }) {
    const store = useStore()
    const campaignId = computed(() => store.state.campaignId)
    const campaignName = computed(() => store.state.campaignName)
    const isExportButtonDisabled = computed(() => store.getters.isExportButtonDisabled)
    const exportButtonDisabledTooltip = computed(() => store.getters.exportButtonDisabledTooltip)
    const uabApiUrl = ref(axios.defaults.baseURL.replace("/api", ""))
    function onExport () {
      if (isExportButtonDisabled.value === true) {
        return
      }
      emit("distribute")
    }

    return {
      uabApiUrl,
      campaignId,
      campaignName,
      isExportButtonDisabled,
      exportButtonDisabledTooltip,
      onExport,
    }
  },
}
</script>

<style lang="less" scoped>
@import (reference) '~design-system-variables/index';

.header {
  display: flex;
  justify-content: space-between;
  height: 48px;
  color: white;
}

.action-menu {
  display: flex;
  margin: 0 15px;
  align-items: center;

  &__item {
    position: relative;
    display: flex;
    padding: 0 15px;
    justify-content: center;
    align-items: center;
    color: @cement;
    font-size: 11px;
    user-select: none;
  }

  &--flex-item {
    position: relative;
    display: flex;
    align-items: center;
  }

  &__item--uppercase {
    text-transform: uppercase;
  }

  &__item--muted {
    color: @smog;
  }

  &__item-text {
    align-items: left;
    font-weight: 500;
    color: inherit;
    padding-left: 10px;
    text-decoration: none;
  }

  &__item--no-padding {
    padding: 0;
  }

  &__item--big {
    font-size: 14px;
  }

  &__item--fixed {
    max-width: 500px;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__item--button {
    transition: color 0.1 ease-out;
    text-decoration: none;
    outline: none;
    height: 100%;
  }
}
</style>
