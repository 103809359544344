import { extractErrorMessageFromError } from "@store/utils"

const state = {
  creativeIds: [],
  namespace: "facebook",
  errorMessage: null,
  isLoading: false,
  isDistributionDialogOpen: false,
}

const getState = () => JSON.parse(JSON.stringify(state))

export default {
  namespaced: true,
  state: getState(),
  getters: {
    distributionMethods () {
      return [
        { id: "facebook", label: "Facebook", icon: "facebook.svg" },
        { id: "dcm", label: "Campaign Manager 360", icon: "dcm.svg" },
        { id: "flashtalking", label: "Flashtalking", icon: "flashtalking.svg" },
        { id: "zip", label: "Download ZIP", icon: "download.svg" },
      ]
    },
    isDialogValid (state, getters) {
      if (!state.namespace || state.errorMessage) {
        return false
      }

      return getters[`${state.namespace}/isDialogValid`]
    },
  },
  actions: {
    initialize ({ commit, dispatch, state }, creativeIds) {
      commit("SET_CREATIVE_IDS", creativeIds)

      dispatch(`${state.namespace}/initialize`)
    },
    selectDistributionMethod ({ commit, dispatch, state }, method) {
      commit("SET_NAMESPACE", method)
      dispatch(`${state.namespace}/initialize`)
    },
    distributeCreatives ({ commit, dispatch, state }) {
      if (!state.namespace) {
        return
      }

      commit("SET_IS_LOADING", true)
      return dispatch(`${state.namespace}/distributeCreatives`)
        .catch((error) => {
          commit("SET_ERROR_MESSAGE", error)
          throw error
        })
        .finally(() => {
          dispatch("creativesTable/setSelectedRowKeys", [], { root: true })
          commit("SET_IS_LOADING", false)
        })
    },
    resetDialog ({ commit, dispatch }) {
      commit("RESET_DIALOG")
      dispatch("zip/resetState")
      dispatch("facebook/resetState")
      dispatch("dcm/resetState")
      dispatch("flashtalking/resetState")
    },
    setIsDistributionDialogOpen ({ commit }, isDistributionDialogOpen) {
      commit("SET_IS_DISTRIBUTION_DIALOG_OPEN", isDistributionDialogOpen)
    },
  },
  mutations: {
    SET_CREATIVE_IDS (state, creativeIds) {
      state.creativeIds = creativeIds
    },
    SET_NAMESPACE (state, namespace) {
      state.namespace = namespace
    },
    SET_ERROR_MESSAGE (state, error) {
      state.errorMessage = extractErrorMessageFromError(error)
    },
    SET_IS_LOADING (state, isLoading) {
      state.isLoading = isLoading
    },
    RESET_DIALOG (state) {
      Object.assign(state, getState())
    },
    SET_IS_DISTRIBUTION_DIALOG_OPEN (state, isDistributionDialogOpen) {
      state.isDistributionDialogOpen = isDistributionDialogOpen
    },
  },
}
