<template>
  <div class="creatives-table">
    <a-table
        :data-source="dataset"
        class="creatives-table__table"
        row-key="id"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="{ defaultPageSize: numberOfCreativesPerPage, showSizeChanger: false, current: currentPage }"
        @change="onPageChange"
    >
      <a-table-column key="icon" title="" width="80px">
        <template #default="{ record }">
          <div class="creatives-table__image-container">
            <img
              class="creatives-table__image"
              :src="[record.thumbnailUrl ?? 'preview-not-available.png']"
              @error="onThumbnailError"
            >
          </div>
        </template>
      </a-table-column>
      <a-table-column
        v-if="selectedColumns.includes('Name')"
        key="Name"
        title="Name"
        max-width="400px"
      >
        <template #default="{ record }">
          {{ record.name }}
        </template>
      </a-table-column>
      <a-table-column
        v-if="selectedColumns.includes('Creative Id')"
        key="Creative Id"
        title="Creative Id"
      >
        <template #default="{ record }">
          {{ record.id }}
        </template>
      </a-table-column>
      <a-table-column
        v-for="attribute in selectedAttributeLabels"
        :key="attribute"
        :title="attribute"
        width="100px"
        max-width="120px"
      >
        <template #default="{ record }">
          <a-tooltip
            :title="getAttributeValueText(record, attribute, true)"
            placement="bottom"
            :mouse-enter-delay="0.5"
          >
          <a-tag
              v-if="getAttributeValueText(record, attribute) !== ''"
              track-name="a-tag"
              class="unselectable-tag"
          >
            {{ getAttributeValueText(record, attribute, false) }}
          </a-tag>
          </a-tooltip>
        </template>
      </a-table-column>
      <a-table-column
        v-if="selectedColumns.includes('Creative Weight')"
        key="Creative Weight"
        title="Creative Weight"
        max-width="80px"
      >
        <template #default="{ record }">
          <a-tooltip v-if="creativeHasWeight(record.id)">
            {{ getCreativeWeightFormatted(record.id) }}
            <template #title>
              {{ getNumberOfVariantsString(record.id) }}
            </template>
          </a-tooltip>
          <a-spin v-else />
        </template>
      </a-table-column>
      <a-table-column
        v-if="selectedColumns.includes('Distribution Id')"
        key="Distribution Id"
        title="Distribution Id"
        max-width="150px"
      >
        <template #default="{ record }">
          <a-tooltip v-if="record.distribution.status === 'IN_SYNC'">
            <a
              v-if="hasValidLinkToFacebookAd(record.distribution)"
              :href="getLinkToFacebookAd(record.distribution.facebookAdAccountId, record.distribution.facebookAdId)"
              target="_blank"
              class="distribution-ad-link"
            >
              {{ record.distribution.facebookAdId }}
            </a>
            <div v-else>{{ getCreativeDistributionId(record) }}</div>
            <template #title>
              {{ getCreativeDistributionPlatform(record) }}
            </template>
          </a-tooltip>
          <a-spin v-else-if="record.distribution.status === 'IN_PROGRESS'" />
          <a-tooltip v-else-if="record.distribution.status === 'ERROR'">
            <c-icon
              name="x-circle-regular"
              size="large"
              class="creatives-table__distribution__error"
            />
            <template #title>
              {{ errorDisplayMessage(record.distribution.errorMessage) }}
            </template>
          </a-tooltip>
        </template>
      </a-table-column>
      <a-table-column
        v-if="selectedColumns.includes('Distributed')"
        key="Distributed"
        title="Distributed"
      >
        <template #default="{ record }">
          <span v-if="record.distribution.status === 'IN_SYNC'">{{ getCreativeDistributionTimestamp(record) }}</span>
        </template>
      </a-table-column>
    </a-table>
  </div>
</template>

<script>
import { computed, watch } from "vue"
import { useStore } from "vuex"
import { dateToLocaleString, humanizeFileSize } from "@store/utils.js"
import { errorMessageSolutionIdentification, numberOfCreativesPerPage } from "@store/modules/creatives_module/shared/constants.js"

export default {
  setup () {
    const store = useStore()
    const dataset = computed(() => store.getters["creativesTable/sortedDataset"])
    const currentPage = computed(() => store.state.creativesTable.currentPage)
    const locale = computed(() => store.state.locale)
    const timezone = computed(() => store.state.timezone)

    const attributeLabels = computed(() => store.getters["creativesTable/attributeLabels"])
    const selectedColumns = computed(() => store.state.creativesTable.selectedColumns)
    const selectedAttributeLabels = computed(() => attributeLabels.value.filter(al => selectedColumns.value.includes(al)))

    const selectedRowKeys = computed(() => store.state.creativesTable.selectedRowKeysArray)
    function onSelectChange (selectedRows) {
      store.dispatch("creativesTable/setSelectedRowKeys", selectedRows)
    }

    const creativeWeights = computed(() => store.getters["creativesTable/creativeWeights"])

    function creativeHasWeight(creativeId) {
      return creativeId in creativeWeights.value && creativeWeights.value[creativeId] !== null
    }

    function getCreativeWeightFormatted(creativeId) {
      return humanizeFileSize(creativeWeights.value[creativeId].weight)
    }

    function getNumberOfVariantsString(creativeId) {
      const numOfVariants = creativeWeights.value[creativeId].numOfVariants
      return numOfVariants > 1 ? `${numOfVariants} variants` : "One variant"
    }

    function getAttributeValueText(record, attribute, unlimited) {
      const matchedAttribute = record.attributes.find(recordAttribute => recordAttribute.label === attribute)
      if (!matchedAttribute) {
        return ""
      }
      if (unlimited || matchedAttribute.value.length < 19) {
        return matchedAttribute.value
      }
      return `${matchedAttribute.value.slice(0, 8)}...${matchedAttribute.value.slice(matchedAttribute.value.length - 7)}`
    }

    function onThumbnailError(event) {
      event.target.src = "preview-not-available.png"
    }

    function getCreativeDistributionId(record) {
      return store.getters["creativesTable/getCreativeDistributionId"](record.distribution)
    }

    function hasValidLinkToFacebookAd (distribution) {
      return distribution.endpoint === "Facebook" && distribution.facebookAdAccountId != null && distribution.facebookAdId != null
    }

    function getLinkToFacebookAd (facebookAdAccountId, facebookAdId) {
      return `https://business.facebook.com/adsmanager/manage/ads/edit?act=${facebookAdAccountId.slice(4)}&selected_ad_ids=${facebookAdId}`
    }

    function getCreativeDistributionPlatform(record) {
      if (record.distribution.endpoint === "DCM") {
        return "Campaign Manager 360"
      }
      return record.distribution.endpoint
    }

    function getCreativeDistributionTimestamp(record) {
      return dateToLocaleString(record.distribution.creationTimestamp, locale.value, timezone.value)
    }

    function errorDisplayMessage (errorMessage) {
      for (const [errorMatch, errorSolution] of Object.entries(errorMessageSolutionIdentification)) {
        if (errorMessage.match(errorMatch) !== null) {
          return errorSolution.displayMessage
        }
      }
      return errorMessage
    }

    function calculateCurrentPage (newDataset) {
      const numberOfPages = Math.ceil(newDataset.length / numberOfCreativesPerPage)
      if (numberOfPages < currentPage.value) {
        store.dispatch("creativesTable/setCurrentPage", numberOfPages)
      }
    }

    function onPageChange(pageInfo) {
      store.dispatch("creativesTable/setCurrentPage", pageInfo.current)
      store.dispatch("creativesTable/loadCreativeWeights")
    }

    watch(dataset, (newValue) => {
      calculateCurrentPage(newValue)
      store.dispatch("creativesTable/loadCreativeWeights")
    })

    return {
      onPageChange,
      dataset,
      attributeLabels,
      selectedColumns,
      selectedAttributeLabels,
      selectedRowKeys,
      creativeWeights,
      currentPage,
      numberOfCreativesPerPage,
      creativeHasWeight,
      getCreativeWeightFormatted,
      getNumberOfVariantsString,
      onSelectChange,
      getAttributeValueText,
      onThumbnailError,
      getCreativeDistributionId,
      hasValidLinkToFacebookAd,
      getLinkToFacebookAd,
      getCreativeDistributionPlatform,
      getCreativeDistributionTimestamp,
      errorDisplayMessage,
    }

  },
}
</script>

<style lang="less" scoped>
@import (reference) '~design-system-variables/index';

.creatives-table {
  height: calc(100vh - 48px - 40px - 96px - 2px);
  width: calc(100vw - 210px);
  background: @ink;
  overflow: scroll;

  &__image-container {
    width: 48px;
    height: 48px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__image {
    width: fit-content;
    height: fit-content;
    max-width: 48px;
    max-height: 48px;
    object-fit: contain;
    border-radius: 8px;
  }

  &__distribution__error {
    color: @danger-red;
  }
}

.distribution-ad-link {
  color: @primary-blue;
  text-decoration: none;
}

.unselectable-tag {
  cursor: default;
  user-select: none;
}

::v-deep(.ant-tag:focus.ant-tag:focus.ant-tag:focus.ant-tag:focus){
  border-color: #3b3b4b;
  box-shadow: none;
}

::v-deep(.ant-table-placeholder) {
  height: calc(100vh - 250px);
}

::v-deep(.ant-table-placeholder.ant-table-placeholder.ant-table-placeholder.ant-table-placeholder.ant-table-placeholder>td) {
    background: @ink;
}

::v-deep(.ant-table-row-selected.ant-table-row-selected.ant-table-row-selected>td) {
    border-bottom-color: @graphite;
}

::v-deep(.ant-table-column-title) {
  white-space: nowrap;
}

</style>
