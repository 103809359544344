<template>
  <span class="autocomplete-title">{{ name }}</span>
  <div class="autocomplete-wrapper">
    <a-autocomplete
      class="autocomplete"
      v-model="value"
      :placeholder="placeholder"
      track-name="distribution-autocomplete"
      :disabled="isDisabled || isLoading"
      :options="filteredOptions"
      :can-add-new="canAddNew"
      @addNew="onAddNew"
      @search="onSearchChange"
      @select="onSelect"
    >
      <template #option="option">
        <div class="option-wrapper">
          <img class="option-icon" v-if="option.imageUrl" :src="option.imageUrl">
          <div class="option-text">
            <c-ellipsis :text="option.label" class="option-text__ellipsis"/>
            <a-tooltip v-if="option.disabled" :title="option.tooltip" placement="topRight">
              <c-icon name="info-bold" class="option-help-icon" />
            </a-tooltip>
            <a-tooltip v-else-if="option.displayTooltip" :title="option.tooltip" placement="topRight">
              <c-icon name="circle-help-regular" class="option-help-icon" />
            </a-tooltip>
            <div v-else class="option-metadata">{{ option.metadata }}</div>
          </div>
        </div>
      </template>
    </a-autocomplete>
    <a-spin v-if="isLoading" class="loader" />
  </div>
</template>

<script>
import { ref, toRefs, computed, watch } from "vue"
import { debounce } from "lodash-es"

export default {
  props: {
    name: { type: String, required: true },
    options: { type: Array, required: true },
    placeholder: { type: String, required: true },
    onSearchMethod: { type: Function, default: null },
    isDisabled: { type: Boolean, default: false },
    isLoading: { type: Boolean, default: false },
    canAddNew: { type: Boolean, default: false },
    parentIds: { type: Array, default: () => [] },
  },
  emits: ["change", "add-new", "search"],
  setup (props, { emit }) {
    const { parentIds, options, isDisabled, isLoading } = toRefs(props)

    const value = ref("")
    const valueSelected = ref(false)

    const filteredOptions = computed(() => {
      if (isDisabled.value || isLoading.value) {
        return []
      }

      if (value.value === "" || !value.value || valueSelected.value) {
        return options.value.map(formatOption)
      }

      return options.value
        .map(formatOption)
        .filter(option => {
          const lowerCaseInput = value.value.toLowerCase()
          return option.label.toLowerCase().indexOf(lowerCaseInput) > -1 ||
            option.metadata.toLowerCase().indexOf(lowerCaseInput) > -1
        })
    })

    function onAddNew (value) {
      emit("add-new", value)
    }

    function onSelect (_, option) {
      valueSelected.value = true
      value.value = option.value
      emit("change", option.key)
    }

    function formatOption (option) {
      return {
        ...option,
        key: option.value,
        value: option.label,
        title: null,
      }
    }

    const onSearchChange = debounce(function (value) {
      valueSelected.value = false
      if (props.onSearchMethod != null) {
        props.onSearchMethod(value)
      }

      emit("change", null)
    }, 300)

    watch(parentIds, (newParentIds, oldParentIds) => {
      if (newParentIds.some((newParentId, i) => newParentId !== oldParentIds[i])) {
        value.value = ""
      }
    })

    return {
      value,
      valueSelected,
      filteredOptions,
      onAddNew,
      onSearchChange,
      onSelect,
    }
  },
}
</script>

<style lang="less">
@import (reference) '~design-system-variables/index';

.autocomplete-title {
  font-size: 12px;
  color: @charcoal;
}

.autocomplete-wrapper {
  margin-top: 2px;
  margin-bottom: 20px;
  position: relative;
}

.autocomplete {
  width: 100%;
}

.option-wrapper {
  display: flex;
}

.option-text {
  width: 100%;
  display: flex;
  justify-content: space-between;

  &__ellipsis {
    max-width: 290px;
  }
}

.option-metadata {
  color: @dust;
}

.option-icon {
  width: 16px;
  height: 16px;
  border-radius: 100%;
  margin-right: 8px;
  position: relative;
  top: 2px;
}

.option-help-icon {
  margin-top: 4px;
  color: @dust;
}

.loader.loader.loader {
  position: absolute;
  top: 12px;
  right: 8px;
}
</style>
