import axios from "axios"
import { orderBy, uniqBy, isEqual } from "lodash-es"
import {
  campaignObjectiveCallToActionTypes,
  campaignObjectivesWithRequiredCTA,
  supportedOptimizationGoalsByCampaignObjective,
  unsupportedCampaignObjectives,
} from "./constants.js"
import { accessTokenExpirationDuration } from "../shared/constants.js"
import { dateToLocaleString, getLocalStorageItem, setLocalStorageItem } from "@store/utils.js"

const mapFacebookAdAccounts = (userAdAccounts) => {
  return userAdAccounts.map(adAccount => {
    const isDisabled = !adAccount.user_tasks.includes("ADVERTISE")
    const tooltip = isDisabled ? "You do not have Advertiser access to this ad account" : ""

    return {
      id: adAccount.id,
      value: adAccount.id,
      label: adAccount.name,
      metadata: adAccount.id,
      disabled: isDisabled,
      tooltip: tooltip,
    }
  })
}

const mapFacebookPages = (userPages) => {
  return userPages.map(page => {
    const isDisabled = !page.permitted_tasks.includes("ADVERTISE")
    const tooltip = isDisabled ? "You do not have Advertiser access to this ad account" : ""

    return {
      id: page.id,
      value: page.id,
      label: page.name,
      metadata: page.id,
      disabled: isDisabled,
      tooltip: tooltip,
      imageUrl: page.picture ? page.picture.url : null,
    }
  })
}

const mapFacebookCampaigns = (campaigns, locale, timezone) => {
  return campaigns.map(campaign => {
    const expired = campaign.stopTime !== null && Date.parse(campaign.stopTime) < Date.now()
    const unsupportedCampaignObjective = unsupportedCampaignObjectives.includes(campaign.objective)

    let tooltip = null
    if (expired) {
      tooltip = `You can't distribute to this campaign because it expired on ${dateToLocaleString(campaign.stopTime, locale, timezone)}.`
    } else if (unsupportedCampaignObjective) {
      const campaignObjective = campaign.objective.replace("_", " ").toLowerCase()
      tooltip = `Distributing creatives to a campaign with the ${campaignObjective} objective is currently not supported by Celtra.`
    }

    return {
      id: campaign.id,
      value: campaign.id,
      label: campaign.name,
      metadata: campaign.id,
      disabled: tooltip !== null,
      tooltip: tooltip,
      objective: campaign.objective,
    }
  })
}

const mapFacebookAdSets = (adSets, campaignObjective, rootState) => {
  return adSets.map(adSet => {
    const expired = adSet.endTime !== null && Date.parse(adSet.endTime) < Date.now()
    const creativesOverflow = adSet.adsCount + rootState.distributionDialog.creativeIds.length > 50
    const missingRequiredTrackingPixel = campaignObjective === "CONVERSIONS" && (adSet.promotedObject === null || !adSet.promotedObject.pixel_id)
    const adSetHasUnsupportedOptimizationGoal = supportedOptimizationGoalsByCampaignObjective[campaignObjective]
        && !supportedOptimizationGoalsByCampaignObjective[campaignObjective].includes(adSet.optimizationGoal)

    const selectedPageAdSetPromotedPageMismatch = !!adSet.promotedObject
        && !!adSet.promotedObject.page_id
        && adSet.promotedObject.page_id !== rootState.distributionDialog.facebook.facebookPageId

    let tooltipMessages = []
    if (expired) {
      tooltipMessages.push(`You can't distribute to this ad set because it expired on ${dateToLocaleString(adSet.endTime, rootState.locale, rootState.timezone)}. Please update the ad set end date in Facebook Ads Manager.`)
    }
    if (selectedPageAdSetPromotedPageMismatch) {
      tooltipMessages.push("Selected page does not match the one promoted in ad set.")
    }
    if (adSetHasUnsupportedOptimizationGoal) {
      tooltipMessages.push("Ad set conversion location is currently not supported by Celtra.")
    }
    if (creativesOverflow) {
      if (adSet.adsCount >= 50) {
        tooltipMessages.push("You can't distribute to this ad set because it already contains a maximum of 50 ads. Please remove ads in Facebook Ads Manager or distribute fewer ads.")
      } else {
        tooltipMessages.push(`You can only distribute ${50 - adSet.adsCount} creatives to this ad set before reaching a maximum of 50 ads. Please remove ads.`)
      }
    }
    if (missingRequiredTrackingPixel) {
      tooltipMessages.push("To distribute to this ad set, you need to set the tracking pixel in Meta Ads Manager.")
    }

    return {
      id: adSet.id,
      value: adSet.id,
      label: adSet.name,
      metadata: adSet.id,
      disabled: tooltipMessages.length > 0,
      tooltip: tooltipMessages.length > 0 ? tooltipMessages.join(" ") : null,
      publisherPlatforms: adSet.targeting.publisher_platforms ?? null,
    }
  })
}

const state = {
  isOAuthDone: false,
  isFacebookDataLoading: false,
  facebookAdAccountOptions: null,
  facebookCampaignOptions: null,
  facebookPageOptions: null,
  instagramAccountOptions: null,
  facebookAdSetOptions: null,
  facebookAdAccountId: null,
  facebookCampaignId: null,
  facebookPageId: null,
  instagramAccountId: null,
  pageBackedInstagramAccountId: null,
  facebookAdSetId: null,
  createLiveAd: false,
  facebookCampaignWarning: null,
  creativesWithDefaultValues: [],
}

const getState = () => JSON.parse(JSON.stringify(state))

export default {
  namespaced: true,
  state: getState(),
  getters: {
    creativesForExport (state, _, rootState) {
      return rootState.creatives.filter(c => rootState.distributionDialog.creativeIds.includes(c.id))
    },
    areIncompatibleCreativesSelected (_, getters) {
      return getters.creativesForExport.filter(c => c.product !== "FACEBOOK").length > 0
    },
    isDialogValid (state, getters) {
      return state.facebookPageId != null && state.facebookAdAccountId != null && state.facebookCampaignId != null && state.facebookAdSetId != null && (getters.isInstagramAccountRequired ? state.instagramAccountId != null : true)
    },
    distributionJobsPostData (state, getters, rooState, rootGetters) {
      if (!state.facebookPageId || !state.facebookAdAccountId) {
        return {}
      }

      const postData = {
        creativeIds: getters.creativesForExport.map(c => c.id),
        endpoint: "Facebook",
        facebookAdAccountId: state.facebookAdAccountId,
        facebookCampaignId: state.facebookCampaignId,
        facebookPageId: state.facebookPageId,
        facebookAdSetId: state.facebookAdSetId,
        instagramAccountId: state.instagramAccountId === "usePage" ? state.pageBackedInstagramAccountId : state.instagramAccountId,
        creativesWithDefaultValues: state.creativesWithDefaultValues,
        caller: "prototype",
        isUpdatable: false,
        accessToken: "facebookDistributionAccessToken",
        distributionPrototypeUserId: getLocalStorageItem(rootGetters.accountUserIdLocalStorageKey),
      }

      return postData
    },
    distributionAllocationJobsPostData (state, _, rootState, rootGetters) {
      if (!state.facebookPageId || !state.facebookAdAccountId || !state.facebookCampaignId || !state.facebookAdSetId) {
        return {}
      }

      const postData = {
        endpoint: "Facebook",
        facebookAdAccountId: state.facebookAdAccountId,
        campaignDistributionId: state.facebookCampaignId,
        facebookAdSetId: state.facebookAdSetId,
        createLiveAd: state.createLiveAd,
        isUpdatable: false,
        accessToken: "facebookDistributionAccessToken",
        distributionPrototypeUserId: getLocalStorageItem(rootGetters.accountUserIdLocalStorageKey),
      }

      return postData
    },
    isInstagramAccountRequired (state, getters) {
      if (state.facebookAdSetId == null) {
        return false
      }

      const adSet = state.facebookAdSetOptions.find(adSetOption => adSetOption.id === state.facebookAdSetId)
      if (adSet == null) {
        return false
      }

      if (adSet.publisherPlatforms) {
        if (isEqual(adSet.publisherPlatforms, ["instagram"])) {
          return true
        }

        if (isEqual(adSet.publisherPlatforms, ["facebook"])) {
          return false
        }
      }

      return getters.creativesForExport.findIndex((c) => c.facebookPlacements && hasInstagramPlacements(c.facebookPlacements)) > -1

      function hasInstagramPlacements(placements) {
        const instagramFeeds = ["instagramFeed", "instagramStories", "instagramExplore", "instagramReels"]
        return placements.filter(placement => instagramFeeds.includes(placement)).length > 0
      }
    },
    campaignObjective (state) {
      if (state.facebookCampaignId == null) {
        return null
      }

      const campaign = state.facebookCampaignOptions.find(campaignOption => campaignOption.id === state.facebookCampaignId)
      return campaign.objective
    },
    creativesWithIncorrectCTA (_, getters) {
      if (getters.campaignObjective === null) {
        return []
      }

      if (!(getters.campaignObjective in campaignObjectiveCallToActionTypes)) {
        // When implementing sentry change this to log in sentry instead of console
        console.error("Can't retrieve campaign objective for the selected campaign. Campaign validation will not work.")
        return []
      }

      return getters.creativesForExport.filter(c => c.facebookCallToAction != null && !campaignObjectiveCallToActionTypes[getters.campaignObjective].includes(c.facebookCallToAction))
    },
    creativesWithMissingCTA (_, getters) {
      return getters.creativesForExport.filter(c => c.facebookCallToAction == null)
    },
    tokenExpirationLocalStorageKey (state, getters, rootState) {
      return `${rootState.distributionPrototypeAccessKeyId}:facebookTokenExpirationTimestamp`
    },
  },
  actions: {
    initialize: ({ commit, dispatch, state, getters }) => {
      if (state.isOAuthDone) {
        return
      }

      const accessTokenExpirationTimestamp = getLocalStorageItem(getters.tokenExpirationLocalStorageKey)
      if (accessTokenExpirationTimestamp === null || Date.now() > parseInt(atob(accessTokenExpirationTimestamp))) {
        return
      }

      commit("SET_IS_OAUTH_DONE", true)
      commit("SET_IS_FACEBOOK_DATA_LOADING", true)

      return dispatch("loadFacebookAssets")
        .catch(error => {
          if (error.response.status === 503) {
            console.error(error)
            setLocalStorageItem(getters.tokenExpirationLocalStorageKey, null)
            commit("SET_IS_OAUTH_DONE", false)
          }
        })
        .finally(() => commit("SET_IS_FACEBOOK_DATA_LOADING", false))
    },
    openOAuthDialog: () => {
      window.open(`${axios.defaults.baseURL}creativeDistributionAuthorization/facebook?state=${window.location.origin}`, "oauth", "width=1024,height=800")
    },
    handleCodeMessage: ({ commit, dispatch, getters }, ev) => {
      if (!ev.data.code) {
        return
      }

      const postData = {
        provider: "facebook",
        accessTokenType: "DistributionAccessToken",
        code: ev.data.code,
      }

      commit("SET_IS_FACEBOOK_DATA_LOADING", true)
      return axios.post("externalAccessTokens", postData)
        .then(() => {
          setLocalStorageItem(getters.tokenExpirationLocalStorageKey, btoa((Date.now() + accessTokenExpirationDuration).toString()))
          commit("SET_IS_OAUTH_DONE", true)
          return dispatch("loadFacebookAssets")
        })
        .catch(error => {
          console.error(error)
          dispatch("setErrorMessage", error, { root: true })
        })
        .finally(() => commit("SET_IS_FACEBOOK_DATA_LOADING", false))
    },
    loadFacebookAssets ({ dispatch }) {
      return Promise.all([
        dispatch("loadFacebookAdAccountOptions"),
        dispatch("loadFacebookPageOptions"),
      ])
    },
    loadFacebookAdAccountOptions: async ({ commit, state }) => {
      if (!state.isOAuthDone) {
        throw new Error("User is not authorized, cannot load Facebook data!")
      }

      commit("SELECT_FACEBOOK_AD_ACCOUNT", null)
      commit("SET_FACEBOOK_AD_ACCOUNT_OPTIONS", null)

      const adAccountsResponse = await axios.get("facebook/adAccounts?accessToken=facebookDistributionAccessToken")
      const adAccounts = mapFacebookAdAccounts(adAccountsResponse.data)
      commit("SET_FACEBOOK_AD_ACCOUNT_OPTIONS", orderBy(adAccounts, ["disabled", "label"]))
    },
    loadFacebookPageOptions: async ({ commit, state }) => {
      if (!state.isOAuthDone) {
        throw new Error("User is not authorized, cannot load Facebook data!")
      }

      commit("SELECT_FACEBOOK_PAGE", null)
      commit("SET_FACEBOOK_PAGE_OPTIONS", null)

      const pagesResponse = await axios.get("facebook/pages?accessToken=facebookDistributionAccessToken")
      const pages = mapFacebookPages(pagesResponse.data)
      commit("SET_FACEBOOK_PAGE_OPTIONS", orderBy(pages, ["disabled", "label"]))
    },
    loadFacebookCampaignOptions: async ({ commit, state, rootState }, search) => {
      commit("SELECT_FACEBOOK_CAMPAIGN", null)
      commit("SET_FACEBOOK_CAMPAIGN_WARNING", null)

      if (!state.facebookAdAccountId) {
        return
      }

      const url = `facebook/campaigns?facebookAdAccountId=${state.facebookAdAccountId}&accessToken=facebookDistributionAccessToken&perPage=100`

      let promises
      if (search) {
        const idUrl = `${url}&id=${search}`
        const nameUrl = `${url}&name.like=${search}`

        promises = [
          axios.get(idUrl).then(response => response.data),
          axios.get(nameUrl).then(response => response.data),
        ]
      } else {
        promises = [axios.get(url).then(response => response.data)]
      }
      const responses = await Promise.all(promises.map(p => p.catch(error => console.error(error))))
      const campaigns = mapFacebookCampaigns(uniqBy(responses.flat(), "id"), rootState.locale, rootState.timezone)

      commit("SET_FACEBOOK_CAMPAIGN_OPTIONS", orderBy(campaigns, ["disabled", "label"]))
    },
    loadFacebookAdSetOptions: async ({ commit, state, getters, rootState }) => {
      commit("SELECT_FACEBOOK_AD_SET", null)
      commit("SET_FACEBOOK_AD_SET_OPTIONS", null)

      if (!state.facebookCampaignId || !state.facebookPageId) {
        return
      }

      const response = await axios.get(`facebook/adSets?facebookCampaignId=${state.facebookCampaignId}&accessToken=facebookDistributionAccessToken`)
      const adSets = mapFacebookAdSets(response.data, getters.campaignObjective, rootState)
      commit("SET_FACEBOOK_AD_SET_OPTIONS", orderBy(adSets, ["disabled", "label"]))
    },
    loadInstagramAccountOptions: async ({ commit, state }) => {
      commit("SELECT_INSTAGRAM_ACCOUNT", null)
      commit("SET_INSTAGRAM_ACCOUNT_OPTIONS", null)

      if (!state.facebookAdAccountId || !state.facebookPageId) {
        return
      }

      const promises = [
        axios.get(`facebook/instagramAccounts?facebookAdAccountId=${state.facebookAdAccountId}&accessToken=facebookDistributionAccessToken`),
        axios.get(`facebook/instagramAccounts?facebookPageId=${state.facebookPageId}&accessToken=facebookDistributionAccessToken`),
      ]
      const responses = await Promise.all(promises.map(p => p.catch(error => console.error(error))))
      const instagramAccounts = responses
        .filter(response => !!response)
        .reduce((agg, response) => agg.concat(response.data), [])
        .map(instagramAccount => ({
          id: instagramAccount.id,
          value: instagramAccount.id,
          label: instagramAccount.username,
          metadata: instagramAccount.id,
          imageUrl: instagramAccount.profilePic ?? null,
        }))

      const facebookPage = state.facebookPageOptions.find(pageOption => pageOption.id === state.facebookPageId)
      commit("SET_INSTAGRAM_ACCOUNT_OPTIONS", [
        {
          id: "usePage",
          value: "usePage",
          label: "Use selected Facebook Page",
          metadata: "Use selected Page",
          displayTooltip: true,
          tooltip: "Your ad will use the Facebook Page name and profile picture",
          imageUrl: facebookPage.imageUrl,
        },
        ...uniqBy(instagramAccounts, "id"),
      ])
    },
    selectFacebookAdAccount ({ state, commit, dispatch }, facebookAdAccountId) {
      if (facebookAdAccountId === state.facebookAdAccountId) {
        return
      }

      commit("SELECT_FACEBOOK_AD_ACCOUNT", facebookAdAccountId)
      return Promise.all([
        dispatch("loadFacebookCampaignOptions"),
        dispatch("loadFacebookAdSetOptions"),
        dispatch("loadInstagramAccountOptions"),
      ])
    },
    selectFacebookPage ({ state, commit, dispatch }, facebookPageId) {
      if (facebookPageId === state.facebookPageId) {
        return
      }

      commit("SELECT_FACEBOOK_PAGE", facebookPageId)
      return Promise.all([
        dispatch("loadFacebookAdSetOptions"),
        dispatch("loadInstagramAccountOptions"),
      ])
    },
    selectFacebookCampaign ({ state, commit, dispatch }, facebookCampaignId) {
      if (facebookCampaignId === state.facebookCampaignId) {
        return
      }

      commit("SELECT_FACEBOOK_CAMPAIGN", facebookCampaignId)
      return Promise.all([
        dispatch("loadFacebookAdSetOptions"),
        dispatch("setCampaignWarning"),
      ])
    },
    selectFacebookAdSet ({ state, commit }, facebookAdSetId) {
      if (facebookAdSetId === state.facebookAdSetId) {
        return
      }
      commit("SELECT_FACEBOOK_AD_SET", facebookAdSetId)
    },
    selectInstagramAccount ({ state, commit }, instagramAccountId) {
      if (instagramAccountId === state.instagramAccountId) {
        return
      }
      commit("SELECT_INSTAGRAM_ACCOUNT", instagramAccountId)
    },
    distributeCreatives: async ({ dispatch, commit, state, getters }) => {
      if (getters.areIncompatibleCreativesSelected) {
        return
      }

      if (state.instagramAccountId === "usePage") {
        const pageBackedAccounts = await axios.get(`facebook/pageBackedInstagramAccounts?facebookPageId=${state.facebookPageId}&accessToken=facebookDistributionAccessToken`)
        if (pageBackedAccounts.data.length > 0) {
          commit("SET_PAGE_BACKED_INSTAGRAM_ACCOUNT", pageBackedAccounts.data[0].id)
        } else {
          const response = await axios.post("facebook/pageBackedInstagramAccounts", { facebookPageId: state.facebookPageId, accessToken: "facebookDistributionAccessToken" })
          commit("SET_PAGE_BACKED_INSTAGRAM_ACCOUNT", response.data.id)
        }
      }

      return axios.post("creativeDistributionJobsRequest", getters.distributionJobsPostData)
        .then(response => dispatch("allocateCreatives", response.data.ids))
    },
    allocateCreatives ({ dispatch, getters }, distributionJobIds) {
      const postData = {
        ...getters.distributionAllocationJobsPostData,
        creativeDistributionJobIds: distributionJobIds,
      }

      return axios.post("creativeDistributionAllocationJobsRequest", postData)
        .then(response => dispatch("addDistributions", response.data.creativeDistributionAllocationIds))
    },
    addDistributions ({ dispatch, getters }, creativeDistributionAllocationIds) {
      const newDistributions = []
      const exportedCreatives = getters.creativesForExport
      for (const index in creativeDistributionAllocationIds) {
        const nowISO = new Date().toISOString().split(".")[0].replace("T", " ")
        const exportedCreative = exportedCreatives[index]
        newDistributions.push({
          id: creativeDistributionAllocationIds[index],
          creativeId: exportedCreative.id,
          builderCreativeVersion: exportedCreative.builderCreativeVersion,
          creationTimestamp: nowISO,
          status: "IN_PROGRESS",
          errorMessage: null,
          facebookAdId: null,
        })
      }
      dispatch("creativesTable/addDistributions", newDistributions, { root: true })
    },
    setCampaignWarning ({ commit, getters }) {
      let warning = null
      if (getters.creativesWithMissingCTA.length > 0 && campaignObjectivesWithRequiredCTA.includes(getters.campaignObjective)) {
        let creativesWithMissingCTAString = getters.creativesWithMissingCTA.length === 1 ? "One creative is" : `${getters.creativesWithMissingCTA.length} creatives are`
        warning = {
          message: `${creativesWithMissingCTAString} missing CTAs which are required in selected Campaign.`,
          solvable: true,
          solvableMessage: "Export creatives with placeholder CTA and website url.",
        }

        if (getters.creativesWithIncorrectCTA.length > 0) {
          const creativesWithIncorrectCTAString = getters.creativesWithIncorrectCTA.length === 1 ? "One creative is" : `${getters.creativesWithIncorrectCTA.length} creatives are`
          creativesWithMissingCTAString = getters.creativesWithMissingCTA.length === 1 ? "one creative is" : `${getters.creativesWithMissingCTA.length} creatives are`
          warning.message = `${creativesWithIncorrectCTAString} using non-supported CTAs and ${creativesWithMissingCTAString} missing CTAs which are required in selected Campaign.`
        }
      } else if (getters.creativesWithIncorrectCTA.length > 0) {
        const creativesWithIncorrectCTAString = getters.creativesWithIncorrectCTA.length === 1 ? "One creative is" : `${getters.creativesWithIncorrectCTA.length} creatives are`
        warning = {
          message: `${creativesWithIncorrectCTAString} using non-supported CTAs in selected Campaign.`,
          solvable: true,
          solvableMessage: "Export creatives with placeholder CTA",
        }
      }

      commit("SET_FACEBOOK_CAMPAIGN_WARNING", warning)

    },
    setCreateLiveAd ({ commit }, createLiveAd) {
      commit("SET_CREATE_LIVE_AD", createLiveAd)
    },
    setCreativesWithDefaultValues ({ commit, getters }, useDefaultValues) {
      const creativesWithDefaultValues = useDefaultValues ? [...getters.creativesWithIncorrectCTA, ...getters.creativesWithMissingCTA].map(c => c.id) : []
      commit("SET_CREATIVES_WITH_DEFAULT_VALUES", creativesWithDefaultValues)
    },
    resetState ({ commit }) {
      commit("RESET_STATE")
    },
  },
  mutations: {
    SET_IS_OAUTH_DONE (state, isOAuthDone) {
      state.isOAuthDone = isOAuthDone
    },
    SET_IS_FACEBOOK_DATA_LOADING (state, isFacebookDataLoading) {
      state.isFacebookDataLoading = isFacebookDataLoading
    },
    SELECT_FACEBOOK_PAGE (state, facebookPageId) {
      state.facebookPageId = facebookPageId
      state.instagramAccountId = null
      state.instagramAccountOptions = null
    },
    SELECT_FACEBOOK_AD_ACCOUNT (state, facebookAdAccountId) {
      state.facebookAdAccountId = facebookAdAccountId
      state.instagramAccountId = null
      state.instagramAccountOptions = null
    },
    SELECT_INSTAGRAM_ACCOUNT (state, instagramAccountId) {
      state.instagramAccountId = instagramAccountId
    },
    SELECT_FACEBOOK_CAMPAIGN (state, facebookCampaignId) {
      state.facebookCampaignId = facebookCampaignId
    },
    SELECT_FACEBOOK_AD_SET (state, facebookAdSetId) {
      state.facebookAdSetId = facebookAdSetId
    },
    SET_FACEBOOK_AD_ACCOUNT_OPTIONS (state, facebookAdAccountOptions) {
      state.facebookAdAccountOptions = facebookAdAccountOptions
    },
    SET_FACEBOOK_PAGE_OPTIONS (state, facebookPageOptions) {
      state.facebookPageOptions = facebookPageOptions
    },
    SET_INSTAGRAM_ACCOUNT_OPTIONS (state, instagramAccountOptions) {
      state.instagramAccountOptions = instagramAccountOptions
    },
    SET_FACEBOOK_CAMPAIGN_OPTIONS (state, facebookCampaignOptions) {
      state.facebookCampaignOptions = facebookCampaignOptions
    },
    SET_FACEBOOK_AD_SET_OPTIONS (state, facebookAdSetOptions) {
      state.facebookAdSetOptions = facebookAdSetOptions
    },
    SET_CREATE_LIVE_AD (state, createLiveAd) {
      state.createLiveAd = createLiveAd
    },
    SET_FACEBOOK_CAMPAIGN_WARNING (state, facebookCampaignWarning) {
      state.facebookCampaignWarning = facebookCampaignWarning
    },
    SET_CREATIVES_WITH_DEFAULT_VALUES (state, creativesWithDefaultValues) {
      state.creativesWithDefaultValues = creativesWithDefaultValues
    },
    SET_PAGE_BACKED_INSTAGRAM_ACCOUNT (state, pageBackedInstagramAccountId) {
      state.pageBackedInstagramAccountId = pageBackedInstagramAccountId
    },
    RESET_STATE (state) {
      Object.assign(state, getState())
    },
  },
}
